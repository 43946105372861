/**
 * Created by jimmy on 2022/6/11.
 */
import AREA from './a.json';
import CITY from './c.json';
import PROVINCE from './p.json';
import * as Utils from '../../../common/utils';

export const getList = (nameKey = 'label', codeKey = 'value', disabledList = []) => {
  const areaMap = {};
  const areaMap2 = {};
  for(let a = 0, alen = AREA.length; a < alen; a++) {
    const aitem = AREA[a];
    if(disabledList.includes(aitem.code)) aitem.disabled = true;

    // aitem.value = aitem.code;
    // aitem.label = aitem.name;
    aitem[codeKey] = aitem.code;
    aitem[nameKey] = aitem.name;

    areaMap[aitem.province] = areaMap[aitem.province] || [];
    areaMap[aitem.province].push(aitem);

    const key2 = aitem.province + '-' + aitem.city;
    areaMap2[key2] = areaMap2[key2] || [];
    areaMap2[key2].push(aitem);
  }

  const cityMap = {};
  for(let c = 0, clen = CITY.length; c < clen; c++) {
    const citem = CITY[c];
    if(disabledList.includes(citem.code)) citem.disabled = true;

    // citem.value = citem.code;
    // citem.label = citem.name;
    citem[codeKey] = citem.code;
    citem[nameKey] = citem.name;

    cityMap[citem.province] = cityMap[citem.province] || [];
    cityMap[citem.province].push(citem);
  }

  const list = [];
  for(let p = 0, plen = PROVINCE.length; p < plen; p++) {
    const pitem = PROVINCE[p];
    if(disabledList.includes(pitem.code)) pitem.disabled = true;

    // pitem.value = pitem.code;
    // pitem.label = pitem.name;
    pitem[codeKey] = pitem.code;
    pitem[nameKey] = pitem.name;

    if(cityMap[pitem.province]) {
      pitem.children = cityMap[pitem.province];
      pitem.children.map(citem => {
        const key2 = citem.province + '-' + citem.city;
        citem.children = areaMap2[key2];
      });
    }else if(areaMap[pitem.province]) {
      pitem.children = areaMap[pitem.province];
    }

    list.push(pitem);
  }

  return list;
};

// export const get = (provinceCode, cityCode, distinctCode) => {
//   console.log('provinceCode, cityCode, distinctCode' ,provinceCode, cityCode, distinctCode)
//   let province = {};
//   let city = {};
//   let distinct = {};
//
//   province = Utils.getTargetByKey(PROVINCE, 'code', provinceCode);
//   console.log('get in', province);
//   if(province && province.children) {
//     city = Utils.getTargetByKey(province.children, 'code', cityCode);
//   }
//   if(city && city.children) {
//     distinct = Utils.getTargetByKey(city.children, 'code', distinctCode);
//   }
//   return { province, city, distinct }
// };
//
// export const getListByCodes = codes => {
//   if(!codes) return [];
//   const provinceTargets = PROVINCE.filter(item => codes.includes(item.code));
//   const cityTargets = CITY.filter(item => codes.includes(item.code));
//   const areaTargets = AREA.filter(item => codes.includes(item.code));
//   return [ ...provinceTargets, ...cityTargets, ...areaTargets ];
// };
//
// export const getListByNames = names => {
//   if(!names || names.length < 2) return [];
//   const [ provinceTargets ] = PROVINCE.filter(item => item.name == names[0]);
//   let cityTargets = null;
//   let areaTargets = null;
//   if(names.length == 2) {
//     [ areaTargets ] = AREA.filter(item => item.name == names[1] && item.province == provinceTargets.province);
//   }else if(names.length == 3){
//     [ cityTargets ] = CITY.filter(item => item.name == names[1] && item.province == provinceTargets.province);
//     [ areaTargets ] = AREA.filter(item => item.name == names[2] && item.city == cityTargets.city);
//   }
//
//   return {
//     provinceTargets, cityTargets, areaTargets
//   };
// };

export const get = (code) => {
  const list = [ ...PROVINCE, ...CITY, ...AREA ];
  console.log('get---list', list)
  const [ target ] = list.filter(lst => lst.code == code);
  return target;
}

export const getProvinceList = () => {
  return PROVINCE;
};

export const getCityListByPcode = (p) => {
  if(!p) return [];
  if(["11", "12", "31", "50"].includes(p.province)) {
    return [p];
  }
  return CITY.filter(item => item.province == p.province);
};

export const getAreaListByPcodeCcode = (p, c) => {
  if(!p || !c) return [];
  if(["11", "12", "31", "50"].includes(p.province)) {
    return AREA.filter(item => item.province == p.province);
  }else{
    return AREA.filter(item => item.province == p.province && item.city == c.city);
  }
};
