/**
 * 退票详情页面
 */
 import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ICON_BASE_URL, orderStatusMap } from '../../../common/const';
import { getOrderDetail, getRefundDetail } from '../../../common/requestList';
import Page from '../../../components/page';
import './index.less';

 const RefundDetailPage = (props) => {
    const { id, productOrderId } = useParams();
    const [productInfo, setProductInfo] = useState(null);
    const [detailData, setDetailData] = useState(null);
    const [refundList, setRefundList] = useState([]);

    /**
     * 获取订单详情
     */
    async function getDetailData() {
        const _id = id;
        if (_id == undefined) {
            return ;
        }
        const res = await getOrderDetail({
            id: _id,
        });
        const _data = res?.data;
        if (_data.status && orderStatusMap[_data.status]) {
            _data.statusText = orderStatusMap[_data.status];
        }
        _data.createdAt = _data.createdAt.replace('T', ' ').substr(0, 18);
        _data.isRedStatusText = [1, 2, 3, 4, 5].indexOf(_data.status) > -1;
        // 初始化订单状态
        const _productInfo = _data?.productList?.filter(item => item.id == productOrderId)?.[0];

        setDetailData(_data);
        setProductInfo(_productInfo);
    }

    async function getOrderRefundDetail() {
        const res = await getRefundDetail({
          orderId: id,
          orderProductId: productOrderId
        });
        let _refundList = res?.data?.list || [];
        const timeFormateStr = 'YYYY-MM-DD HH:mm';
        _refundList = _refundList.map(item => {
          item.refundList = [];
          if (item.refundApplyTime) {
            item.refundList.push({
              text: item.refundApplyTimeLabel,
              date: moment(item.refundApplyTime ).format(timeFormateStr)
            });
          }
          if (item.refundApprovalTime) {
            item.refundList.push({
              text: item.refundApprovalTimeLabel,
              date: moment(item.refundApprovalTime).format(timeFormateStr)
            });
          }
          if (item.refundRefusedTime) {
            item.refundList.push({
              text: item.refundRefusedTimeLabel,
              date: moment(item.refundRefusedTime).format(timeFormateStr)
            });
          }
          if (item.refundInitiationTime) {
            item.refundList.push({
              text: item.refundInitiationTimeLabel,
              date: moment(item.refundInitiationTime).format(timeFormateStr)
            });
          }
          if (item.refundReceptionTime) {
            item.refundList.push({
              text: item.refundReceptionTimeLabel,
              date: moment(item.refundReceptionTime).format(timeFormateStr)
            });
          }
          if (item.refundSuccessTime) {
            item.refundList.push({
              text: item.refundSuccessTimeLabel,
              date: moment(item.refundSuccessTime).format(timeFormateStr)
            });
          };
          return item;
        })
        setRefundList(_refundList);
    }

    useEffect(() => {
        getDetailData();
        getOrderRefundDetail();
    }, []);


    return <Page {...{
        ...props,
        page: 'refund-detail', title: '退款详情'
    }}>
        <div className="refund-detail-container">
            {
                productInfo?.refundTime ? <div className="refund-success-wrapper">
                    <img src={`${ICON_BASE_URL}success_icon.png`} className="success-icon" />
                    <div className="color-black f-28 bold">退款成功</div>
                </div> : null
            }
            {
                refundList?.map((refundItem) => <div className="refund-info-wrapper bd-b" key={refundItem.id}>
                    <div className="f-28 color-black bold refund-title">{refundItem.refundList[refundItem.refundList.length - 1].text}</div>
                    <div className="color-333 f-24 mg-b-20">退款金额：<text className="color-price">¥{refundItem.refundPrice || 0}</text></div>
                    <div className="color-333 f-24 mg-b-20">退回账户：{refundItem.refundReceivedAccount || '原路退回'}</div>
                    {
                        productInfo?.refundNumber ? <div className="color-333 f-24 mg-b-20">退款流水号：{productInfo.refundNumber}</div> : null
                    }
                    <div className="refund-progress-container">
                        {
                            refundItem.refundList?.map(item => <div className={`progress-item ${refundItem.refundList.length == 1 ? 'only-one' : ''}`}>
                                <text className="progress"></text>
                                <div className="progress-item-text">{item.text}</div>
                                <div className="progress-item-time">{item.date}</div>
                            </div>) 
                        }
                    
                    </div>
                </div>)
            }

        </div>

    </Page>
 }
 export default RefundDetailPage;