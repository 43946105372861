/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Page from '../../components/page';
import List from '../../components/list';
import './index.less';
import * as CONST from "../../common/const";
import EmptyListContent from "../../components/emptyListContent";
import Button from "../../components/button";
import Modal from "../../components/modal";

const ENTRANCE_LIST = [
	{ label: '系统消息', icon: 'icon-alarm', desc: '亲，与您密切相关的系统消息都在这里哦', type: CONST.MESSAGE_TYPE_SYSTEM },
	{ label: '物流消息', icon: 'icon-logistics', desc: '宝贝物流实时信息可以点这里看哦', type: CONST.MESSAGE_TYPE_LOGISTICS },
]

const PageModel = props => {
	const history = useHistory();

	const [ list, setList ] = useState([]);
	const getStatus = async () => {
		const list = [];
		for(let i = 0, len = ENTRANCE_LIST.length; i < len; i++) {
			const result = await Request.get({
				url: '/message/list',
				data: {
					type: ENTRANCE_LIST[i].type,
					status: CONST.MESSAGE_STATUS_TO_READ
				}
			});
			if(!result) return;

			const _list = result.data.list;

			let item = { ...ENTRANCE_LIST[i] };
			if(_list?.length > 0) {
				const desc = _list[0]?.title || ENTRANCE_LIST[i].desc;
				const time = _list[0]?.updatedAt ? moment(_list[0]?.updatedAt).format('HH:mm') : '';
				const hasUnreadMessage = _list?.[0] && !_list[0].isRead ? true : false;
				item = { ...item, desc, time, hasUnreadMessage };
			}
			list.push(item);
		}
		setList(list);
	};
	useEffect(() => {
		getStatus();

		return () => false;
	}, [123]);

	return (
		<Page {...{
			...props,
			page: 'message', title: '信息中心'
		}}>
			<section className="message-container">
				{
					list?.length > 0 && list.map((item, idx) => {
						return (
							<div className="message-item" key={idx} onClick={() => {
								history.push(`message/list?type=${item.type}`);
							}}>
								<em className={`iconfont ${item.icon}${item.hasUnreadMessage ? ' alarm' : ''}`}>{ !!item.hasUnreadMessage && <i /> }</em>
								<div className="info">
									<label>{item.label}</label>
									<span>{item.desc}</span>
									<span className="time">{item.time}</span>
								</div>
							</div>
						)
					})
				}
			</section>
		</Page>
	);
};

export default PageModel;
