/**
 * Created by jimmy on 2022/9/25.
 */

import React, { useState, useEffect } from 'react';
import * as CONST from '../../common/const';
import './index.less';

const CompBlockModel = props => {
	const { style, className, disabled = false, text = '我知道了', onClick } = props;
	if(!text) return null;

	return (
		<section className={`comp-button-wrapper${disabled ? ' disabled' : ''} ${className}`} style={style} onClick={() => {
			if(disabled) return;

			if(onClick && typeof onClick == 'function') {
				onClick();
			}
		}}>{text}</section>
	)
};

export default CompBlockModel;
