// export const BASE_URL = 'https://mall.yeekin.cn/api/v1/';
const api = {
  // 删除地址
  deleteAddress: '/address/delete',
  updateAddress: '/address/update',
  addAddress: '/address/add',
  addressList: '/address/list',
  // 获取广告位Banner列表
  bannerList: '/banner/list',
  // 获取品牌下的商品列表
  brandProductList: '/brand/product/list',
  // 获取品牌列表
  brandList: '/brand/list',
  // 品牌详情
  brandDetail: '/brand/get',
  // 删除购物车
  deleteCart: '/cart/delete',
  setCart: '/cart/set',
  cartList: '/cart/list',
  // 标记信息为已读状态
  messageMarkRead: '/message/read',
  // 消息列表
  messageList: '/message/list',
  // 未读消息数量
  messageUnReadCount: '/message/unread/count',
  // 创单
  submitOrder: '/order/submit',
  // 是否支持配送
  shipableOrder: '/order/shipable',
  // 确认收货
  confirmReceiveOrder: '/order/receive/confirm',
  // 获取物流信息
  trackingOrder: '/order/tracking',
  // 获取退款理由
  refundReasons: '/order/refund/reasons',
  // 订单列表
  orderList: '/order/list',
  // 订单支付
  orderPay: '/order/pay',
  // 订单详情
  orderDetail: '/order/get',
  // 退款申请
  applyRefund: '/order/refund/apply',
  // 退款明细
  refundDetail: '/order/refund/detail',
  // 获取页面配置信息
  pageConfig: '/page/config/get',
  // 上传评论图片
  commentUpload: '/product/comment/upload',
  // 商品分组列表
  productGroupList: '/product/group/list',
  // 商品列表
  productList: '/product/list',
  // 商品规格信息列表
  productSpecificationList: '/product/items/list',
  // 商品评论列表
  productCommentList: '/product/comment/list',
  // 商品详情
  productDetail: '/product/get',
  // 添加商品评论
  productCommentAdd: '/product/comment/add',
  // 更新用户信息
  userSet: '/user/set',
  // 用户头像上传
  uploadAvatar: '/user/avatar/upload',
  // 用户登录
  userLogin: '/user/login',
  // 获取用户信息
  userGet: '/user/get',
  // 商品收藏/取消收藏
  toggleProductCollect: '/product/collect/toggle',
  // 商品收藏列表
  collectList: '/user/product/collect/list',
  // 商品搜索
  searchProduct: '/product/search',
  // 个人中心查询各个状态下的订单数量
  orderCount: '/order/count',
  // 获取折扣商品列表
  discountProductList: '/discount/product/list',
  // 获取配送运费
  orderShippingPrice: '/order/shipping/price',
  // 判断是否幸福云用户
  checkAccessUser: '/user/xingfuyun/check',
  // 判断是否打开幸福云用户登录入口
  xfuLoginFlag: '//user/xingfuyun/login/flag',
  // 票券类商品下单接口
  submitTicketOrder: '/order/submit/ticket',
  // 发票
  submitInvoice: '/invoice/submit',
  // 发票详情
  invoiceGet: '/invoice/get',
  // 发票物流信息
  invoiceTracking: '/invoice/tracking',
  // 票券商品购买说明
  productDescriptions: '/product/descriptions/get',
  // 取消退款申请
  cancelRefundOrder: '/order/refund/apply/cancel',
  // 上传评论图片
  uploadCommentImg: '/product/comment/upload',
  // 上传退款图片
  uploadRefundImg: '/order/refund/upload',
  // 获取商家资质信息
  getMerchant: '/merchant/get'
}
export default api;
