/**
 * Created by jimmy on 2019/11/8.
 */
import 'whatwg-fetch';
import * as Utils from './utils';
import * as CONST from './const';

export const getHeaders = (conf = {}) => {
	const token = window.sessionStorage.getItem('token');
	const openid = window.sessionStorage.getItem('openid');
	const _header = {
		"content-type":"application/json; charset=utf-8",
		"auth-token": token,
		"auth-openid": openid,
		// "sys-token": 'a1s4md6cj7n8d9j0as2dq0weeq1okgmx',
		...conf
	};
	return _header;
};

export const post = async (settings) => {
	const self = this;
	let url = CONST.API_PREFIX + settings.url;
	const headers = getHeaders(settings.headers);
	if(!headers['auth-token'] && ![ '/user/xingfuyun/check', '/user/xingfuyun/login' ].includes(settings.url)) {
		Utils.checkXINGFUYUNAccessUser(() => {
			self.call({...settings});
		});
	}
	Utils.loading.show();
	let response = await window.fetch(url, {
		method: 'POST',
		headers,
		body: JSON.stringify(settings.data || {})
	});
	Utils.loading.hide();

	response = await response.json();
	if(!settings.hideError && response.ok == -1) {
		Utils._alert(response.msg);
		return false;
	}
	return response;
};

export const get = async (settings = {}) => {
	const self = this;
	try{
		let url = CONST.API_PREFIX + settings.url;
		const headers = getHeaders(settings.headers);
		if(!headers['auth-token'] && ![ '/user/xingfuyun/check', '/user/xingfuyun/login' ].includes(settings.url)) {
			Utils.checkXINGFUYUNAccessUser(() => {
				self.call({...settings});
			});
		}

		if(settings.data) {
			let queryArr = [];
			for(let i in settings.data) {
				if(i && settings.data[i]){
					queryArr.push(`${i}=${settings.data[i]}`);
				}
			}
			url += '?' + queryArr.join('&');
		}
		Utils.loading.show();
		let response = await window.fetch(url, {
			method: 'GET',
			headers,
		});
		Utils.loading.hide();

		response = await response.json();
		if(!settings.hideError && response.ok == -1) {
			Utils._alert(response.msg);
			return false;
		}
		return response;
	}catch (e) {
		Utils.loading.hide();
	}
};

export const upload = async(settings) => {
	const url = CONST.API_PREFIX + settings.url;
	settings.body = settings.body || settings.data;
	settings.method = 'POST';
	delete settings.url;
	delete settings.data;
	settings.headers = getHeaders(settings.headers);
	if(settings.headers['content-type']) delete settings.headers['content-type'];
	let response = await window.fetch(url, settings);

	response = await response.json();
	if(!settings.hideError && response.ok == -1) {
		Utils._alert(response.msg);
		return false;
	}
	return response;
}
