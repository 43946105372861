/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import './index.less';

import * as Utils from '../../common/utils';
import { MEDIA_HOST } from '../../common/const';

const CompModel = props => {
	const history = useHistory();
	const [data, setData] = useState(window.MALL_DATA);
	useEffect(() => {
		if (!data) {
			setTimeout(() => {
				setData(window.MALL_DATA || null);
			}, 200);
		}
	}, [ 123 ]);
	
	const { page, showFooter = false, title = '加载中……', style = {}, showBg = false } = props;

	useEffect(() => {
		Utils.setTitle(title);

		return () => false
	}, [ title ]);

	return (
		<section className="page-container" style={style}>
			{ data?.backgroundImage2 && showBg && <div className="page-bg"  style={{ backgroundImage: `url(${MEDIA_HOST + data.backgroundImage2})` }} /> }
			<section className="page-body" style={{ minHeight: window.innerHeight, ...(() => !showFooter && ({ paddingBottom: 0 }) || ({}))() }}>{props.children}</section>
			{
				!!showFooter && <footer className="page-footer">
					{
						[
							{ label: '首页', icon: 'icon-home', pageId: 'home', path: 'home' },
							{ label: '分类', icon: 'icon-category', pageId: 'category', path: 'category' },
							{ label: '购物车', icon: 'icon-cart', pageId: 'cart', path: 'cart' },
							{ label: '我的', icon: 'icon-user', pageId: 'user', path: 'user' },
						].map((item, idx) => (
							<div className={`item${page == item.pageId ? ' selected' : ''}`} key={idx} onClick={() => {
								history.push(item.path);
							}}>
								<em className={`iconfont ${item.icon}`} />
								<span>{item.label}</span>
							</div>
						))
					}
				</footer>
			}
		</section>
	);
};

export default CompModel;
