/**
 * Created by jimmy on 2022/9/25.
 */

import React, { useState, useEffect } from 'react';
import {useHistory, useParams} from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import * as CONST from '../../common/const';
import './index.less';
import {BANNER_TYPE_WX_BRAND} from "../../common/const";

const CompBannerModel = props => {
	const history = useHistory();
	const { style = {}, list = [], refProp } = props;

	if(!list || list.length <= 0) return null;

	return (
		<section className="comp-banner-wrapper" style={style} ref={refProp}>
			<Carousel {...{
				className: 'carousel1',
				showArrows: false,
				infiniteLoop: true,
				showIndicators: false,
				// autoPlay: true,
				showThumbs: false,
				showStatus: false
			}}>
				{
					list.map((item = {}, idx) => {
						return (
							<div className="carousel1-item" key={idx} style={{ backgroundImage: `url(${CONST.MEDIA_HOST + item.cover})` }} onClick={() => {
								if(item.type == CONST.BANNER_TYPE_WEBSITE && item.target.startsWith('http')) {
									window.location.href = item.target;
								}else if(item.type == CONST.BANNER_TYPE_WX_PRODUCT_DETAIL){
									history.push(`product/${item.target}`);
								}else if(item.type == CONST.BANNER_TYPE_WX_BRAND){
									history.push(`brand/${item.target}`);
								}else{
									// alert('程序员忘记写了，你提醒下叻');
								}
							}} />
						)
					})
				}
			</Carousel>
		</section>
	);
};

export default CompBannerModel;
