/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Page from '../../components/page';
import List from '../../components/list';
import './index.less';
import * as CONST from "../../common/const";
import ProductItem from "../../components/product/productItem";
import Button from "../../components/button";
import Modal from "../../components/modal";

const PageModel = props => {
	const id = Utils.getSearchByKey('id');
	const [ list, setList ] = useState([]);
	const [ title, setTitle ] = useState('');
	const getList = async () => {
		if(!id) return;

		const result = await Request.get({
			url: '/discount/product/list',
			data: {
				id,
				// page: pageIndex,
				// pageSize,
			}
		});
		if(!result) return;

		setList(result.data.list);
		setTitle(result.data.name);
	};
	useEffect(() => {
		getList();
	}, [ 123 ]);

	return (
		<Page {...{
			...props,
			style: { backgroundColor: '#FFFFFF' },
			page: 'product-discount-list', title
		}}>
			<section className="product-discount-list-container">
				{
					list?.length > 0 && list.map((item, idx) => {
						return (
							<div className="product-discount-item-wrapper">
								<ProductItem key={idx} {...{
									data: item?.productInfo || null,
									mode: 'ltr'
								}} />
							</div>
						)
					})
				}
			</section>
		</Page>
	);
};

export default PageModel;
