/**
 * 发票详情页面
 */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ICON_BASE_URL, InvoiceDetailItemList } from '../../../common/const';
import { getInvoiceDetail } from '../../../common/requestList';
import { toast } from '../../../common/utils';
import Page from '../../../components/page';
import './index.less';

const InvoiceDetail = ({ }) => {
    const { orderId } = useParams();
    const [invoiceData, setInvoiceData] = useState(null);
    const [itemList, setItemList] = useState(null);
    const [trackingData, setTrackingData] = useState(null);

    /**
     * 获取发票详情
     */
    async function getData() {
        // const { orderId } = this.data;
        const res = await getInvoiceDetail({orderId});
        setInvoiceData(res?.data || null);
        setTrackingData(res?.data?.trackingInfo || null);
        // initItemList();
        // this.getInvoiceTrackingData();
    };
    function initItemList() {
        const _itemList = [];
        if (!invoiceData) {
          return false;
        }
        InvoiceDetailItemList.forEach(item => {
          if (item.type == 'radioBtn') {
            _itemList.push({
              label: item.label,
              text: item.contents.filter(cItem => cItem.value == invoiceData[item.key])?.[0].text
            })
          } else if (!item.visible || invoiceData[item.visible.key] == item.visible.value) {
            _itemList.push({
              label: item.label,
              text: invoiceData[item.key]
            });
          }
        });
        console.log('_itemList', _itemList);
        setItemList(_itemList);
    };

    useEffect(() => {
        getData();
    }, [])
    useEffect(() => {
        initItemList();
    }, [invoiceData])
    

    return <Page {...{
        page: 'invoice-detail', title: '发票详情'
    }}>
    <div className="invoice-detail-container">
        <div className="invoice-success-wrapper">
            <img src={`${ICON_BASE_URL}success_icon.png`} className="success-icon" />
            <div className="color-black f-28 bold">发票开具成功</div>
        </div>
        {/*发票详情 */ }
        {
            itemList?.length ?  <div className="invoice-detail-list">
                {
                    itemList.map(item => <div className="invoice-detail-item" key={item.label}>
                        <div className="invoice-detail-item-label">{item.label}</div>
                        <div className="invoice-detail-item-text">{item.text}</div>
                    </div>)
                }
                
            </div> : null
        }
       
        {/*发票物流信息 */ }
        {
            trackingData ? <div className="invoice-detail-tracking-info">
                {/*物流信息 */ }
                <div className="shipping-info-container">
                {/*<div className="title f-28 color-333 lh-1">订单号: {packageList[0].productList[0].serialNumber}</div> */ }
                <div className="title f-28 color-333 lh-1">物流单号: {invoiceData.trackingNumber}</div>
                <div className="title f-28 color-333 lh-1">承运来源: {invoiceData.trackingCompanyName}</div>
                <div className="title f-28 color-333 lh-1">物流状态</div>
                <div className="info-list f-24">
                    {
                        trackingData?.list?.map((item, index) => <div className={`info-item ${index == 0 ? 'latest-item' : ''}`}>
                            <span className="progress"></span>
                            <div className="info-content">{item.context}</div>
                            <div className="info-date">{item.time}</div>
                        </div>)
                    }
                    
                    </div>
                </div>
            </div> : <div className="invoice-detail-tracking-info">
                暂无物流信息
            </div>
        }
        
        
    </div>
</Page>
}

export default InvoiceDetail;