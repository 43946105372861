/**
 * Created by jimmy on 2022/9/25.
 */

import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import * as CONST from '../../common/const';
import './index.less';

const CompListModel = props => {
	let { style = {}, className = '', list = [], renderItem, numberInLine = 1, gap = 0 } = props;
	if(!list || list.length <= 0) return null;

	return (
		<section className={`comp-list-wrapper ${className}`} style={style}>
			<div className="list-container" style={{ marginBottom: -gap, flexDirection: numberInLine == 1 ? 'column' : 'inherit' }}>
				{
					list.map((item, idx) => {
						let _style = { marginBottom: gap };
						if(numberInLine > 1) {
							_style = {
								..._style,
								width: `calc((100% - ${(numberInLine-1)*gap}px)/${numberInLine})`,
								marginLeft: idx % numberInLine === 0 ? 0 : gap
							}
						}else{
							_style = {
								..._style,
								flex: 1
							}
						}
						return <div className="list-item" style={_style} key={idx}>{renderItem(item)}</div>
					})
				}
			</div>
		</section>
	);
};

export default CompListModel;
