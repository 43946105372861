/**
 * Created by jimmy on 2019/11/8.
 */
import PROJECT_CONFIG from '../../project.json';

/**
 ************************************************************
 * 基础配置
 ************************************************************
 */
export const appId = PROJECT_CONFIG.appId;
export const HOST = PROJECT_CONFIG.host || window.__APP_DATA__.host
export const API_PREFIX = `${HOST}/api/v1`;
export const HOME_ID = PROJECT_CONFIG.homeId;
// export const UPLOAD_API_PREFIX = 'https://mall.yeekin.cn/api/v1';
export const MEDIA_HOST = PROJECT_CONFIG.mediaHost || window.__APP_DATA__.mediaHost;
export const ICON_BASE_URL = `${MEDIA_HOST}/assets/icon/`;

/**
 ************************************************************
 * 常量配置
 ************************************************************
 */
export const CONST_LABEL = {};
/**
 * 企业微信客服地址
 * @type {string}
 */
export const customerServiceLink = 'https://work.weixin.qq.com/kfid/kfc939084d421cd35c0';
/**
 * 分页每页数量
 * @type {number}
 */
export const PAGE_SIZE_LIMIT = 10;
/**
 * 是否默认地址
 */
export const ADDRESS_DEFAULT_IS = 1;
export const ADDRESS_DEFAULT_NOT = 2;
/**
 * 管理员角色
 */
export const ADMIN_ROLE_ADVANCED = 'role1';
export const ADMIN_ROLE_GENERAL = 'role2';
export const ADMIN_ROLE_FINANCE = 'role3';
export const ADMIN_ROLE_WRITE_OFF = 'role4';
export const ADMIN_ROLE_SERVICE = 'role5';
export const ADMIN_ROLE_UI = 'role6';
export const ADMIN_ROLE_OPERATION = 'role7';
/**
 * banner类型
 */
export const BANNER_TYPE_DEFAULT = 1;
export const BANNER_TYPE_WEBSITE = 2;
export const BANNER_TYPE_WX_PRODUCT_DETAIL = 3;
export const BANNER_TYPE_WX_MINI_PROGRAM = 4;
export const BANNER_TYPE_WX_BRAND = 5;
/**
 * banner在线状态
 */
export const BANNER_STATUS_ONLINE = 1;
export const BANNER_STATUS_OFFLINE = 2;
/**
 * brand类型
 */
export const BRAND_TARGET_TYPE_DEFAULT = 1;
export const BRAND_TARGET_TYPE_WEBSITE = 2;
export const BRAND_TARGET_TYPE_WX_MINI_PROGRAM = 3;
/**
 * 包邮类型
 */
export const FREE_SHIPPING_TYPE_PLATFORM = 1;
export const FREE_SHIPPING_TYPE_MERCHANT = 2;
/**
 * 包邮限制类型
 */
export const FREE_SHIPPING_LIMITATION_TYPE_PRICE = 1;
export const FREE_SHIPPING_LIMITATION_TYPE_COUNT = 2;
/**
 * 包邮是否生效
 */
export const FREE_SHIPPING_STATUS_ONLINE = 1;
export const FREE_SHIPPING_STATUS_OFFLINE = 2;
/**
 * 信息类型
 */
export const MESSAGE_TYPE_SYSTEM = 1;
export const MESSAGE_TYPE_LOGISTICS = 2;
/**
 * 信息是否已读
 */
export const MESSAGE_STATUS_TO_SEND = 1;
export const MESSAGE_STATUS_SEND = 2;
export const MESSAGE_STATUS_TO_READ = 1;
export const MESSAGE_STATUS_READ = 2;
/**
 * 订单类型
 */
export const ORDER_TYPE_PHYSICAL = 1;
export const ORDER_TYPE_TICKET = 2;
export const ORDER_TYPE_DIGITAL = 3;
/**
 * 订单支付渠道
 */
export const ORDER_PAY_METHOD_WX = 1;
export const ORDER_PAY_METHOD_WX_JS = 2;
export const ORDER_PAY_METHOD_WX_H5 = 3;
export const ORDER_PAY_METHOD_ALI = 4;
/**
 * 订单支付状态
 */
export const ORDER_PAY_STATUS_TODO = 1;
export const ORDER_PAY_STATUS_DONE = 2;
/**
 * 订单状态
 * 1-待支付；2-支付中；3-待发货；4-待收货；41-待使用；5-待评价；6-已退款；7-已完成；8-已关闭；
 */
export const ORDER_STATUS_TO_PAY = 1;
CONST_LABEL[ORDER_STATUS_TO_PAY] = '待支付';
export const ORDER_STATUS_TO_PENDING = 2;
CONST_LABEL[ORDER_STATUS_TO_PENDING] = '支付中';
export const ORDER_STATUS_TO_SHIP = 3;
CONST_LABEL[ORDER_STATUS_TO_SHIP] = '待发货';
export const ORDER_STATUS_TO_RECEIVE = 4;
CONST_LABEL[ORDER_STATUS_TO_RECEIVE] = '待收货';
export const ORDER_STATUS_TO_USE = 41;
CONST_LABEL[ORDER_STATUS_TO_USE] = '待使用';
export const ORDER_STATUS_TO_COMMENT = 5;
CONST_LABEL[ORDER_STATUS_TO_COMMENT] = '待评价';
export const ORDER_STATUS_REFUND_COMPLETE = 6;
CONST_LABEL[ORDER_STATUS_REFUND_COMPLETE] = '已退款';
export const ORDER_STATUS_COMPLETED = 7;
CONST_LABEL[ORDER_STATUS_COMPLETED] = '已完成';
export const ORDER_STATUS_CLOSED = 8;
CONST_LABEL[ORDER_STATUS_CLOSED] = '已关闭';
/**
 * 订单退款状态
 */
export const ORDER_REFUND_STATUS_APPLY_REVIEW = 1;
export const ORDER_REFUND_STATUS_APPLY_REFUSED = 2;
export const ORDER_REFUND_STATUS_PENDING = 3;
export const ORDER_REFUND_STATUS_COMPLETED = 4;
export const ORDER_REFUND_STATUS_CLOSED = 5;
/**
 * 核销码状态
 */
export const WRITE_OFF_CODE_STATUS_TO_USE = 1;
export const WRITE_OFF_CODE_STATUS_USED = 2;
export const WRITE_OFF_CODE_STATUS_INVALID = 3;
export const WRITE_OFF_CODE_STATUS_EXPIRED = 4;
/**
 *  商品类型
 */
export const PRODUCT_TYPE_PHYSICAL = 1;
export const PRODUCT_TYPE_TICKET = 2;
export const PRODUCT_TYPE_DIGITAL = 3;
/**
 * 商品状态
 */
export const PRODUCT_STATUS_ONLINE = 1;
export const PRODUCT_STATUS_OFFLINE = 2;
export const PRODUCT_STATUS_SALE_OUT = 3;
/**
 * 票券商品状态
 */
export const TICKET_PRODUCT_STATUS_ABLE = 1;
export const TICKET_PRODUCT_STATUS_DISABLE = 2;
export const TICKET_PRODUCT_STATUS_SALE_OUT = 3;
export const TICKET_PRODUCT_STATUS_EXPIRED = 4;
/**
 * 商品描述状态
 */
export const PRODUCT_DESCRIPTION_STATUS_ONLINE = 1;
export const PRODUCT_DESCRIPTION_STATUS_OFFLINE = 2;
/**
 * 商品描述类型
 */
export const PRODUCT_DESCRIPTION_TYPE_PARAMS = 1;
export const PRODUCT_DESCRIPTION_TYPE_BUY = 2;
export const PRODUCT_DESCRIPTION_TYPE_USAGE = 3;
/**
 * 商品配送方式
 */
export const PRODUCT_SHIPPING_TYPE_ED = 1;
export const PRODUCT_SHIPPING_TYPE_MERCHANT = 2;
export const PRODUCT_SHIPPING_PRICE_TYPE_UNITY = 1;
export const PRODUCT_SHIPPING_PRICE_TYPE_TPL = 2;
/**
 * 商品价格显示状态
 */
export const PRODUCT_COMMENT_STATUS_ONLINE = 1;
export const PRODUCT_COMMENT_STATUS_OFFLINE = 2;
/**
 * 用户与商品关系类型
 */
export const USER_PRODUCT_RELATION_TYPE_COLLECT = 1;
export const USER_PRODUCT_RELATION_TYPE_LIKE = 2;
/**
 * 商品收藏状态
 */
export const PRODUCT_COLLECT_STATUS_DO = 1;
export const PRODUCT_COLLECT_STATUS_UNDO = 2;
/**
 * 商品点赞状态
 */
export const PRODUCT_LIKE_STATUS_DO = 1;
export const PRODUCT_LIKE_STATUS_UNDO = 2;
/**
 * 商家主体类型
 */
export const BUSINESS_ENTITY_TYPE_ENTERPRISE = 1;
export const BUSINESS_ENTITY_TYPE_SELF_EMPLOYED = 2;
export const BUSINESS_ENTITY_TYPE_INDIVIDUAL = 3;
export const BUSINESS_ENTITY_TYPE_OTHER = 4;
// 商家主体类型是否有子层级
export const BUSINESS_ENTITY_HAS_CHILD = 1;
export const BUSINESS_ENTITY_HAS_NOCHILD = 2;
// 商家主体类型启用状态
export const BUSINESS_ENTITY_STATUS_ONLINE = 1;
export const BUSINESS_ENTITY_STATUS_OFFLINE = 2;
/**
 * 运费计算方式
 */
export const SHIPPING_TYPE_COUNT = 1;
export const SHIPPING_TYPE_WEIGHT = 2;
/**
 * 用户性别
 */
export const USER_SEX_MALE = 1;
export const USER_SEX_FEMALE = 2;
export const USER_SEX_UNKNOWN = 3;
/**
 * 限时折扣
 */
export const DISCOUNT_VALID_STATUS_IS = 1;
export const DISCOUNT_VALID_STATUS_NOT = 2;
export const DISCOUNT_STATUS_TO_START = 1;
export const DISCOUNT_STATUS_PROCESSING = 2;
export const DISCOUNT_STATUS_OVER = 3;
/**
 * 统计仪表盘所属类型
 */
export const DASHBOARD_TYPE_PRODUCT = 1;
export const DASHBOARD_TYPE_ORDER = 2;
export const DASHBOARD_TYPE_USER = 3;
export const DASHBOARD_TYPE_PAGE = 4;
export const DASHBOARD_TYPE_DISCOUNT = 5;
/**
 * 统计仪表盘所属类型
 */
export const DASHBOARD_SOURCE_SYSTEM = 1;
export const DASHBOARD_SOURCE_USER_DEFINED = 2;
/**
 * 统计仪表盘所属类型
 */
export const KANBAN_SOURCE_SYSTEM = 1;
export const KANBAN_SOURCE_USER_DEFINED = 2;
/**
 * 消息模板
 */
export const MESSAGE_TPL_ORDER_SHIP = `亲，您的宝贝已经发货啦，请耐心等待哦。点我查看实时物流信息～`;
export const MESSAGE_TPL_INVOICE_SHIP = `亲，您的发票已经发货啦，请耐心等待哦。点我查看实时物流信息～`;
/**
 * 资产信息-交易类型
 */
export const ASSETS_TYPE_BASIC = 1;
export const ASSETS_TYPE_FEES = 2;
/**
 * 资产信息-收支类型
 */
export const ASSETS_TYPE_EXPENDITURE = 1;
export const ASSETS_TYPE_INCOME = 2;
/**
 * 角色定义来源
 */
export const ROLE_SOURCE_DEFAULT = 1;
export const ROLE_SOURCE_SELF_DEFINE = 2;
/**
 * 商品统计访问类型
 */
export const STAT_PRODUCT_TYPE_EXPOSURE = 1;
export const STAT_PRODUCT_TYPE_DETAIL = 2;
export const STAT_PRODUCT_TYPE_ADD_CART = 3;
export const STAT_PRODUCT_TYPE_COLLECT = 4;
/**
 * 用户统计访问类型
 */
export const STAT_USER_TYPE_ACTIVITY = 1;
/**
 * 系统操作日志类型
 */
export const SYSTEM_LOGS_TYPE_ORDER = 1;
export const SYSTEM_LOGS_TYPE_PRODUCT = 2;
/**
 * 页面统计页面表示前缀
 */
export const STAT_PAGE_PREFIX_HOME = 'home';
export const STAT_PAGE_PREFIX_PRODUCT_LIST = 'product_list_';
export const STAT_PAGE_PREFIX_PRODUCT_DETAIL = 'product_detail_';
/**
 * 发票开具类型
 */
export const INVOICE_TYPE_PAPER = 1;
export const INVOICE_TYPE_ELECTRONIC = 2;
/**
 * 发票类型
 */
export const INVOICE_INVOICE_TYPE_GENERAL = 1;
export const INVOICE_INVOICE_TYPE_SPECIAL = 2;
/**
 * 发票内容类型
 */
export const INVOICE_CONTENT_CATEGORY_TYPE_DETAIL = 1;
export const INVOICE_CONTENT_CATEGORY_TYPE_CATEGORY = 2;
/**
 * 抬头类型
 */
export const INVOICE_TARGET_TYPE_PERSONAL = 1;
export const INVOICE_TARGET_TYPE_ENTERPRISE = 2;
/**
 * 发票状态
 */
export const INVOICE_TARGET_STATUS_TO_MAKE = 1;
export const INVOICE_TARGET_STATUS_MADE = 2;
export const INVOICE_TARGET_STATUS_SEND = 3;
/**
 * 页面搭建组件模块类型
 */
export const PAGE_MODULE_TYPE_SEARCH = 1;
export const PAGE_MODULE_TYPE_BANNER = 2;
export const PAGE_MODULE_TYPE_KING_AREA = 3;
export const PAGE_MODULE_TYPE_CIPIAN_AREA = 4;
export const PAGE_MODULE_TYPE_PRODUCT = 5;
/**
 * local storage key
 */
export const _LOCALSTORAGE_KEY_SEARCH_HISTORY_ = '_SEARCH_HISTORY_';
/**
 * 新增发票的item
 */
export const InvoiceItem1ListData = [
    { label: '开具类型',
      type: 'radioBtn',
      key: 'type',
      contents: [{ value: 1, text: '纸质票' }],
    },
    { label: '发票类型',
      type: 'radioBtn',
      key: 'invoiceType',
      placeholder: '',
      contents: [{ value: 1, text: '增值税普通发票' }],
    },
    { label: '发票内容',
      type: 'radioBtn',
      key: 'category',
      contents: [{ value: 1, text: '商品明细' }, { value: 2, text: '商品类别' }],
    },
    { label: '抬头类型',
      type: 'radioBtn',
      key: 'targetType',
      contents: [{ value: 1, text: '个人' }, { value: 2, text: '单位' }],
    },
    { label: '个人姓名',
      type: 'text',
      key: 'targetName',
      visible: {
        key: 'targetType',
        value: 1
      },
      placeholder: '请输入个人姓名',
      patterns: [{ required: true, text: '请输入个人姓名'}, { reg: '\\S{2,}', text: '请输入正确的姓名' }]
    },
    { label: '抬头名称',
      type: 'text',
      key: 'targetName',
      visible: {
        key: 'targetType',
        value: 2
      },
      placeholder: '请输入抬头名称',
      patterns: [{ required: true, text: '请输入抬头名称'}, { reg: '\\S{2,}', text: '请输入正确的抬头名称' }]
    },
    { label: '单位税号',
      type: 'text',
      key: 'targetTax',
      visible: {
        key: 'targetType',
        value: 2
      },
      placeholder: '请输入单位税号',
      patterns: [{ required: true, text: '请输入单位税号'}, { reg: '\\S{2,}', text: '请输入正确的单位税号' }]
    },
  ];
export const InvoiceItem2ListData = [
    { label: '收票地址',
      type: 'picker',
      key: 'region',
      patterns: [{ required: true, text: '请选择收票地址'}]
    },
    { label: '详细地址',
      type: 'text',
      key: 'recipientAddressDetail',
      placeholder: '请输入收件人详细地址',
      patterns: [{ required: true, text: '请输入收件人详细地址'}, { reg: '\\S{2,}', text: '请输入正确的详细地址' }]
    },
    { label: '收件人',
      type: 'text',
      key: 'recipientName',
      placeholder: '请输入收件人姓名',
      patterns: [{ required: true, text: '请输入收件人姓名'}, { reg: '\\S{2,}', text: '请输入正确的收件人姓名' }]
    },
    { label: '手机号',
      type: 'number',
      key: 'recipientMobile',
      placeholder: '请输入收件人联系电话',
      patterns: [{ required: true, text: '请输入收件人电话'}, { reg: '\^\\d\{11\}\$', text: '请输入正确的收件人电话' }]
    },
    { label: '邮箱',
      type: 'text',
      key: 'email',
      placeholder: '请输入收件人邮箱',
      patterns: [{ required: true, text: '请输入收件人邮箱'}, { reg: "^([a-z0-9A-Z]+[-|\\.]?)+[a-z0-9A-Z]@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\\.)+[a-zA-Z]{2,}$", text: '请输入正确的邮箱地址' }]
    }
  ];
// 发票详情数据
export const InvoiceDetailItemList = [
    { label: '开具类型',
      type: 'radioBtn',
      key: 'type',
      contents: [{ value: 1, text: '纸质票' }],
    },
    { label: '发票类型',
      type: 'radioBtn',
      key: 'invoiceType',
      placeholder: '',
      contents: [{ value: 1, text: '增值税普通发票' }, { value: 2, text: '电子发票' }],
    },
    { label: '发票内容',
      type: 'radioBtn',
      key: 'category',
      contents: [{ value: 1, text: '商品明细' }, { value: 2, text: '商品类别' }],
    },
    { label: '抬头类型',
      type: 'radioBtn',
      key: 'targetType',
      contents: [{ value: 1, text: '个人' }, { value: 2, text: '单位' }],
    },
    { label: '个人姓名',
      type: 'text',
      key: 'targetName',
      visible: {
        key: 'targetType',
        value: 1
      },
      placeholder: '请输入个人姓名',
      patterns: [{ required: true, text: '请输入个人姓名'}, { reg: '\\S{2,}', text: '请输入正确的个人姓名' }]
    },
    { label: '抬头名称',
      type: 'text',
      key: 'targetName',
      placeholder: '请输入抬头名称',
      visible: {
        key: 'targetType',
        value: 2
      },
      patterns: [{ required: true, text: '请输入抬头名称'}, { reg: '\\S{2,}', text: '请输入正确的抬头名称' }]
    },
    { label: '单位税号',
      type: 'text',
      key: 'targetTax',
      placeholder: '请输入单位税号',
      visible: {
        key: 'targetType',
        value: 2
      },
      patterns: [{ required: true, text: '请输入单位税号'}, { reg: '\\S{2,}', text: '请输入正确的单位税号' }]
    },
    { label: '收票地址',
      type: 'picker',
      key: 'recipientAddress',
      patterns: [{ required: true, text: '请选择收票地址'}]
    },
    { label: '收件人',
      type: 'text',
      key: 'recipientName',
      placeholder: '请输入收件人姓名',
      patterns: [{ required: true, text: '请输入收件人姓名'}, { reg: '\\S{2,}', text: '请输入正确的收件人姓名' }]
    },
    { label: '手机号',
      type: 'number',
      key: 'recipientMobile',
      placeholder: '请输入收件人联系电话',
      patterns: [{ required: true, text: '请输入收件人电话'}, { reg: '\^\\d\{11\}\$', text: '请输入正确的收件人电话' }]
    },
    { label: '邮箱',
      type: 'text',
      key: 'email',
      placeholder: '请输入收件人邮箱',
      patterns: [{ required: true, text: '请输入收件人邮箱'}, { reg: "^([a-z0-9A-Z]+[-|\\.]?)+[a-z0-9A-Z]@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\\.)+[a-zA-Z]{2,}$", text: '请输入正确的邮箱地址' }]
    }
  ];
  // 订单状态
export const orderStatusMap = {
    1: '待付款',
    2: '支付中', // 等待微信支付结果回调
    3: '待发货', // 已支付
    4: '待收货',
    41: '待使用',
    5: '待评价',
    6: '已退款',
    7: '已完成',
    8: '已关闭', // 超时未支付的、已完成退款的
  };

export const weekEnum = ['一', '二', '三', '四', '五', '六', '日'];


/**
 * 商家资质展示的字段
 */
 export const EnterpriceQualificationList = [
  // { id: 0, label: '企业注册号', key: ''},
  { id: 1, label: '企业名称', key: 'name'},
  { id: 2, label: '类型', key: 'businessCategoryLabel'},
  // { id: 3, label: '住所', key: ''},
  { id: 4, label: '法定代表人', key: 'legalRepresentative'},
  { id: 5, label: '成立时间', key: 'establishTime'},
  { id: 6, label: '注册资本', key: 'registerCapital'},
  // { id: 7, label: '营业期限', key: ''},
  { id: 8, label: '经营范围', key: 'intro'},
  // { id: 9, label: '登记机关', key: ''},
  // { id: 10, label: '核准时间', key: ''},
]
