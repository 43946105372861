/**
 * Created by jimmy on 2019/9/29.
 */

import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter as Router, Route, Switch, useParams, useHistory } from "react-router-dom";
import * as CONST from '../../common/const';
import Page from '../../components/page';
import './index.less';
import * as Request from "../../common/request";
import Banners from "../../components/banners";
import Block from "../../components/block";
import ProductCommentItem from "../../components/product/commentItem";
import ProductItem from "../../components/product/productItem";
import Modal from "../../components/modal";
import * as Utils from "../../common/utils";
import { addToCart } from '../../common/requestList';
import { setBookingDataList } from '../../common/cache';
import AddCount from '../../components/addCount';
import moment from 'moment';
import { Calendar } from 'react-vant';
const KEY_EDIT_ADD_TO_CART = 1;
const KEY_EDIT_BUY_NOW = 2;


const ProductPage = props => {
	const history = useHistory();
	const { id } = useParams();
	const [ data, setData ] = useState({});
	const [ discountStock, setDiscountStock ] = useState(0);
	const [ discountPrice, setDiscountPrice ] = useState(0);
	const [ originPrice, setOriginPrice ] = useState(0);
	const getData = async () => {
		const result = await Request.get({
			url: '/product/get',
			data: {
				id
			}
		});
		if(!result) return;
		const _data = result.data;
		setData(_data);
		setStock(_data.stock || 0);
		setPrice(_data.price || 0);
		setDiscountStock(_data?.discountInfo?.discountStock || 0);
		setDiscountPrice(_data?.discountInfo?.productPrice || 0);
		setOriginPrice(_data?.originalPrice || 0);
	};
	useEffect(() => {
		getData();
	}, [ 123 ]);

	const [ countDownInfo, setCountDownInfo ] = useState(null);
	useEffect(() => {
		if(data?.discountInfo) {
			let endTime = null;
			let formatLabel = null;
			if(data?.discountInfo?.status == CONST.DISCOUNT_STATUS_TO_START) {
				formatLabel = `<span>距离开始还有</span><i>DD天</i><i>HH时</i><i>mm分</i><i>ss秒</i>`;
				endTime = data?.discountInfo?.effectiveTime;
			}
			if(data?.discountInfo?.status == CONST.DISCOUNT_STATUS_PROCESSING) {
				formatLabel = `<span>距离结束还有</span><i>DD天</i><i>HH时</i><i>mm分</i><i>ss秒</i>`;
				endTime = data?.discountInfo?.expiredTime;
			}
			if(!endTime) return;

			Utils.countDown(endTime, result => {
				if(result.ok == 0) {
					setCountDownInfo(result.data);
				}else{
					setCountDownInfo(result.msg || null);
				}
			}, formatLabel);
		}
	}, [ data ]);

	const [ items, setItems ] = useState([]);
	const [ detailItemsList, setDetailItemsList ] = useState([]);
	const [ itemsLabel, setItemsLabel ] = useState('');
	const [ stock, setStock ] = useState(0);
	const [ price, setPrice ] = useState(0);
	const [ minDate, setMinDate ] = useState('');
	const [ maxDate, setMaxDate ] = useState('');

	const getItems = async () => {
		const result = await Request.get({
			url: '/product/items/list',
			data: {
				productId: id
			}
		});
		if(!result) return;
		const { list = [], detailList = [] } = result.data;
		// const list = result.data.list;
		setItems(list);
		setDetailItemsList(detailList);

		let itemsLabel = list.reduce((total, item) => `${total}${item.name}/`, '');
		// 如果是票券类型商品，则增加日期选择项
		// todo 这块是不是接口其实也要有名字返回的
		if (data.type == 2) {
			itemsLabel = itemsLabel ? `${itemsLabel}日期/` : '日期/';
		}
		setItemsLabel(itemsLabel.substr(0, itemsLabel.length - 1));

		// 当前的规格
		const { discountStock, discountPrice } = data;
		let _chooseProductIds = []
		let _currentItemValue = '';
		list.forEach(item => {
			const _value = item.values[0];
			_chooseProductIds.push(_value.id);
			_currentItemValue += (_currentItemValue ? '_' : '') + _value.value;
		});

		let _data = {
			list: list,
			detailList,
		};
		let _currentItemObj = null;
		if (detailList.length) {
			// 校验规格,商品规格的库存，减去购物车中的库存
			// const cartStockMap =  this.data.cartStockMap || {};
			// 当前选中的detailItem的值
			_data.detailList = detailList.map(item => {
				// if (item.stock && cartStockMap[`item_${item.id}`]) {
				//   item.stock = item.stock - cartStockMap[`item_${item.id}`];
				// }
				// 如果有活动库存，则取活动价格
				if (discountStock > 0) {
					item.price = discountPrice;
					item.originalPrice = item.price;
					item.stock = Math.min(item.stock, discountStock);
				}
				if (item.itemValues == _currentItemValue) {
					_currentItemObj = item;
				}
				return item;
			});
			// 库存计算end
			let _price = _currentItemObj?.price;
			let _stock = _currentItemObj?.stock;
			_data = {
				..._data,
				price: _price,
				stock: _stock,
				currentItemId: _currentItemObj?.id || 0,
			}
			setStock(_data.stock);
			setPrice(_data.price);
			} else if (list?.length && !detailList.length) {
				_data.stock = 0;
				setStock(0);
			} else if (!list?.length && !detailList?.length && discountStock) {
				// 无规格且有活动价商品
				_data.stock = Math.min(discountStock, data?.stock);
				_data.price = discountPrice;
				setStock(_data.stock);
				setPrice(_data.price);
			}
			setCurrentItemId(_data.currentItemId);
			setChooseProductIds(_chooseProductIds);
			// 票券类商品库存
			if (data?.type == 2 && detailList.length) {
				initDateList(_currentItemObj.dates || []);
			}
	};
	function initDateList(list) {
		const _list = [...list];
      const _selectedItem = (() => {
        const _newList = [];
        _list.map(item => {
          if(item.status != 2) {
            _newList.push(item);
          }
        })
        return _newList[0];
      })();
      const hasDiscountInfo = data?.discountInfo?.discountStock > 0;
			setDateList(_list);
			setSelectedDate(_selectedItem);
			setMinDate(new Date(moment(_list?.[0]?.date).valueOf()));
			setMaxDate(new Date(moment(_list?.[1]?.date).valueOf()));
			setStock(hasDiscountInfo && Math.min(_selectedItem?.stock || 0, discountStock) || (_selectedItem?.stock || 0));
			setPrice(hasDiscountInfo && Math.min(_selectedItem?.stock || 0, discountStock) || (_selectedItem?.stock || 0));
      // let _state = {
      //   dateList: _list,
      //   selectedDateId: _selectedItem?.id || 0,
      //   selectedDate: _selectedItem?.date || 0,
      //   minDate: moment(_list?.[0]?.date).valueOf(),
      //   maxDate: moment(_list?.[_list.length - 1]?.date).valueOf(),
      //   stock: hasDiscountInfo && Math.min(_selectedItem?.stock || 0, discountStock) || (_selectedItem?.stock || 0),
      //   price: hasDiscountInfo ? discountPrice : _selectedItem?.price || '0.00',
      // };

	}
	useEffect(() => {
		getItems();
	}, [ data ]);

	const [ descriptionList, setDescriptionList ] = useState([]);
	const getDescriptionList = async () => {
		const descriptionSets = {
			1: { label: '参数说明' },
			2: { label: '购买说明' },
			3: { label: '使用说明' }
		};
		for(let type in descriptionSets) {
			const result = await Request.get({
				url: '/product/descriptions/get',
				data: {
					productId: id,
					type
				}
			});
			if(!result) return;
			descriptionSets[type] = {
				...descriptionSets[type],
				...result.data
			}
		}
		const descriptionList = Object.keys(descriptionSets).map(type => descriptionSets[type]);
		setDescriptionList(descriptionList);

	};
	useEffect(() => {
		getDescriptionList();
	}, [123]);

	const [ comments, setComments ] = useState([]);
	const getComments = async () => {
		const result = await Request.get({
			url: '/product/comment/list',
			data: {
				productId: id,
				// page: pageIndex,
				// pageSize: pageSize
			}
		});
		if(!result) return;

		const list = result.data.list;
		setComments(list);
	};
	useEffect(() => {
		getComments();
	}, [123]);

	const TAB_HEIGHT = 36;
	const TAB_MAP = {
		'product': { label: '商品', ref: useRef(null) },
		'detail': { label: '详情', ref: useRef(null) },
		'comment': { label: '评价', ref: useRef(null) },
	}
	const TAB_LIST = Object.keys(TAB_MAP).map(key => ({
		...TAB_MAP[key],
		id: key
	}));
	const [ tab, setTab ] = useState(TAB_LIST[0]);
	const [ showTab, setShowTab ] = useState(false);
	useEffect(() => {
		const onScroll = (event) => {
			const scrollTop = document.documentElement.scrollTop;
			for(let i = 0, len = TAB_LIST.length; i < len; i++) {
				if(scrollTop >= TAB_LIST[i].ref?.current?.offsetTop - TAB_HEIGHT) {
					setTab(TAB_LIST[i]);
				}
			}
			setShowTab(scrollTop > 0 ? true : false);
		};
		window.removeEventListener('scroll', onScroll);
		window.addEventListener('scroll', onScroll, { passive: true });
		return () => window.removeEventListener('scroll', onScroll);
	}, [ 123 ]);

	const collectHandle = async () => {
		const status = data.isCollected ? 2 : 1;
		const result = await Request.post({
			url: '/product/collect/toggle',
			data: { id, status }
		});
		if(!result) return;

		Utils._alert(({ 1: '已收藏', 2: '已取消' })[status]);
		setData({ ...data, isCollected: !data.isCollected });
	};

	const [ descModalFlag, setDescModalFlag ] = useState(false);
	const [ descModalData, setDescModalData ] = useState(null);

	const [ itemsModalFlag, setItemsModalFlag ] = useState(false);
	const [ editType, setEditType] = useState(1);
	const [ count, setCount] = useState(1);
	const [ currentItemId, setCurrentItemId] = useState(0)
	const [ chooseProductIds, setChooseProductIds ] = useState([]);
	const [ dateList, setDateList ] = useState([]);
	const [ selectedDate, setSelectedDate ] = useState(null);
	// const [ itemsModalData, setItemsModalData ] = useState(null);
	/**
	 * 加入购物车
	 */
	const addToShoppingCart = async () => {
		const param = {
			productId: data.id,
			itemId: currentItemId,
			count,
			merchantId: data.merchantId,
		}
		const res = await addToCart(param);
		if (res.data) {
			// this.triggerEvent('success');
			Utils._alert('在购物车等你哦~');
			setItemsModalFlag(false);
		}
	}

	/**
	 * 立即购买
	 */
	const buyNow = () => {
		// const { detailList, data, currentItemId, count, selectedDateId, selectedDate, minDate, maxDate } = this.data;
		if(!data) {
			Utils._alert('数据加载中，请稍候……');
			return;
		}
		let param = {
			productId: data.id,
			count,
			itemId: currentItemId,
			merchantId: data.merchantId,
		};
		// 虚拟商品需要商品的使用日期
		const _itemInfo = detailItemsList.filter(item => item.id == currentItemId)?.[0];
		// setBookingDataList([{
		// 	merchantInfo: { id: data.merchantId },
		// 	productList: [{
		// 		...param,
		// 		productInfo: data,
		// 		itemInfo: _itemInfo,
		// 		selectedDateId: selectedDate.id,
		// 		minDate,
		// 		maxDate,
		// 		selectedDate: selectedDate.date
		// 	}],
		// }]);
		// 如果是虚拟商品，跳转到票圈的订单填写页面
		if (data.type == 1 ) {
			setBookingDataList([{
				merchantInfo: { id: data.merchantId },
				productList: [{
					...param,
					productInfo: data,
					itemInfo: _itemInfo
				}],
			}]);
			history.push('/booking')
		} else if (data.type == 2 ) {
			setBookingDataList([{
				merchantInfo: { id: data.merchantId },
				productList: [{
					...param,
					productInfo: data,
					itemInfo: _itemInfo,
					selectedDateId: selectedDate.id,
					minDate,
					maxDate,
					selectedDate: selectedDate.date
				}],
			}]);
			history.push('/ticket-booking')
		}
	}
	/**
	 * 数量改变
	 */
	const changeNum = (num) => {
		setCount(num);
	}
	/**
	 * 选择对应的item
	 */
	const chooseItemValue = (id, index) => {
      // const { detailList, list, chooseProductIds, detailData } = this.data;
      // const { chooseProductIds } = this.data;
			const list = items;
      // 更新规格
      if (chooseProductIds[index] == id) {
        return ;
      }
      chooseProductIds[index] = id;
      let _state = {
        chooseProductIds: [...chooseProductIds],
        count: 1,
      }
			setCount(1);
			setChooseProductIds([...chooseProductIds]);
      // 更新价格和库存
      const nameArr = [];
      list.forEach((val, i) => {
        nameArr.push(val.values.filter(item => item.id == chooseProductIds[i])[0].value);
      });
      let _dateList = [];
      // 如果detailItems里面有个规格没库存，则库存显示为0
      let isNoStock = true;
			const detailList = detailItemsList;
      detailList.forEach(item => {
        if (item.itemValues == nameArr.join('_')) {
          // 如果是票券累商品，则从dates中获取库存和价格
          isNoStock = false;
          item.dates = (item.dates || []).filter(dItem => !moment(dItem.date).isBefore(moment(new Date()).format('YYYY-MM-DD')));
          if (item.dates) {
            _dateList = item.dates;
          }
          if (data.type == 1) {
            Object.assign(_state, {
              price: item.price,
              stock: item.stock,
            });
						setPrice(item.price);
						setStock(item.stock);
          }
          Object.assign(_state, {
            currentItemId: item.id
          });
					setCurrentItemId(item.id);
					setPrice(item.price);
					setStock(item.stock);
        }
      });
      // 如果没有库存，则显示商品已售罄
      if (isNoStock) {
				setStock(0);
      }
      if (data?.type == 2) {
        initDateList(_dateList);
      }
	}
console.log('data------', data);
	const chooseDate = (data, id) => {
		const _currentData = data;
		if (_currentData.status == 2) {
			return ;
		}

		let _state = {
			selectedDate: _currentData?.date || '',
			stock: _currentData.stock,
			price: _currentData.price,
		};
		if (discountStock > 0) {
			_state.stock = Math.min(_state.stock, discountStock);
			_state.price = discountPrice;
		}
		setSelectedDate(_currentData);
		setStock(_state.stock);
		setPrice(_state.price);
	}
	const [calendarVisible, setCalendarVisible] = useState(false);
	const formatter = (day) => {
		if (dateList.filter(item => item.date == moment(day.date).format('YYYY-MM-DD'))?.[0]?.status == 2) {
			day.type = 'disabled'
		}
		return day;
	}

	const chooseOtherDate = () => {
		setCalendarVisible(true);
	}
	const calendarConfirm = (date) => {
		let _dateList = dateList;
		const _selectedDate = moment(date).format('YYYY-MM-DD');
		const _currentData = dateList.filter(item => item.date == _selectedDate)?.[0];
		if (_currentData) {
			// _dateList[2] = {
			// 	id: _currentData.id,
			// 	label: moment(date).format('MM-DD日')
			// };
		}
		setSelectedDate(_currentData || null);
		// this.setData({
		// 	selectedDateId: _currentData?.id || 0,
		// 	selectedDate: _currentData?.date || '',
		// });
	}
	const toEnterpriseQualification = () => {
		history.push(`/enterprice/${data.merchantInfo.id}`)
	}

	const trackProductDetail = async () => {
		await Request.get({
			hideError: true,
			url: '/track/product',
			data: {
				productId: id,
				pageId: `${CONST.STAT_PAGE_PREFIX_PRODUCT_DETAIL}${id}`,
				type: CONST.STAT_PRODUCT_TYPE_DETAIL
			}
		});
	};
	useEffect(() => {
		trackProductDetail();
	}, []);

	return (
		<Page {...{
			...props,
			page: 'product', title: data.name
		}}>
			<section className="product-container" style={{ minHeight: window.innerHeight }}>
				<div className={`product-tabs${ showTab && ' show' || '' }`}>
					{
						TAB_LIST.map((item, idx) => {
							return <div className={`product-tab${item.id == tab.id ? ' activity' : ''}`} key={idx} onClick={() => {
								const elementPosition = item.ref.current.offsetTop;
								const offsetPosition = elementPosition - (idx == 0 ? 0 : TAB_HEIGHT);
								window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
							}}>{item.label}</div>
						})
					}
				</div>

				<Banners {...{
					list: data.images?.length > 0 && data.images.map(img => ({ cover: img })) || [],
					style: { height: window.innerWidth },
					refProp: TAB_MAP.product.ref
				}} />

				{
					data?.discountInfo && <div className="discount-wrapper">
						<span>限时优惠</span>
						<span className="activity-countdown" dangerouslySetInnerHTML={{ __html: countDownInfo }} />
					</div>
				}

				<Block className="product-info-wrapper">
					<div className="info-wrapper">
						<div className="price-info">
							<div className="price">
								<span className="value">¥<i>{data.price}</i></span>
								{ data.originalPrice && <span className="origin-value">价格：<i>{data.originalPrice}</i></span> }
							</div>
							{
								data.tag && <div className="tags">
									<span className="tag-item">{data.tag}</span>
								</div>
							}
						</div>
						<div className="handler-wrapper">
							<div className="handler-item" onClick={collectHandle}>
								<em className={`iconfont ${data.isCollected ? 'icon-collected' : 'icon-collect'}`} />
								<span>收藏</span>
							</div>
							<div className="handler-item" onClick={() => {
								Utils.wx.share({
									title: data.name, // 分享标题
									desc: data.intro, // 分享描述
									link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
									imgUrl: CONST.MEDIA_HOST + data.images[0], // 分享图标
								});
							}}>
								<em className="iconfont icon-share" />
								<span>分享</span>
							</div>
						</div>
					</div>
					<div className="message-wrapper">
						<span className="name">{data.name}</span>
						<span className="intro" dangerouslySetInnerHTML={{ __html: data?.intro?.replace('\n', '<br />') }} />
					</div>
				</Block>

				{
					itemsLabel && <Block style={{ marginTop: 10 }}>
						<div className="product-item-label" onClick={() => setItemsModalFlag(true)}>
							<label>选择</label><span>选择{itemsLabel}</span>
						</div>
					</Block>
				}

				{
					descriptionList?.length > 0 && descriptionList.map((item, idx) => {
						const labelArray = item?.descriptions?.length > 0 && item?.descriptions.map(desc => desc.label) || [];
						if(!labelArray || labelArray.length <= 0) return null;
						const label = labelArray.join(' ');
						return (
							<Block style={{ marginTop: 10 }} key={idx} onClick={() => {
								setDescModalFlag(true);
								setDescModalData(item);
							}}>
								<div className="product-item-label">
									<label>{item.title}</label><span>{label}</span>
								</div>
							</Block>
						)
					})
				}
				{/* 商家资质 */}
				<Block style={{ marginTop: 10 }} onClick={toEnterpriseQualification}>
					<div className="product-item-label">
						<div className='flex1'>商家资质</div>
						<div className="flex flex-v-c">
							<img src={`${CONST.ICON_BASE_URL}national_emblem_icon.png`} className="national-emblem-img" />
							<div className="iconfont icon-arrow-right"></div>
						</div>
					</div>
				</Block>
      {/* <div className="block-item" bindtap="toEnterpriseQualification">
        <div className="flex1 flex">
          <span className="color-999 flex1">商家资质</span>
          <div className="flex flex-v-c">
            <image src={`${CONST.MEDIA_HOST}national_emblem_icon.png`} className="national-emblem-img" />
            <div className="iconfont icon-arrow-right"></div>
          </div>
        </div>
      </div> */}

				<Block className="product-detail-container" refProps={TAB_MAP.detail.ref}>
					<div className="product-detail-header"><span>商品详情</span></div>
					<div className="product-detail-body" dangerouslySetInnerHTML={{ __html: data.detail }} />
				</Block>

				<Block className="product-comment-container" title="商品评价" refProps={TAB_MAP.comment.ref} >
					{
						comments?.length > 0 && comments.map((item, idx) => {
							return <ProductCommentItem {...{
								data: item
							}} key={idx} />
						})
					}
				</Block>
			</section>

			<div className="product-bottom-container">
				<div className="bottom-nav-item" onClick={() => history.push('/')}><em className="iconfont icon-home" /><span>店铺</span></div>
				<div className="bottom-nav-item" onClick={() => window.location.href = CONST.customerServiceLink}><em className="iconfont icon-customer-service" /><span>客服</span></div>
				<div className="bottom-nav-item" onClick={() => history.push('/cart')}><em className="iconfont icon-cart" /><span>购物车</span></div>
				{
					data.status == 1 && <>
						{
							data.stock > 0 && <div className={`bottom-button-list ${data.type == 1 ? '' : 'one'}`}>
								{ data.type == 1 && <div className="bottom-button-item add-cart" onClick={() => {
									setItemsModalFlag(true);
									setEditType(KEY_EDIT_ADD_TO_CART);
								}}>加入购物车</div> }
								<div className={`bottom-button-item ${data.type == 1 ? '' : 'one'}`} onClick={() => {
									setItemsModalFlag(true);
									setEditType(KEY_EDIT_BUY_NOW);
								}}>立即购买</div>
							</div> || <div className="bottom-button-item disabled flex1">已售罄</div>
						}
					</>
				}
				{ data.status == 2 && <div className="bottom-button-item disabled flex1">已下架</div> }
			</div>

			<Modal {...{
				visible: descModalFlag,
				title: descModalData?.title || '',
				buttonOption: {
					onClick: () => {
						setDescModalFlag(false);
						setDescModalData(null);
					}
				}
			}}>
				<div className="modal-product-description-wrapper">
					{
						descModalData?.descriptions?.length > 0 && descModalData.descriptions.map((item, idx) => {
							return (
								<div className="product-description-item" key={idx}>
									<label>{item.label}</label>
									<span>{item.value}</span>
								</div>
							)
						})
					}
				</div>
			</Modal>

			<Modal {...{
				visible: itemsModalFlag,
				buttonOption: {
					text: !stock ? '商品已售罄' : (editType === KEY_EDIT_ADD_TO_CART ? '加入购物车' : '立即购买'),
					disabled: !stock,
					onClick: () => {
						if (editType === KEY_EDIT_ADD_TO_CART) {
							addToShoppingCart();
						} else {
							buyNow();
						}

					}
				}
			}}>
				<div className="modal-product-items-wrapper">
					<em className="iconfont icon-close" onClick={() => setItemsModalFlag(false)} />
					<ProductItem {...{
						mode: 'ltr',
						showCartIcon: false,
						data: {
							...data,
							productPrice: price || data.price,
							stock: stock || data.stock
						}
					}} />

					<div className="modal-specifications-list-wrapper">
						{
							items?.length > 0 && items.map((item, idx) => {
								return (
									<div className="specifications-item-wrapper">
										<div className="specifications-label">{item.name}</div>
										{
											item?.values?.length > 0 && item.values.map((itemValue, itemIndex) => {
												return <span className={`specifications-detail-item ${itemValue.id == chooseProductIds[idx] ? 'active' : ''}`} onClick={() => chooseItemValue(itemValue.id, idx)} key={itemIndex}>{itemValue.value}</span>
											})
										}
									</div>
								)
							})
						}
						{
							data.type == 2 && dateList?.length ? <div className='specifications-item-wrapper'>
								<div className="specifications-label">日期</div>
								<div>
									{
										dateList.map((dateItem, dateIndex) => dateIndex<=3 ? <span className={`specifications-detail-item ${dateItem.status == 2 ? 'disabled' : ''} ${selectedDate?.id == dateItem.id ? 'active' : ''}`} onClick={() => chooseDate(dateItem, dateItem.id)}>{dateItem.date}</span> : null)
									}
          				<span class="specifications-detail-item" onClick={chooseOtherDate}>更多日期</span>
								</div>
							</div> : null
						}
					</div>
					{
						stock > 0 ? <div className='add-count-wrapper'>
							<div className='add-count-label flex1'>购买数量：</div>
								<AddCount
										change={(n) => changeNum(n)}
										max={stock}
										value={count}
									/>
							</div> : null
					}


				</div>
			</Modal>
			{
				calendarVisible ? <Calendar
				visible={calendarVisible }
				onClose={() => setCalendarVisible(false)}
				onConfirm={calendarConfirm}
				minDate={minDate}
				maxDate={maxDate}
				defaultValue={new Date(selectedDate)}
				formatter={formatter}
		/> : null
			}

		</Page>
	);
};

export default ProductPage;
