/**
 * Created by jimmy on 2022/9/25.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as CONST from '../../../common/const';
import * as Utils from '../../../common/utils';
import * as Request from '../../../common/request';
import './index.less';
import {ORDER_STATUS_TO_PAY} from "../../../common/const";
import { confirmReceipt } from '../../../common/requestList';

const CompOrderButtonModel = props => {
	const history = useHistory();
	const { style = {}, data = {}, from = '' } = props;
	const isFromDetail = from == 'detail';

	const toDetail = () => {
		console.log('data--', data);
		if(data.type == CONST.ORDER_TYPE_PHYSICAL) {
			history.push(`/order/${data.id}`);
		}else if(data.type == CONST.ORDER_TYPE_TICKET) {
			history.push(`/order/ticket/${data.id}`);
		}
	};

	const toPay = async (orderId) => {
		const ua = Utils.getUA();
		const result = await Request.post({
			url: '/order/pay',
			data: {
				id: orderId,
				payment: ua.isInWechat ? CONST.ORDER_PAY_METHOD_WX_JS : CONST.ORDER_PAY_METHOD_WX_H5
			}
		});
		if(!result) return;

		Utils.wx.pay(result.data, {
			success: () => {
				if (props.refresh) {
					props.refresh();
				}
			},
			error: (error) => {
				console.log('error', error);
			}
		});
	};
	const toShippingInfo = (id) => {
		history.push(`/shipping-info/${id}`)
	}

	const confirmReceiptHanlder = async (id) => {
		const res = await confirmReceipt({
			id
		});
		if (res?.ok == 0) {
			Utils._alert('确认收货成功', () => {
				window.location.reload();
			});

		}
	}

	const dom = [
		// !isFromDetail && <div className="button-item" key="topay" onClick={toDetail}>查看详情</div>
	];
	// 1-待支付；2-支付中；3-待发货；4-待收货；41-待使用；5-待评价；6-已退款；7-已完成；8-已关闭；
	switch(data?.status){
		case CONST.ORDER_STATUS_TO_PAY:
			dom.push(
				<div className="button-item button-primary-item" key="to-pay" onClick={() => {
					toPay(data.id);
				}}>去支付</div>
			);
			break;
		case CONST.ORDER_STATUS_TO_RECEIVE:
			dom.push(
				<div className="button-item" key="to-receive" onClick={() => toShippingInfo(data.id)}>查看物流</div>,
				<div className="button-item button-primary-item" key="to-use" onClick={() => confirmReceiptHanlder(data.id)}>确认收货</div>
			);
			break;
		case CONST.ORDER_STATUS_TO_COMMENT:
			if(!isFromDetail) {
				dom.push(
					<div className="button-item button-primary-item" key="to-comment" onClick={toDetail}>立即评价</div>
				);
			}
			break;
		case CONST.ORDER_STATUS_COMPLETED:
			dom.push(
				<div className="button-item" key="completed" onClick={() => history.push('/')}>再次购买</div>
			);
			break;
		case CONST.ORDER_STATUS_TO_USE:
			dom.push(
				<div className="button-item button-primary-item" key="to-use" onClick={toDetail}>去使用</div>
			);
			break;
		default:
			break;
	}

	return (
		<section className="comp-order-button-wrapper" style={style}>{dom}</section>
	);
};

export default CompOrderButtonModel;
