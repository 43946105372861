import React, { useState, useEffect } from 'react';
import './index.less';

const AddCountComp = ({ max, min = 1, change, value }) => {
  const [num, setNum] = useState(1)
  // 1是加法，2是减法
  function changeNum(t) {
    console.log('最大值', max, min);
    // 加法
    const bol1 = t == 1 && num >= max;
    const bol2 = t == 2 && num <= min;
    if (bol1 || bol2) {
      return ;
    }
    const _num = t == 1 ? num + 1 : num - 1;
    setNum(_num);
    change(_num);
  }
  useEffect(() => {
    setNum(value);
  }, [value])
  
  return <>
    <div className="add-count-wrapper">
      <div className={`add-count-block ${num <= min ? 'disable' : ''}`} onClick={() => changeNum(2)}></div>
      <div className="num">{num}</div>
      <div className={`add-count-block add ${num >= max ? 'disable' : ''}`} onClick={() => changeNum(1)}></div>
    </div></>
}
export default AddCountComp;
