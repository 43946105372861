/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as CONST from '../../common/const';
import Page from '../../components/page';
import './index.less';

const PageModel = props => {
	useEffect(() => {
		const appId = CONST.appId;
		const code = Utils.getSearchByKey('code');
		const state = Utils.getSearchByKey('state');
		const redirectUrl = Utils.getSearchByKey('redirectUrl');
		console.log('code===',code);
		if(!code) {
			const url = `https://open.weixin.qq.com/connect/oauth2/authorize?${[
				`appid=${appId}`,
				`redirect_uri=${encodeURIComponent(Utils.user._wxRedirectUriReset(window.location.href))}`,
				`response_type=code`,
				`scope=snsapi_base`,
				`state=STATE_BASE`
			].join('&')}#wechat_redirect`;
			window.location.href = url;
		}else{
			window.location.href = `${redirectUrl}?code=${code}&state=${state}`;
		}
	}, [ 123 ]);

	return (
		<Page {...{
			...props,
			page: 'login', title: '用户登录'
		}}>
			<section className="login-container" style={{ minHeight: window.innerHeight }}>
				<span>登录中……</span>
			</section>
		</Page>
	);
};

export default PageModel;
