/**
 * Created by jimmy on 2022/9/25.
 */

import React, { useState, useEffect } from 'react';
import moment from "moment";
import * as CONST from '../../../common/const';
import './index.less';

const CompProductItemModel = props => {
	const { style = {}, data } = props;

	if(!data) return null;

	return (
		<div className="comp-comment-item-wrapper">
			<div className="user-info">
				<img src={data.userInfo.avatar} className="comment-avatar-img"/>
				<div className="nickname">{data.userInfo.nickname}</div>
				<div className="desc">
					<span>{data.createdAt && moment(data.createdAt).format('YYYY-MM-DD')}</span>
					{
						data?.productInfo?.itemInfo?.itemValues && <span style={{ paddingLeft: 6 }}>{data.productInfo.item1}：{data.productInfo.itemInfo.itemValues.replace('_', ' ')}</span>
					}
				</div>
			</div>
			<div className="comment-desc">{data.content}</div>
			<div className="comment-img-wrapper">
				{
					data.images?.length > 0 && data.images.map((imgItem, imgIdx) => {
						return <img className="comment-img" src={`${CONST.MEDIA_HOST}${imgItem}`} key={imgIdx} />
					})
				}
			</div>
			{ data.replyContent && <div className="reply-wrapper">商家回复：<span>{data.replyContent}</span></div> }
		</div>
	)
};

export default CompProductItemModel;
