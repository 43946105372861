/**
 * 评论
 */
 import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ICON_BASE_URL, MEDIA_HOST } from '../../common/const';
import { addComment, getOrderDetail, uploadCommentImg } from '../../common/requestList';
import { wx, _alert } from '../../common/utils';
 import './index.less';
 const AddComment = () => {
   const { id, productId, productOrderId } = useParams();
   const [orderDetailData, setOrderDetailData] = useState(null);
   const [productData, setProductData] = useState(null);
   const [commentText, setCommentText] = useState('');
   const [imgList, setImgList] = useState([]);
   const [logisticsScore, setLogisticsScore] = useState(0);
   const [serviceScore, setServiceScore] = useState(0);
   const [headImage, setheadImage] = useState('');

   // 用户输入
  function changeText(e) {
    setCommentText(e.currentTarget.value);
    // this.setData({
    //   commentText: e.detail.value
    // });
  }
  // 评分
  function starHandler(index, key) {
    if (key == 1) {
      setLogisticsScore(index);
      return false;
    }
    if (key == 2) {
      setServiceScore(index);
      return false;
    }
  }
  async function chooseImage() {
    const _imgList = imgList || [];
    const self = this;
    await wx.sign();
    const localId = await wx.chooseWxImage({}); // 选择图片
    const serverId = await wx.uploadWxImage(localId);
    const localData = await wx.getWxImageLocalData(localId, serverId);
    console.log('选择图片的base64', localData);
    // chooseImage(_resFile => {
    //   if (_resFile) {
    //     self.setData({
    //       imgList: [..._imgList, _resFile]
    //     });
    //   }
    // })
  }
  /**
   * 校验参数是否合规
   */
  function validParam() {
    if (!commentText) {
      _alert('请输入评价内容');
      return false;
    }
    if (!logisticsScore) {
      _alert('请给店铺的物流打分');
      return false;
    }
    if (!serviceScore) {
      _alert('请给店铺的服务打分');
      return false;
    }
    return true;
  }
  /**
   * 删除元素
   */
  function deleteImg(index) {
    const _imgList = imgList || [];
    _imgList.splice(index, 1);
    setImgList(_imgList);
    // this.setData({
    //   imgList: _imgList,
    // });
  }
  async function submit() {
      const isValid = validParam();
      if (!isValid) {
        return false;
      }
      // 评论的时候，先获取用户头像和昵称
      // if (!app.globalData.userInfo?.nickname) {
      //   await this.getUserInfo();
      // }
      // const { id, productId, productOrderId, logisticsScore, serviceScore, commentText, imgList } = this.data;
      const param = {
        orderId: id,
        productId,
        orderProductId: Number(productOrderId),
        logisticsScore,
        serviceScore,
        content: commentText,
        images: imgList
      };
      const res = await addComment(param);
      if (res?.data) {
        _alert('评价成功');
        setTimeout(() => {window.history.go(-1)}, 2000);
      }
    }
    const uploadHandle = async (e,  idx) => {
      const selectedFile = e.target.files[0];
      const formData = new FormData();
      formData.append('file', selectedFile);
      const result = await uploadCommentImg(formData);
      if(!result) return;
  
      const src = result.data.src;
      console.log('imgList', imgList);
      setImgList([...imgList, src]);
    };

   async function getDetailData() {
    if (id == undefined) {
      return ;
    }
    const res = await getOrderDetail({
      id: id,
    });
    const _data = res?.data;
    let _headImage = '';
    let _productData = null;
    if (_data?.productList?.length) {
      _productData = _data.productList.filter(item => item.id == productOrderId)?.[0] || _data.productList[0];
      _headImage = _productData.productInfo.images[0];
    }
    setOrderDetailData(_data);
    setheadImage(_headImage);
    setProductData(_productData);
  }
  useEffect(() => {
    getDetailData()
  }, [])
   
  return <>
    <div className="comment-container">
      {
        orderDetailData ? <div className="product-container">
          <img className="product-image" src={`${MEDIA_HOST}${headImage}`} />
          <div className="product-title">{productData?.productInfo?.name || ''}</div>
          <div className="product-desc">客观真实的评价能帮助用户做决策</div>
        </div> : null
      }
      
      <div className="comment-wrapper">
        { /* 输入评论 */}
        <textarea className="comment-textarea" placeholder-style="color: #999999;text-align: center;" onInput={changeText} placeholder="从多个角度评价宝贝，可以帮助更多想买的人" />
        <div className={`comment-img-container ${imgList.length == 0 ? 'none-img' : ''}`}>
          { /* 已上传的图片 */}
          {
            imgList?.map((item, index) => <div className="choosed-img-wrapper" key={`${item}_${index}`}>
            <div className="iconfont icon-close" onClick={() => deleteImg(index)}></div>
            <img src={`${MEDIA_HOST}${item}`} className="choosed-img" alt='图片' />
          </div>)
          }
          
          { /* 拍买家秀 */}
          {
            imgList?.length < 3 ? <div className="camera-img-wrapper">
              <input type="file" accept= "image/*" className='upload-block' onChange={(e) => {
                    uploadHandle(e);
              }} />
              <img className="camera-img" src={`${ICON_BASE_URL}camera.png`} alt='图片' />
              <div className="color-999 f-20 tc">拍买家秀</div>
            </div> : null
          }
          
        </div>
      </div>
      <div className="comment-score-wrapper">
        <div className="shop-title">店铺：{orderDetailData?.merchantInfo?.name}</div>
        <div className="score-item">
          <span className="score-item-label">物流服务</span>
          <div className="score-wrapper">
            {
              [1,2,3,4,5].map(item => <img className="star-img" key={`score_1_${item}`} onClick={() => starHandler(item, 1)} src={`${ICON_BASE_URL}${item <= logisticsScore ? 'star_orange' : 'star'}.png`} />)
            }
            
            </div>
        </div>
        <div className="score-item">
          <span className="score-item-label">服务态度</span>
          <div className="score-wrapper">
            {
              [1,2,3,4,5].map(item => <img className="star-img" key={`score_2_${item}`} onClick={() => starHandler(item, 2)} src={`${ICON_BASE_URL}${item <= serviceScore ? 'star_orange' : 'star'}.png`} />)
            }
          </div>
        </div>
      </div>
      <div className="submit-btn" onClick={submit}>立即发布</div>
    </div>
  </>
 }

 export default AddComment;
