/**
 * 1:参数说明
 * 2:购买说明
 * 3:使用说明
 */
import React, { useState, useEffect } from 'react';
import Modal from '../modal/index';
import { getProductDescriptions } from './../../common/requestList';
import './index.less';

const ProductDescModal = ({ show, currentType, types, productId, close, aftergetdata }) => {
  const [descData, setDescData] = useState({
    1: null,
    2: null,
    3: null
  });
  async function getDescInfoData(t) {
    const res = await getProductDescriptions({
      productId,
      type: t,
    });
    if (res?.data) {
      let _state = {...descData};
      _state[t] = res.data;
      setDescData(_state);
    }
    aftergetdata({
      type: t,
      data: res?.data
    });
  }
  useEffect(() => {
    (types || []).forEach(item =>  {
      getDescInfoData(item)
    })
  }, [])
  return <>
      <Modal {...{
				title: descData[currentType]?.title,
				visible: show,
				buttonOption: {
					text: '关闭',
					onClick: () => {
						close();
					}
				}
			}}>
        <div className='product-desc-modal-container'>
          <div className="view-content">
            { /** 参数说明  */ }
            {
              descData[currentType] ? <div className="use-note-modal-content modal-content-item">
                {
                  descData[currentType]?.descriptions?.map(item => <div className="desc-item" key={item.label}>
                    <div className="desc-item-title">{item.label}</div>
                    <div className="desc-item-text">{item.value}</div>
                  </div>)
                }
              </div> : null
            }
          </div>
        </div>
      </Modal>
  </>
}

export default ProductDescModal;