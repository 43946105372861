/**
 * Created by jimmy on 2022/9/25.
 */

import React, { useState, useEffect } from 'react';
import * as CONST from '../../common/const';
import Button from '../../components/button';
import './index.less';

const CompBlockModel = props => {
	const { title = '', visible = false, position = 'bottom', buttonOption = { text: '我知道了' }, maskClose = void(0) } = props;

	if(visible) {
		document.body.style.position = 'fixed';
	}else{
		document.body.style.position = 'inherit';
	}

	if(!visible || !props.children) return null;

	return (
		<section className="comp-modal-wrapper">
			<div className="comp-modal-mask" onClick={() => maskClose?.()} />
			<div className={`comp-modal-body ${position}`}>
				{ title && <div className="comp-modal-title">{title}</div> }
				<div className="comp-modal-content">{props.children}</div>
				<Button {...{
					style: { margin: 20 },
					...buttonOption,
				}} />
			</div>
		</section>
	)
};

export default CompBlockModel;
