import React, { useState, useEffect } from 'react';
import { ICON_BASE_URL } from '../../common/const';

import './index.less';

const EmptyCartConetent = ({ title, desc}) => {
	return (
		<div className="empty-block">
            <img className="empty-img" src={`${ICON_BASE_URL}shipping_cart_empty.png`} />
            {
                title ? <div className="title">{title}</div> : null
            }
            {
                desc ? <div className="desc" >{desc}</div> : null
            }
            
            <slot></slot>
        </div>
	);
};

export default EmptyCartConetent;