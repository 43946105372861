/**
 * 退票页面
 */
import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {ICON_BASE_URL, MEDIA_HOST} from '../../common/const';
import {applyRefund, getOrderDetail, getRefundReasons, uploadImg, uploadRefundImg} from '../../common/requestList';
import { wx, _alert} from '../../common/utils';
import * as Request from '../../common/request';
import Modal from "../../components/modal";
import './index.less';
import {upload} from "../../common/request";

const RefundPage = ({}) => {
  const history = useHistory();
  const {id, productId, productOrderId} = useParams();
  const [orderId, setOrderid] = useState(0);
  const [productStatus, setProductStatus] = useState(0);
  const [refundReasonStatus, setRefundReasonStatus] = useState(0);
  const [detailData, setDetailData] = useState(null);
  const [productData, setProductData] = useState(null);
  const [reasonText, setReasonText] = useState('');
  const [supplementaryDescVoucherImgs, setSupplementaryDescVoucherImgs] = useState([]);
  const [refundReasons, setRefundReasons] = useState([]);
  const [checkedReasonId, setCheckedReasonId] = useState(0);
  const [modalCheckedReason, setModalCheckedReason] = useState(null);
  const [checkedReasonText, setCheckedReasonText] = useState('');
  const [refundReasonModalVisible, setRefundReasonModalVisible] = useState(false);
  const [refundPrice, setRefundPrice] = useState(0);


  /**
   * 确定退款
   */
  async function submitRefund() {
    const isValid = validRefundParam();
    if (!isValid) {
      return false;
    }
    ;
    let param = {
      id: Number(id),
      productId: Number(productId),
      orderProductId: Number(productOrderId),
      itemId: productData?.productInfo?.itemInfo?.id,
      reasonType: checkedReasonId,
      reason: reasonText,
      images: supplementaryDescVoucherImgs || []
    };
    // 查看申请退款的图片
    const res = await applyRefund(param);
    if (res?.ok == 0) {
      _alert('申请成功');
      setTimeout(() => {
        history.go(-1);
      }, 2000);
    }
  }

  function validRefundParam() {
    if (!checkedReasonId) {
      _alert('请选择退款理由');
      return false;
    }
    return true;
  }

  /**
   * 用户输入
   */
  function changeReasonText(e) {
    const value = e.currentTarget.value;
    setReasonText(value);
  }

  async function getRefundReason() {
    const res = await getRefundReasons({id});
    const _list = res?.data?.list || [];
    setRefundReasons(_list);
  }

  /**
   * 获取订单详情
   */
  async function getDetailData() {
    if (id == undefined) {
      return;
    }
    const res = await getOrderDetail({
      id
    });
    const _data = res?.data;
    let _product = null;
    let _refundLen = 0; // 如果出了当前的商品，其他的都申请退款了，则，这个加上运费
    if (_data?.productList?.length) {
      _data.productList.forEach(item => {
        if (item.id == productOrderId) {
          _product = item;
        } else if (item.refundStatus && item.refundStatus != 2) {
          _refundLen++;
        }
      })
    }
    // const _product = _data?.productList?.filter(item => item.id == productOrderId)?.[0];
    // 退款金额
    let _refundPrice = 0;
    if (_product) {
      _refundPrice = _product.price * 100 * _product.count / 100;
    }
    if (_refundLen + 1 == _data?.productList?.length) {
      _refundPrice = (Number(_data?.shipping || 0) * 100 + _refundPrice * 100) / 100;
    }
    setDetailData(_data);
    setRefundPrice(_refundPrice);
    setProductData(_product || null)
  };

  function chooseReason(item) {
    // setCheckedReasonId(id);
    // setModalCheckedReasonId
    setModalCheckedReason(item);
    
  }

  function togglaModal(bol) {
    setRefundReasonModalVisible(bol);
  }

  function chooseStatus() {
    togglaModal(true);
  }

  function closeModal() {
    togglaModal(false);
  }

  /**
   * 删除元素
   */
  function deleteImg(index, list) {
    const _imgList = [...list] || [];
    _imgList.splice(index, 1);
    setSupplementaryDescVoucherImgs(_imgList);
  }

  async function chooseImage() {
    // const _imgList = imgList || [];
    const self = this;
    await wx.sign();
    const localId = await wx.chooseWxImage({}); // 选择图片
    const serverId = await wx.uploadWxImage(localId);
    const localData = await wx.getWxImageLocalData(localId, serverId);
    // const str = localData.replace('wxLocalResource:', '');
    const _type = localData.split(';')[0].replace('data:', '');
    console.log('选择图片的base64', localData, _type);
    // console.log('选择图片的base64', base64toBlob(localData, _type));
    // uploadImg(base64toBlob(str, 'png'));
    // chooseImage(_resFile => {
    //   if (_resFile) {
    //     self.setData({
    //       imgList: [..._imgList, _resFile]
    //     });
    //   }
    // })
  }

  useEffect(() => {
    getRefundReason();
    getDetailData();
  }, [])

  const uploadHandle = async (e,  idx) => {
    const selectedFile = e.target.files[0];
    const formData = new FormData();
    console.log('selectedFile', selectedFile)
    formData.append('file', selectedFile);
    // const result = await Request.upload({
    //   url: '/order/refund/upload',
    //   data: formData
    // });
    const result = await uploadRefundImg(formData);
    if(!result) return;

    const src = result.data.src;
    setSupplementaryDescVoucherImgs([...supplementaryDescVoucherImgs, src]);
    console.log('src', src);
  };

  return <>
    <div className="container refund-page-container">
      <div className="refund-order-wrapper">
        <div className="refund-info-item bd-b">
          <div className="label require">退款原因</div>
          <div className="content" onClick={chooseStatus}>
            {
              checkedReasonText ? <span className="text">{checkedReasonText}</span> :
                <span className="placeholder-label">请选择</span>
            }
            <div className="iconfont icon-arrow-right"></div>
          </div>
        </div>
        <div className="refund-money-info">
          <div className="label require">预计退款金额</div>
          <div className="money">¥{refundPrice}</div>
        </div>
      </div>
      <div className="refund-order-other">
        <div className="f-24 lh-1 color-black mg-b-30">补充描述和凭证</div>
        <div className="refund-info-wrapper">
          <textarea className="refund-info-textarea" onInput={changeReasonText} placeholder="补充描述，有助于商家更好的处理售后问题。"/>

          <div className="flex flex-v-c">
            {
              supplementaryDescVoucherImgs?.length < 3 ?
                <div className="supplementary-voucher-wrapper">
                  <input type="file" accept= "image/*" className='upload-block' onChange={(e) => {
                    uploadHandle(e);
                  }} />
                  <img src={`${ICON_BASE_URL}camera.png`} className="camera-icon"/>
                  <span className="color-999 f-12">上传凭证</span>
                  <span className="color-999 f-12">(最多3张)</span>
                </div> : null
            }

            {
              supplementaryDescVoucherImgs?.map((item, index) => <div className="choosed-img-wrapper">
                <div onClick={() => deleteImg(index, supplementaryDescVoucherImgs)} className="iconfont icon-close"></div>
                <img src={`${MEDIA_HOST}${item}`} className="choosed-img"/>
              </div>)
            }

          </div>
        </div>
      </div>
      <div className="submit-btn" onClick={submitRefund}>提交</div>
      <Modal {...{
        visible: refundReasonModalVisible,
        title: '退款原因',
        maskClose: closeModal,
        buttonOption: {
          text: '提交',
          onClick: () => {
            setCheckedReasonText(modalCheckedReason.label);
            setCheckedReasonId(modalCheckedReason.value);
            closeModal();
          }
        }
      }}>
        <div className="reason-list-wrapper refund-modal-container">
          {
            refundReasons?.map(item => <div className="reason-item flex" key={item.label}
                                            onClick={() => chooseReason(item)}>
              <div className="flex1">{item.label}</div>
              {
                modalCheckedReason?.value == item.value ?
                  <img className="check-icon checked" src={`${ICON_BASE_URL}checked_icon.png`}/> :
                  <span className="check-icon"></span>
              }
            </div>)
          }

        </div>
      </Modal>
    </div>
  </>
}

export default RefundPage;
