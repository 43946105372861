/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { computeTotalPrice, toast, _alert } from '../../common/utils';
import * as Const from '../../common/const';
import Page from '../../components/page';
import './index.less';
import { addToCart, deleteCartItem, getCardListData, getPageConfig } from '../../common/requestList';
import { setBookingDataList } from '../../common/cache';
import EmptyCartConetent from '../../components/emptyCartContent';
import AddCount from '../../components/addCount';
import { useHistory } from 'react-router-dom';

const CartPage = props => {
	const history = useHistory();
	const [ data, setData ] = useState(window.MALL_DATA);
	const getData = async () => {
		setData(window.MALL_DATA);
	};
	useEffect(() => {
		if (!data) {
			setTimeout(() => {
				getData();
			}, 300);
		}
		return () => false;
	}, [ 123 ]);

	const [list, setList] = useState([]);
	const [ showEmpty, setShowEmpty ] = useState(false);
	const [ systemInfo, setSystemInfo ] = useState(null);
	const [ bottomHeight, setBottomHeight ] = useState(0);
	const [ pageIndex, setPageIndex ] = useState(0);
	const [ pageSize, setPageSize ] = useState(10);
	const [ total, setTotal ] = useState(0);
	const [ totalPrice, setTotalPrice ] = useState(0);
	const [ checkAll, setCheckAll ] = useState(false);
	const [ checkedProductIds, setCheckedProductIds ] = useState([null]);
	/**
   * 获取购物车列表
   */
	const getList = async () => {
			const res = await getCardListData({
				page: pageIndex,
				pageSize
			});
			// wx.hideLoading();
			const oriList = list || [];
			let _list = res?.data?.list || [];
			_list = _list.map(item => {
				let _item = undefined;
				if(oriList?.length && item.merchantInfo.id && oriList.filter(oitem => oitem.merchantInfo.id == item.merchantInfo.id)?.length) {
					[ _item ] = oriList.filter(oitem => oitem.merchantInfo.id == item.merchantInfo.id);
				//   item.merchantInfo.checked = _item.merchantInfo.checked || false;
				}
				item.productList.forEach(pItem => {
					if(oriList?.length > 0 && !!_item) {
						const [ _pItem ] = _item.productList.filter(opitem => opitem.id == pItem.id);
						pItem.checked = _pItem?.checked || false;
					}else{
						pItem.checked = false;
					}
				});
				// 根据当前商品的状态，判断店铺是否是全选的状态
				item.merchantInfo.checked = !!(item.productList.filter(item => item.checked)?.length == item.productList?.length);
				// 价格展示
				// item.tips = showMerchantTips(item.productList).tips;
				return {...item};
			});
			setList([]);
			setListData(_list);
			setTotal(res?.data?.count || 0);
			setShowEmpty(pageIndex == 0 && !(res?.data?.list?.length))
	}
		/**
		 * 商品选中逻辑
		 */
	const toggleCheck = (pItem, item, merchantindex) => {
		console.log('商品选中逻辑', pItem, item);
			let _list = list;
			let _merchantInfo = item.merchantInfo;
			const _productList = item.productList;
			const _productInfo = pItem;
			_productInfo.checked = !pItem.checked;

			const bol1 = _productList.filter(item => item.checked)?.length == _productList.length;
			_merchantInfo.checked = bol1 ? true : false;
			_list[merchantindex] = {
				merchantInfo: {..._merchantInfo},
				productList: [..._productList]
			}
			setListData([..._list]);
		}
		/**
		 * 店铺全选逻辑
		 */
	const toggleMerchantCheck = (id, index) => {
		let _data = list[index];
		// 如果全部商品都disable
		// if (_data.merchantInfo.disabled) {
		//   return ;
		// }
		const _merchantChecked = _data.merchantInfo.checked;
		_data.merchantInfo.checked = !_merchantChecked;
		_data.productList = _data.productList.map(item => {
			if (filterValidProduct(item)) {
				item.checked = !_merchantChecked;
			}
			return {...item};
		})
		setListData([...list]);
	}
	/**
	 *
	 * @param {*} list
	 */
	const	filterValidProduct = (item) => {
		return true;
		// return (item.itemInfo?.stock || item.productInfo?.stock) >= item.count;
	};
		/**
		 * 计算商品总价
		 */
	const	setListData = (list = []) => {
		let _totalPrice = 0;
		list.forEach(item => {
			if (item.merchantInfo.checked) {
				_totalPrice += computeTotalPrice(item.productList);
			} else if (item?.productList?.filter(pItem => pItem.checked)?.length) {
				_totalPrice += (computeTotalPrice(item?.productList?.filter(pItem => pItem.checked)));
			}
		});
		setList(list);
		setTotalPrice(_totalPrice.toFixed(2));
		setCheckAll(list.length == list.filter(item => item.merchantInfo.checked)?.length)
	}
	/**
	 * 数量的变化
	 */
	const changeCount = async (num, pItem) => {
		const {productId, itemId, merchantId, id } = pItem;
		console.log(111, pItem, {
			productId,
			itemId,
			count: num,
			merchantId,
			id
		})
		const res = await addToCart({
			productId,
			itemId,
			count: num,
			merchantId,
			id
		});
		getList();
	}
		/**
		 * 删除购物车商品
		 */
	const	deleteProduct = (id) => {
		// wx.showLoading({
		// 	title: '加载中...',
		// 	mask: true,
		// });
		deleteCartItem({
			id
		}).then(res => {
			if (res.data) {
				getList();
			}
		})
	}
		/**
		 * 跳转到下单页面
		 */
	const	toBookingPage = e => {
		let _list = [];
		list.forEach(item => {
			if (item.productList.filter(pItem => pItem.checked)?.length) {
				_list.push({
					merchantInfo: item.merchantInfo,
					productList: item.productList.filter(pItem => pItem.checked)
				})
			}
		})
		// const _list = list.filter(item => item.productList.filter(pItem => pItem.checked)?.length);
		if (!_list.length) {
			_alert('请先选择需要结算的商品')
			return ;
		}
		setBookingDataList(_list);
		history.push('/booking');
		// window.location.href="/booking"
	}
		/**
		 * 全选购物车内容
		 */
	const	toggleCheckAllItem = e => {
		let _list = [];
		_list = list.map(item => {
			item.merchantInfo.checked = !checkAll;
			item.productList.forEach(pItem => {
				pItem.checked = !checkAll;
			});
			return { ...item };
		});
		setListData(_list);
	}
		/**
		 * 跳转订单详情
		 */
	const	toProductDetail = id => {
		// wx.navigateTo({
		// 	url: '/pages/productDetail/productDetail?id=' + id,
		// })
	}

	useEffect(() => {
		getList();
	}, [])

	return (
		<Page {...{
			...props,
			showFooter: true,
			page: 'cart', title: '购物车'
		}}>
			<section className="cart-container" style={{ minHeight: window.innerHeight, overflow: 'hidden' }}>
				{
					showEmpty ? <EmptyCartConetent title="购物车还是空的" desc="快去挑几个喜欢的宝贝吧，不然都抢完了"></EmptyCartConetent> : null
				}
				{
					!showEmpty && list.length && <>
						{/*{
							data?.title ? <div className="shop-cart-title flex flex-v-c">
								<div className="iconfont shop-img icon-mall"></div>
								<span>{data.title}</span>
							</div> : null
						}*/}

						<div className="shop-product-container">
							{
								list.map((item, index) => <div className="product-item">
									{
										item && item.merchantInfo && item.merchantInfo.name ? <div className="shop-product-item-title">
										<div className="flex flex-v-c" onClick={() => toggleMerchantCheck(item.merchantInfo.id, index)}>
											{
												item.merchantInfo.checked ? <div className="iconfont check-icon icon-selected"></div> : <span className={`check-icon ${item.merchantInfo.disabled ? 'disabled' : ''}`}></span>
											}


										</div>
										<text className="shop-product-item-title-text">{item.merchantInfo.name}</text>
									</div> : null
									}

									{
										item.productList.map((pItem, pIndex) => <div className="scroll-item"
											enhanced={true}
											bounces={false}
											data-id={pItem.id}
										>
										<div className="product-item-content">
											<div className="flex">
												<div className="flex flex-v-c" onClick={() => toggleCheck(pItem, item, index, pIndex)} data-index="{{pIndex}}" data-merchantindex="{{index}}" data-merchantid="{{item.merchantInfo.id}}" data-id="{{pItem.id}}">
													{
														!pItem.checked ? <text className={`check-icon ${pItem.disabled ? 'disabled' : ''}`}></text> : <div className="iconfont check-icon icon-selected"></div>
													}
												</div>
												<img src={`${Const.MEDIA_HOST}${pItem.productInfo.images[0]}`} onClick={() => toProductDetail(pItem.productId)} className="product-item-img" />
												<div className="flex1 product-item-wrapper flex">
													<div className="flex1" onClick={() => toProductDetail(pItem.productId)}>
														<div className="title overflow-2-ellipsis">{pItem.productInfo.name}</div>
														<div className="desc">{pItem?.itemInfo?.itemValues}</div>
													</div>
													<div className="price-wrapper">
														<div className="price-num">¥{pItem.productInfo.discountInfo?.discountStock ? pItem.productInfo.discountInfo.productPrice : (pItem.itemInfo?.price) || (pItem.productInfo.price)}</div>
														<AddCount
															change={(n) => changeCount(n, pItem)}
															max={pItem.productInfo.discountInfo?.discountStock || pItem.itemInfo?.stock || pItem.productInfo.stock}
															value={pItem.count}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="delete-wrapper" onClick={() => deleteProduct(pItem.id)}>删除</div>
									</div>)
									}
								</div>)
							}

						</div>
						<div className="total-container">
							<div className="flex1 flex flex-v-c">
								<div className="checkbox-flag flex flex-v-c" onClick={toggleCheckAllItem}>
									{
										!checkAll ? <text className="check-icon"></text> : <div className="iconfont check-icon icon-selected"></div>
									}
									全选</div>
								<div><text className="total-price-label">合计:</text><text className="total-price">¥{totalPrice}</text></div>
								<div className="desc">不含运费</div>
							</div>
							<div className="settlement-btn" onClick={toBookingPage}>去结算</div>
						</div>
					</> || null
				}
			</section>
		</Page>
	);
};

export default CartPage;
