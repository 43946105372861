/**
 * Created by jimmy on 2019/9/29.
 */

import React, {useState, useEffect, useRef} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import moment from 'moment';
import { QRCodeCanvas } from 'qrcode.react';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Page from '../../components/page';
import List from '../../components/list';
import './index.less';
import * as CONST from "../../common/const";
import EmptyListContent from "../../components/emptyListContent";
import Button from "../../components/button";
import Block from "../../components/block";
import {
	HOST, ICON_BASE_URL,
	ORDER_REFUND_STATUS_COMPLETED,
	ORDER_REFUND_STATUS_PENDING,
	ORDER_STATUS_REFUND_COMPLETE, WRITE_OFF_CODE_STATUS_TO_USE
} from "../../common/const";
import { cancelRefundOrder } from '../../common/requestList';

const OrderTicketPage = props => {
	const history = useHistory();
	const { orderId } = useParams();

	const [ data, setData ] = useState(null);
	const [ btnList, setBtnList ] = useState(null);
	const getData = async () => {
		if(!orderId) return;
		const result = await Request.get({
			url: '/order/get',
			data: { id: orderId }
		});
		if(!result) return;
		console.log('result.data', result.data);
		setData(result.data);
	};
	useEffect(() => {
		console.log(orderId);
		getData();
	}, [ 123 ]);

	const qrCodeRef = useRef(null);
	const [ qrCodeSize, setQrCodeSize ] = useState(0);
	useEffect(() => {
		if(qrCodeRef?.current) {
			setQrCodeSize(qrCodeRef.current.offsetWidth);
		}
	}, [ data ]);

	const [ descriptions, setDescriptions ] = useState([]);
	const getDescriptions = async () => {
		const productInfo = data?.productList[0]?.productInfo || {};
		if(!productInfo.id) return;

		const result = await Request.get({
			url: '/product/descriptions/get',
			data: {
				productId: productInfo.id,
				type: CONST.PRODUCT_DESCRIPTION_TYPE_USAGE
			}
		});
		if(!result) return;

		setDescriptions(result.data?.descriptions || []);
	};
	useEffect(() => {
		getDescriptions();
		initBtnVisible();
	}, [ data ]);

	const productInfo = data?.productList[0]?.productInfo || {};

	/**
	 * 详情按钮控制
	 */
	// const { status, writeOffCodeInfo, invoiceId, refundStatus, paidPrice } = data || {};
	// 取消退款展示
	function initBtnVisible() {
		let _state = {}
    if (!data || data?.status === CONST.ORDER_STATUS_CLOSED) {
      return ;
    }
		const { status, productList, paidPrice, writeOffCodeInfo, invoiceId } = data;
		const refundStatus = productList?.[0]?.refundStatus || 0;
    // 取消退款展示
    _state.cancelRefundBtnVisible = paidPrice > 0 && status == CONST.ORDER_STATUS_TO_USE && refundStatus == CONST.ORDER_REFUND_STATUS_APPLY_REVIEW ? true : false;
    // 申请退款按钮展示
		// console.log('申请退款', refundStatus, data, CONST.ORDER_REFUND_STATUS_APPLY_REFUSED);
    _state.applyRefundBtnVisible = paidPrice > 0 && writeOffCodeInfo?.status != 2 && (!refundStatus || refundStatus == CONST.ORDER_REFUND_STATUS_APPLY_REFUSED) ? true : false;
    // 查看退款进度
    _state.refundProgressBtnVisible = paidPrice > 0 && [ CONST.ORDER_REFUND_STATUS_APPLY_REVIEW, CONST.ORDER_REFUND_STATUS_PENDING, CONST.ORDER_REFUND_STATUS_COMPLETED ].indexOf(refundStatus) > -1 ? true : false;
    // 申请发票
    _state.applyInvoiceBtnVisible = paidPrice > 0 && writeOffCodeInfo?.status == CONST.WRITE_OFF_CODE_STATUS_USED && !invoiceId ? true : false;
    // 查看发票
    _state.checkInvoiceBtnVisible = !!invoiceId;
    // 立即评价
    _state.commentBtnVisible = !!(data.status == CONST.ORDER_STATUS_TO_COMMENT);
    // 所有的按钮是否展示
    _state.showBottomBtnBlock = (_state.cancelRefundBtnVisible || _state.applyRefundBtnVisible || _state.refundProgressBtnVisible || _state.applyInvoiceBtnVisible || _state.checkInvoiceBtnVisible  || _state.commentBtnVisible);
		console.log('按钮', _state);
		setBtnList(_state);

	}

	function applyRefund() {
		const _productInfo = data?.productList?.[0];
		history.push(`/order/refund/${data.id}/${_productInfo.productId}/${_productInfo.id}`)
	}
	async function cancelRefund() {
		const _productInfo = data?.productList?.[0];
		const res = await cancelRefundOrder({
			id: data.id,
			orderProductId: _productInfo.id
		});
		if (res?.ok == 0) {
			Utils._alert('取消成功');
			getData();
		}

	}
	function applyInvoice() {
		// const _productInfo = data?.productList?.[0];
		history.push(`/invoice/add/${data.id}`)
	}
	function checkInvoice() {
		// const _productInfo = data?.productList?.[0];
		history.push(`/invoice/detail/${data.id}`)
	}
	function toRefundDetail() {
		const _productInfo = data?.productList?.[0];
		history.push(`/order/refund-detail/${data.id}/${_productInfo.id}`)
	}
	function commentCurrently() {
		const _productInfo = data?.productList?.[0];
		history.push(`/add-comment/${data.id}/${_productInfo.productId}/${_productInfo.id}`)
	}

	return (
		<Page {...{
			...props,
			page: 'order-ticket', title: '订单信息'
		}}>
			<section className="order-ticket-container">
				<Block className="order-product-info-wrap">
					<div className="order-product-title">{productInfo.name}</div>
					<div className="order-info-item-wrap">
						{
							[
								{ label: '使用日期', value: productInfo.ticketDateInfo?.date && moment(productInfo.ticketDateInfo.date).format('YYYY-MM-DD'), highlight: true, extra: <span className="date-tip">当日有效</span> },
								...descriptions
							].map((item, idx) => {
								return (
									<div className="order-info-item" key={idx}>
										<label>{item.label}：</label><span className={`${item.highlight ? 'highlight' : ''}`}>{item.value}</span>
										{ item.extra || null }
									</div>
								)
							})
						}
					</div>
					<div className="order-info-item-wrap">
						{
							[
								{ label: '订单状态', value: CONST.CONST_LABEL[data?.status], highlight: true },
								{ label: '订单编号', value: data?.serialNumber },
								{ label: '下单时间', value: data?.createdAt && moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss') },
								{ label: '订单金额', value: data?.paidPrice },
							].map((item, idx) => {
								return (
									<div className="order-info-item" key={idx}>
										<label>{item.label}：</label><span className={`${item.highlight ? 'highlight' : ''}`}>{item.value}</span>
									</div>
								)
							})
						}
					</div>
				</Block>

				<Block className="order-ticket-info-wrap">
					<div className="order-ticket-info-count">——{CONST.CONST_LABEL[data?.status]}{data?.productList?.length}人——</div>
					<div className="order-ticket-info-tip">凭「入园码」或身份证扫码入园</div>
					<div className="order-ticket-info-travellers">
						{
							data?.travellers?.length > 0 && data.travellers.map((item, idx) => {
								return (
									<div className="order-ticket-info-traveller-item" key={idx}>
										<div className="left"><i>必带</i><em>证<br />件</em></div>
										<div className="right">
											<span>姓名：{item.name}</span>
											<span>身份证：{item.idcard}</span>
										</div>
									</div>
								)
							})
						}
					</div>
					{
						data?.status != 8 && data?.writeOffCodeInfo ? <div className="order-ticket-info-qrode">
							<div className="left">入园凭证</div>
							<div className="right">
								<span>券码：{data?.writeOffCodeInfo?.serialNumber}</span>
								<div className="qrcode" ref={qrCodeRef}>
									<QRCodeCanvas size={qrCodeSize} value={`${HOST}/averify/detail/${data?.id}`} />
									{
										data?.writeOffCodeInfo?.status != CONST.WRITE_OFF_CODE_STATUS_TO_USE && <div className="invalid-cover" style={{ backgroundImage: `url(${({
												[CONST.WRITE_OFF_CODE_STATUS_USED]: CONST.ICON_BASE_URL + 'code-used.png',
												[CONST.WRITE_OFF_CODE_STATUS_INVALID]: CONST.ICON_BASE_URL + 'code-invalid.png',
												[CONST.WRITE_OFF_CODE_STATUS_EXPIRED]: CONST.ICON_BASE_URL + 'code-expired.png'
											})[data?.writeOffCodeInfo?.status]})` }}
										/>
									}
								</div>
							</div>
						</div> : null
					}
					
					{ data?.status != CONST.ORDER_STATUS_CLOSED && <div className="order-ticket-info-tip2">该产品购买后只会收到一个凭证码，该订单内的全部游客均可使用该凭证码入园。</div> }
				</Block>

				<Block className="rule-tip-wrap">
					<div className="rule-tip-title">退改规则</div>
					<div className="rule-tip-content">
						<label>未使用随时退</label>
						<span>未消费可随时申请退款，3个工作日内完成退款审核。</span>
					</div>
					<div className="rule-tip-content">
						<label>过期也可退</label>
						<span>过期后未消费的订单也可申请退款。</span>
						<span>一笔订单多张票不支持部分退款。</span>
						<span>下单后不支持改期。</span>
					</div>
				</Block>

				<Block className="invoice-tip-wrap">
					<div className="invoice-tip-title">发票开具规则</div>
					<div className="invoice-tip-content">
						{
							[
								{ text: '订单完成后，当年内均可申请开具普通发票（纸质），如资质审核通过，将在订单完成后 3 个工作日内为您寄出，邮寄信息详见收票人信息；' },
								{ text: '厂商直送（厂家或供应商发货及配送）订单需先确认商品收货，待订单完成后为您开具发票；' },
								{ text: '发票开具金额为您实际支付金额；' },
								{ text: '第三方订单的商品发票由第三方商家自行开具，您可点击申请开票并查看开票进度，如有疑问请联系商家。' },
								{ text: '订单完成前可修改发票信息，请您务必确认修改内容；发票开具成功后，1年内可支持一次发票换开；' },
								{ text: '更换发票，需将原票寄回本公司，邮寄地址请见收票人信息页。' },
							].map((item, idx) => {
								return (
									<div className="item"><em>{idx + 1}）</em><span>{item.text}</span></div>
								)
							})
						}
					</div>
				</Block>

				{
					btnList?.showBottomBtnBlock && <div className="order-ticket-detail-btns">
						<div className="order-ticket-detail-btns-block">
							{
								btnList.applyRefundBtnVisible && <Button {...{
									text: '申请退款',
									key: 'apply-refund',
									className: 'order-ticket-detail-btn-item default-btn-item',
									onClick: applyRefund
								}} />
							}
							{
								btnList.cancelRefundBtnVisible && <Button {...{
									text: '取消退款',
									key: 'cancel-refund',
									className: 'order-ticket-detail-btn-item default-btn-item',
									onClick: cancelRefund
								}} />
							}
							{
								btnList.refundProgressBtnVisible && <Button {...{
									// text: '退款中/已退款',
									text: data?.status== CONST.ORDER_STATUS_REFUND_COMPLETE || data?.productList?.[0]?.refundStatus == CONST.ORDER_REFUND_STATUS_COMPLETED ? '已退款' : '退款中',
									key: 'refunding',
									className: `order-ticket-detail-btn-item ${(btnList.cancelRefundBtnVisible || btnList.applyRefundBtnVisible) ? '' : 'default-btn-item'}`,
									// disabled: true,
									onClick: toRefundDetail
								}} />
							}
							{
								btnList.applyInvoiceBtnVisible && <Button {...{
									text: '开具发票',
									key: 'apply-invoice',
									className: 'order-ticket-detail-btn-item',
									disabled: false,
									onClick: applyInvoice
								}} />
							}
							{
								btnList.checkInvoiceBtnVisible && <Button {...{
									text: '查看发票',
									key: 'check-invoice',
									className: 'order-ticket-detail-btn-item',
									// disabled: true,
									onClick: checkInvoice
								}} />
							}
						</div>
						{
							btnList.commentBtnVisible && <Button {...{
								text: '立即评价',
								key: 'comment',
								className: 'order-ticket-detail-btn-item',
								onClick: commentCurrently
							}} />
						}
					</div>
				}
			</section>
		</Page>
	);
};

export default OrderTicketPage;
