/**
 * Created by jimmy on 2022/9/25.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as CONST from '../../../common/const';
import { cancelRefundOrder } from '../../../common/requestList';
import { _alert } from '../../../common/utils';
import './index.less';
// 展示已退款和退款中的状态的orderList
const RefundProgressList = [CONST.ORDER_REFUND_STATUS_APPLY_REVIEW, CONST.ORDER_REFUND_STATUS_PENDING, CONST.ORDER_REFUND_STATUS_COMPLETED ]

const CompOrderItemModel = props => {
	const history = useHistory();
	const { style = {}, data, detailData } = props;
	const cancelRefund = async () => {
    const productInfo = data;
    const { id } = detailData;
    const res = await cancelRefundOrder({
      id,
      orderProductId: productInfo.id
    });
		if (res?.ok == 0) {
			_alert('取消成功', () => {
				window.location.reload();
			});
		}
	}

	const toRefundDetail = () => {

	}
	const refund = () => {
		history.push(`/order/refund/${detailData.id}/${data.productId}/${data.id}`)
	}

	const toComment = (e) => {
		e.preventDefault();
		e.stopPropagation();
		console.log('去评价', data, detailData);
		history.push(`/add-comment/${detailData.id}/${data.productId}/${data.id}`)
	}

	if(!data) return null;
	return (<>
		<section className="comp-order-item-wrapper" style={style}>
			<div className="cover" style={{ backgroundImage: `url(${CONST.MEDIA_HOST + data?.productInfo?.images[0]})` }} />
			<div className="message">
				<span className="title">{data?.productInfo?.name}</span>
				<span className="desc">{data?.productInfo?.itemInfo?.itemValues}</span>
			</div>
			<div className="price-count">
				<span className="price">¥{data.price}</span>
				<span className="count">x{data.count}</span>
			</div>
		</section>
		{
			// 取消退款
			data?.refundStatus == 1 ? <div className="product-hander-wrapper">
				<div className="product-after-sales-btn" onClick={cancelRefund}>取消退款</div>
			</div> : (
				// 正在退款的订单查看状态
				RefundProgressList.indexOf(data?.refundStatus) > -1 ? <div className="product-hander-wrapper">
				<div className="product-after-sales-btn" onClick={toRefundDetail}>{
					data.refundStatus == CONST.ORDER_REFUND_STATUS_COMPLETED || data.status == CONST.ORDER_STATUS_REFUND_COMPLETE ? '已退款' : '退款中'
				}</div>
			</div> : (
				// 可以申请退款
				(data.paidPrice > 0 && (data.refundPrice || 0) <= 0 && !detailData?.originalPaidPrice) && (data.status == CONST.ORDER_STATUS_TO_SHIP || data.status == CONST.ORDER_STATUS_TO_RECEIVE) ? <div className="product-hander-wrapper">
				<div className="product-after-sales-btn" onClick={refund}>申请退款</div>
			</div> : (
				// 去评价
				data?.status == CONST.ORDER_STATUS_TO_COMMENT ? <div className="product-hander-wrapper">
					<div className="product-after-sales-btn" onClick={toComment}>去评价</div>
				</div> : null
				)
			)
			)
		}
	</>
	);
};

export default CompOrderItemModel;
