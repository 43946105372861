/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import EventBus from "../../common/eventBus";
import * as Const from '../../common/const';
import Page from '../../components/page';
import './index.less';
import * as CONST from "../../common/const";
import KingAreas from "../../components/kingAreas";
import Block from "../../components/block";
import { getOrderCount } from '../../common/requestList';


const PageModel = props => {
	const history = useHistory();
	const [ userInfo, setUserInfo ] = useState(null);
	const [ badgeList, setBadgeList ] = useState({});
	useEffect(() => {
		const userInfo = Utils.user.getInfo();
		setUserInfo(userInfo);

		EventBus.addListener('__login_userinfo_', (userInfo) => {
			setUserInfo(userInfo);
		})
	}, [ 123 ]);
	const getBadgeList = async() => {
		if(!userInfo || !userInfo.id) return;

		const res1 = await getOrderCount({ status: 1});
		const res3 = await getOrderCount({ status: 3});
		const res4 = await getOrderCount({ status: 4});
		const res5 = await getOrderCount({ status: 5});
		const _badgeList = {
			1: res1 && res1.data && res1.data.count || 0,
			3: res3 && res3.data && res3.data.count || 0,
			4: res4 && res4.data && res4.data.count || 0,
			5: res5 && res5.data && res5.data.count || 0,
		};
		setBadgeList(_badgeList);
	}
	useEffect(() => {
		getBadgeList();
	}, [ userInfo ]);

	return (
		<Page {...{
			...props,
			showFooter: true,
			page: 'user', title: '我的档案',
			showBg: true
		}}>
			<section className="user-container" style={{ minHeight: window.innerHeight }}>

				<div className="user-info-container flex user-block">
					{ userInfo && userInfo.avatar && <img src={userInfo.avatar} className="avator-img"/> || <div className="none-avator-img flex flex-c">{userInfo && userInfo.nickname && userInfo && userInfo.nickname[0]}</div> }
					<p className='flex1'>
						<span className="nickname">{userInfo && userInfo.nickname}</span><br />
						{
							userInfo && userInfo.mobile ? <span className='mobile'>{userInfo && userInfo.mobile}</span> : null
						}

					</p>
					<div className="edit-userinfo" onClick={() => {
						history.push('/user-edit')
					}}><i className="iconfont icon-edit" /></div>
					{/*{
						userInfo && userInfo.nickname && userInfo.avatar && <>
							<img src={userInfo.avatar} className="avator-img"/>
							<span className="nickname">{userInfo.nickname}</span>
						</> || <>
							<div className="none-avator-img flex flex-c" />
							<span className="nickname">点击授权微信头像和昵称</span>
						</>
					}*/}
				</div>

				<Block {...{
					style: { marginBottom: 10 },
					className: "user-block"
				}}>
					<KingAreas {...{
						numberInLine: 5,
						gap: 0,
						list: [
							{ id: 1, type: 0, label: '我的订单', icon: 'icon-order', path: `order/list`, badge: 0 },
							{ id: 2, type: 1, label: '待付款', icon: 'icon-topay', path: `order/list?type=${CONST.ORDER_STATUS_TO_PENDING}`, badge: badgeList[1]|| 0},
							{ id: 3, type: 3, label: '待发货', icon: 'icon-send', path: `order/list?type=${CONST.ORDER_STATUS_TO_SHIP}`, badge: badgeList[3] || 0 },
							{ id: 4, type: 4, label: '待收货', icon: 'icon-toreceive', path: `order/list?type=${CONST.ORDER_STATUS_TO_RECEIVE}`, badge: badgeList[4] || 0 },
							{ id: 5, type: 5, label: '待评价', icon: 'icon-comment', path: `order/list?type=${CONST.ORDER_STATUS_TO_COMMENT}`, badge: badgeList[5] || 0 }
						],
						clickHandle: (item) => {
							if(item.path) {
								history.push(item.path);
							}
						}
					}} />

				</Block>

				<Block className="user-block sub-entry-block">
					{/* <KingAreas {...{
						list: [
							{
								id: 1, bgColor: '#FFD924', label: '我的消息',
								icon: 'icon-message',
								path: 'message'
							},
							{
								id: 2, bgColor: '#FFAB19', label: '我的收藏',
								icon: 'icon-collect',
								path: 'product/collect/list'
							},
							{
								id: 3, bgColor: '#DB97FA', label: '地址管理',
								icon: 'icon-address-list',
								path: 'address'
							},
							{
								id: 4, bgColor: '#908BFB', label: '联系客服',
								icon: 'icon-customer-service',
								href: CONST.customerServiceLink
							}
						],
						clickHandle: (item) => {
							if(item.path) {
								history.push(item.path);
							}else if(item.href) {
								window.location.href = item.href;
							}
						}
					}} /> */}
					<ul className='sub-entry-list'>
						{
							[
								{
									id: 1, bgColor: '#FFD924', label: '我的消息',
									icon: 'icon-message',
									path: 'message'
								},
								{
									id: 2, bgColor: '#FFAB19', label: '我的收藏',
									icon: 'icon-collect',
									path: 'product/collect/list'
								},
								{
									id: 3, bgColor: '#DB97FA', label: '地址管理',
									icon: 'icon-address-list',
									path: 'address'
								},
								{
									id: 4, bgColor: '#908BFB', label: '联系客服',
									icon: 'icon-customer-service',
									href: CONST.customerServiceLink
								}
							].map((item, index) => <li key={item.label} className="entry-item" onClick={() => {
								((item) => {
									if(item.path) {
										history.push(item.path);
									}else if(item.href) {
										window.location.href = item.href;
									}
								})(item)
							}}>
								<span className={`iconfont pre-icon ${item.icon}`} style={{color: item.bgColor}}></span><span className='entry-item-label'>{item.label}</span><span className='iconfont icon-arrow-right'></span>
							</li>)
						}
					</ul>
				</Block>
			</section>
		</Page>
	);
};

export default PageModel;
