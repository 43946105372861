import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import * as Utils from './common/utils';
import './index.less';
import * as serviceWorker from './serviceWorker';

import PageHome from './pages/home';
import PageCategory from './pages/category';
import PageCart from './pages/cart';
import PageUser from './pages/user';
import PageUserEdit from './pages/user/edit';
import PageNotFound from './pages/404';
import PageProduct from './pages/product';
import PageAddress from './pages/address';
import PageAddressEdit from './pages/address/edit';
import PageProductCollectList from './pages/productCollectList';
import PageProductDiscountList from './pages/productDiscountList';
import PageMessage from './pages/message';
import PageMessageList from './pages/message/list';
import PageBrand from './pages/brand';
import PageBrandList from './pages/brand/list';
import PageSearch from './pages/search';
import PageOrderList from './pages/order/list';
import InvoiceAdd from './pages/invoice/add/index';
import InvoiceDetail from './pages/invoice/detail/index';
import PageOrderDetail from './pages/order/detail';
import PageOrderTicketDetail from './pages/order/ticketDetail';
import PageBooking from './pages/booking/index';
import PageRefund from './pages/refund/index';
import PageRefundDetail from './pages/refund/detail/index';
import TicketBooking from './pages/ticketBooking/index';
import AddComment from './pages/addComment/index';
import PageLogin from './pages/login/index';
import PageShippingInfo from './pages/shippingInfo';
import PageEnterpriceQualification from './pages/enterpriceQualification/index';
import middleware from './common/middleware';
import {getUA, user, wx} from "./common/utils";

const App = () => (
	<Router>
		<Switch>
			<Route exact path="/"><PageHome /></Route>
			<Route exact path="/home"><PageHome /></Route>
			<Route exact path="/category"><PageCategory /></Route>
			<Route exact path="/cart"><PageCart /></Route>
			<Route exact path="/user"><PageUser /></Route>
			<Route exact path="/user-edit"><PageUserEdit /></Route>
			<Route exact path="/address"><PageAddress /></Route>
			<Route exact path="/address-edit"><PageAddressEdit /></Route>
			<Route exact path="/product/:id"><PageProduct /></Route>
			<Route exact path="/product/collect/list"><PageProductCollectList /></Route>
			<Route exact path="/product/discount/list"><PageProductDiscountList /></Route>
			<Route exact path="/message"><PageMessage /></Route>
			<Route exact path="/message/list"><PageMessageList /></Route>
			<Route exact path="/brand/list"><PageBrandList /></Route>
			<Route exact path="/brand/:id"><PageBrand /></Route>
			<Route exact path="/search"><PageSearch /></Route>
			<Route exact path="/booking"><PageBooking /></Route>
			<Route exact path="/order/list"><PageOrderList /></Route>
			<Route exact path="/invoice/add/:id"><InvoiceAdd /></Route>
			<Route exact path="/invoice/detail/:orderId"><InvoiceDetail /></Route>
			<Route exact path="/order/:id"><PageOrderDetail /></Route>
			<Route exact path="/order/ticket/:orderId"><PageOrderTicketDetail /></Route>
			<Route exact path="/order/refund/:id/:productId/:productOrderId"><PageRefund /></Route>
			<Route exact path="/order/refund-detail/:id/:productOrderId"><PageRefundDetail /></Route>
			<Route exact path="/ticket-booking"><TicketBooking /></Route>
			<Route exact path="/add-comment/:id/:productId/:productOrderId"><AddComment /></Route>
			<Route exact path="/shipping-info/:id"><PageShippingInfo /></Route>
			<Route exact path="/login"><PageLogin /></Route>
			<Route exact path="/enterprice/:id"><PageEnterpriceQualification /></Route>
			{/* PageRefund */}
			<Route path="*"><PageNotFound /></Route>
		</Switch>
	</Router>
);

middleware();
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Utils.checkXINGFUYUNAccessUser(() => {
// 	Utils.wx.sign();
// });
// Utils.user.wxLogin({}, () => {
// 	Utils.wx.sign();
// });
const ua = getUA();
console.log('ua===',ua);
if(ua.isInWechat) {
	const xfy_mobile = window.sessionStorage.getItem('xfy_mobile');
	if(xfy_mobile){
		user.wxLogin({}, () => {
			wx.sign();
		});
	}
}
