/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { pca, pcaa } from 'area-data'; // v3 or higher
import 'react-area-linkage/dist/index.css'; // v2 or higher
import { AreaSelect, AreaCascader } from 'react-area-linkage';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Page from '../../components/page';
import List from '../../components/list';
import './index.less';
import * as CONST from "../../common/const";
import EmptyListContent from "../../components/emptyListContent";
import Button from "../../components/button";
import Modal from "../../components/modal";
import Switch from "../../components/switch";
import City from "../../components/city";
import EventBus from "../../common/eventBus";

const PageModel = props => {
	const history = useHistory();

	const [ params, setParams ] = useState({ nickname: '' });
	const [ userInfo, setUserInfo ] = useState(null);
	useEffect(() => {
		const userInfo = Utils.user.getInfo();
		setUserInfo(userInfo);
		if(userInfo){
			params.nickname=userInfo.nickname;
			params.mobile = userInfo.mobile ? userInfo.mobile : '';
		}

		EventBus.addListener('__login_userinfo_', (userInfo) => {
			setUserInfo(userInfo);
		})
	}, [ 123 ]);

	const submit = async () => {
		// if(!params.nickname) return Utils._alert('请填写有效的姓名');
		// let verify = Utils.mobileVerify(params.mobile);
		// console.log('mobileVerify',verify);
		if(!Utils.mobileVerify(params.mobile)) return Utils._alert('请填写有效手机号');
		userInfo.mobile = params.mobile;
		const result = await Request.post({
			url: '/user/set',
			data: userInfo
		});
		if(!result) return;

		Utils._alert('保存成功', () => {
			// history.go(-1);
		});
	};

	return (
		<Page {...{
			...props,
			page: 'userinfo-edit', title: '个人设置'
		}}>
			<section className="address-edit-container">
				<div className="address-form-item">
					<label>姓名</label>
					<input placeholder="姓名" readOnly defaultValue={params?.nickname} onChange={e => {
						setParams({ ...params, nickname: e.target.value });
					}} />
				</div>
				<div className="address-form-item">
					<label>手机号</label>
					<input placeholder="联系电话" maxLength={11} defaultValue={params.mobile} onChange={e => {
						setParams({ ...params, mobile: e.target.value });
					}} />
				</div>

			</section>

			<Button {...{
				className: 'address-edit-button',
				text: '保存',
				onClick: () => {
					submit();
				}
			}} />
		</Page>
	);
};

export default PageModel;
