/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Page from '../../components/page';
import List from '../../components/list';
import './index.less';
import * as CONST from "../../common/const";
import EmptyListContent from "../../components/emptyListContent";
import Button from "../../components/button";
import Modal from "../../components/modal";
import {setChoosedAddress} from "../../common/cache";

const PageModel = props => {
	const history = useHistory();

	const [ list, setList ] = useState([]);
	const getList = async () => {
		const result = await Request.get({
			url: '/address/list',
			data: {
				// page: pageIndex,
				// pageSize
			}
		});
		if(!result) return;

		setList(result.data.list);
	};
	useEffect(() => {
		getList();
	}, [ 123 ]);

	const deleteHandle = async (id) => {
		if(!id) return;
		const result = await Request.post({
			url: '/address/delete',
			data: { id }
		});
		if(!result) return;

		Utils._alert('操作成功');
		getList();
	};

	const defaultHandle = async (item) => {
		if(!item?.id) return;
		const result = await Request.post({
			url: '/address/update',
			data: {
				...item,
				isDefault: ({ [CONST.ADDRESS_DEFAULT_IS]: CONST.ADDRESS_DEFAULT_NOT, [CONST.ADDRESS_DEFAULT_NOT]: CONST.ADDRESS_DEFAULT_IS })[item.isDefault]
			}
		});
		if(!result) return;

		Utils._alert('操作成功');
		getList();
	};

	return (
		<Page {...{
			...props,
			page: 'address', title: '地址管理'
		}}>
			<section className="address-list-container">
				{
					list?.length > 0 && list.map((item, idx) => {
						return (
							<div className="address-item-wrapper" key={idx} onClick={() => {
								setChoosedAddress(item);
								window.history.back();
							}}>
								<div className="address-item"><label>收货人</label><span>{item.name}</span></div>
								<div className="address-item"><label>联系电话</label><span>{item.mobile}</span></div>
								<div className="address-item"><label>配送地址</label><span>{[ item.province, item.city, item.distinct, item.address ].join(' ')}</span></div>
								<div className="address-ctrl">
									<div className="ctrl-default" onClick={() => defaultHandle(item)}><em className={`iconfont${item.isDefault == CONST.ADDRESS_DEFAULT_IS ? ' icon-selected' : ''}`} />设为默认</div>
									<div className="ctrl-item-wrap">
										<div className="ctrl-item" onClick={() => deleteHandle(item?.id)}><em className="iconfont icon-delete" />删除</div>
										<div className="ctrl-item" onClick={() => history.push(`/address-edit?id=${item.id}`)}><em className="iconfont icon-edit" />编辑</div>
									</div>
								</div>
							</div>
						)
					}) || <EmptyListContent text="暂无数据，点击下方添加常用地址" />
				}
			</section>

			<Button {...{
				className: 'address-add-button',
				text: '添加地址',
				onClick: () => history.push('/address-edit')
			}} />
		</Page>
	);
};

export default PageModel;
