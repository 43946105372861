/**
 * Created by jimmy on 2019/11/7.
 */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import * as Request from './request';
import * as CONST from './const';
import EventBus from './eventBus';

export const getSearchByKey = (key, search = window.location.search) => {
	if(!search) return '';

	const _search = search.slice(1);
	const _searchArr = _search.split('&');
	const _map = {};
	_searchArr.map(item => {
		const _item = item.split('=');
		if(_item[0] && _item[1]) {
			_map[_item[0]] = _item[1];
		}
	});
	return _map[key] ? decodeURIComponent(_map[key]) : '';
};

export const setTitle = (title) => {
	if(document && title) {
		document.title = title;

		const i = document.createElement('iframe');
		i.src = '';
		i.style.display = 'none';
		i.onload = function() {
			setTimeout(function(){
				i.remove();
			}, 9)
		}
		document.body.appendChild(i);
		setTimeout(() => {
			i.remove();
			// document.body.removeChild(document.querySelector('iframe'));
		}, 100);
	}
};

// loading
export const loading = {
	show: () => {
		document.getElementById('loading').style.display = 'block';
	},
	hide: () => {
		document.getElementById('loading').style.display = 'none';
	}
};

// toast
export const toast = {
	show: (txt) => {
		const $toast = document.getElementById('toast');
		$toast.style.display = 'block';
		$toast.innerText = txt;
	},
	hide: () => {
		const $toast = document.getElementById('toast');
		$toast.style.display = 'none';
		$toast.innerText = '';
	}
};

export const getUA = () => {
	if(!window || !window.navigator || !window.navigator.userAgent){
		return null;
	}

	const ua = window.navigator.userAgent.toLocaleLowerCase();

	return {
		ua,
		isInWechat: false,
		isInRongMeiApp: ua.indexOf('rongmeiapp/qingpu') > -1 || ua.indexOf('rongmeiapp') > -1,    // mainly for QingPu
    isIOS: ua.indexOf("iphone") > -1 || ua.indexOf("ios") > -1,
    isAndroid: ua.indexOf("android") > -1 || ua.indexOf("linux") > -1
	}
};

export const _alert = (txt, cb = () => {}) => {
	if(!txt) return;

	toast.show(txt);

	setTimeout(() => {
		toast.hide();
		cb();
	}, 2000);
};

export const arrayToMap = (arr = [], key = 'id', value = 'label') => {
	const map = {};
	arr.map(a => {
		map[a[key]] = a[value];
	});
	return map;
};

export const countDown = (endTime, callback, format = 'DD天HH时mm分ss秒') => {
	let timer = null;
	const _action = () => {
		const leftTime = moment.duration(moment(endTime).diff(moment()));
		if(leftTime < 0) {
			clearTimeout(timer);
			timer = null;
			callback({ ok: -1, msg: '已结束' });
		}else{
			let days = Math.floor(leftTime / 1000 / 60 / 60 / 24);
			let hours = Math.floor(leftTime / 1000 / 60 / 60 % 24);
			let minutes = Math.floor(leftTime / 1000 / 60 % 60);
			let seconds = Math.floor(leftTime / 1000 % 60);
			seconds = seconds < 10 ? "0" + seconds : seconds;
			minutes = minutes < 10 ? "0" + minutes : minutes;
			hours = hours < 10 ? "0" + hours : hours;

			const result = format.replace('DD', days).replace('HH', hours).replace('mm', minutes).replace('ss', seconds);
			callback({ ok: 0, data: result });
			timer = setTimeout(() => {
				_action();
			}, 1000);
		}
	};
	_action();
}

/**
 * 计算订单总价格
 * 如果当前商品是在活动中，那么则判断单个商品的每个规格的库存是否超出活动库存，如果超出，则超出的数量则按照商品原价购买
 */
export const computeTotalPrice = (list) => {
  let totalPrice = 0;
  let _list = list.sort((v1, v2) => (v2?.itemInfo?.price || v2.productInfo.price) - (v1?.itemInfo?.price || v1.productInfo.price));
  let discountMap = {}
  _list.forEach(item => {
    if (item.disabled) {
      return ;
    }
    // 如果活动有库存
    let _discountInfo = item.productInfo.discountInfo;
    let _price = 0;
    // 活动商品的价格计算方式
    if (_discountInfo) {
      // debugger;
      const _key = `product_${item.productInfo.id}`;
      const _discountStock = discountMap[_key] == undefined ? _discountInfo?.discountStock : discountMap[_key];
      discountMap[_key] = _discountStock;
      // 如果库存已经不足该活动商品的活动库存价格，则部分按照库存价格来计算，部分按照原价来计算
      if (_discountStock >= item.count) {
        totalPrice += Number(_discountInfo?.productPrice) * 100 * item.count / 100;
        discountMap[_key] = _discountStock - item.count;
      } else {
        // 按照活动价计算的商品
        const _discountCount = item.count - _discountStock;
        // totalPrice +=  Number(_discountInfo?.productPrice) * 100 * _discountStock / 100;
        // 按照原价计算的商品
        // totalPrice +=  Number(item.itemInfo?.price || item.productInfo.price) * 100 * _discountCount / 100;

        // 如果有库存，就使用活动价计算，其他逻辑在下单页面
        totalPrice += Number(_discountStock > 0 ? _discountInfo?.productPrice : (item.itemInfo?.price || item.productInfo.price)) * 100 * item.count / 100
        discountMap[_key] = 0;
      }
    } else {
      // 非活动商品的价格计算方式
      _price = Number(item.itemInfo?.price != undefined ? item.itemInfo?.price : item.productInfo.price)
      totalPrice += _price * 100 * item.count / 100;
    }
  });
  // TODO运费的计算
  return totalPrice;
}

/**
 * 登录功能
 * 微信内：微信登录
 * APP 内：暂时先 return
 */
const _user = {
	getInfo: () => {
		const userInfoStr = window.sessionStorage.getItem('_user');
		return userInfoStr && typeof userInfoStr == 'string' ? JSON.parse(userInfoStr) : userInfoStr;
	},
	setInfo: (info = {}) => {
		window.sessionStorage.setItem('_user', JSON.stringify(info));
	},
	isLogin: () => {
		const userInfo = _user.getInfo();
		return userInfo && userInfo.id;
	},
	_wxRedirectUriReset: (uri = window.location.href)=>{
		let hash, pureUri, pureUrl;
		hash = uri.split('#')[1];
		pureUri = uri.split('#')[0];
		pureUrl = pureUri.split('?')[0];

		let querys = (function (querys) {
			if(!querys) return {};
			let query = {};
			let _querysArr = querys.split('&');
			for(let i=0, len=_querysArr.length; i<len; i++){
				let qItem = _querysArr[i].split('=');
				query[qItem[0]] = qItem[1];
			}
			return query;
		})(pureUri.split('?')[1]);

		let queryArr = [];
		for(let i in querys){
			if(querys[i] && i != 'code' && i != 'state'){
				queryArr.push(i + '=' + querys[i]);
			}
		}
		if(queryArr.length > 0){
			pureUrl += '?' + queryArr.join('&')
		}
		if(!!hash) pureUrl += '#' + hash;

		return pureUrl;
	},
	wxLogin: async (option = {}, callback) => {
		// const appId = "wx6ef0bb6a5b9e3955";
		const appId = CONST.appId;
		const _userInfo = _user.getInfo();
		if(_userInfo && _userInfo.avatar) return;

		const code = getSearchByKey('code');
		const state = getSearchByKey('state');
		if(!code) {
			// const url = `https://open.weixin.qq.com/connect/oauth2/authorize?${[
			// 	`appid=${appId}`,
			// 	`redirect_uri=${encodeURIComponent(_user._wxRedirectUriReset(window.location.href))}`,
			// 	`response_type=code`,
			// 	`scope=snsapi_base`,
			// 	`state=STATE_BASE`
			// ].join('&')}#wechat_redirect`;
			// window.location.href = url;
			// return;
			if(window.location.pathname.indexOf('/login') == -1) {
				window.location.href = `${CONST.HOST}/login?redirectUrl=${window.location.href}`;
			}
			return;
		}
		if(window.location.pathname.indexOf('/login') > -1) return;

		const xxfymobile = window.sessionStorage.getItem('xfy_mobile');
		const result = await Request.post({
			hideError: true,
			url: '/user/login/h5',
			data: { code, xxfymobile, scope: ({ 'STATE_BASE': 'snsapi_base', 'STATE_USERINFO': 'snsapi_userinfo' })[state] }
		});
		console.log('result', result);
		if(!result) return;

		if(result && result.data) {
			const token = result.data.token;
			window.sessionStorage.setItem('token', token);
			delete result.data.token;
			const userInfo = result.data;
			window.sessionStorage.setItem('openid', userInfo.openid);
			_user.setInfo(userInfo);

			if(!userInfo.avatar) {
				const url = `https://open.weixin.qq.com/connect/oauth2/authorize?${[
					`appid=${appId}`,
					`redirect_uri=${encodeURIComponent(_user._wxRedirectUriReset(window.location.href))}`,
					`response_type=code`,
					`scope=snsapi_userinfo`,
					`state=STATE_USERINFO`
				].join('&')}#wechat_redirect`;
				window.location.href = url;
				return;
			}
		}

		setTimeout(() => {
			callback && callback();
		}, 666);
	}
};
export const user = _user;

export const getTargetByKey = (array = [], key, value) => {
	const [ target = {} ] = array.filter(item => item[key] == value);
	return target;
};

export const getFromMapByKey = (map, key) => {
	let target = null;
	if(map[key]) {
		target = map[key];
	}else{
		for(let i in map) {
			if(map[i] == key) {
				target = i;
			}
		}
	}
	return target;
};

export const mobileVerify = mobile => {
	return /^1[3456789]\d{9}$/.test(mobile);
};

export const localStorage = (key) => {
	return {
		get: () => {
			if(!key) return;

			let searchHistory = window.localStorage.getItem(key);
			if(searchHistory) {
				searchHistory = JSON.parse(searchHistory);
			}
			return searchHistory || null;
		},
		set: (target) => {
			if(!key) return;

			window.localStorage.setItem(key, JSON.stringify(target));
		},
		clear: () => {
			if(!key) return;
			window.localStorage.setItem(key, null);
		}
	}
}

export const wx = {
	sign: async () => {
		const result = await Request.get({
			url: '/user/wxsign',
			data: {
				url: encodeURIComponent(window.location.href.split('#')[0])
			}
		});
		console.log('wxsign result', result);
		if(!result) return;

		window.wx.config({
			// debug: true,
			...result.data,
			jsApiList: [
				'startRecord',
				'stopRecord',
				'onVoiceRecordEnd',
				'playVoice',
				'pauseVoice',
				'stopVoice',
				'onVoicePlayEnd',
				'uploadVoice',
				'updateAppMessageShareData',
				'updateTimelineShareData',
				'chooseImage',
				'uploadImage',
				'getLocalImgData',
				'previewImage',
				'chooseWXPay'
			] // 必填，需要使用的JS接口列表
		})
	},
	share: (config) => {
		if(!config) return;

		window.wx.ready(function () {
			window.wx.updateAppMessageShareData({
				title: config.title, // 分享标题
				desc: config.desc, // 分享描述
				link: config.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
				imgUrl: config.imgUrl, // 分享图标
				success: config.success || function () {
					// 设置成功
				}
			});
			window.wx.updateTimelineShareData({
				title: config.title, // 分享标题
				link: config.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
				imgUrl: config.imgUrl, // 分享图标
				success: config.success || function () {
					// 设置成功
				}
			});
		});
	},
	chooseWxImage: ({count = 1, sizeType = ['compressed'], sourceType = ['album', 'camera']}) => {
		return new Promise((resolve, reject) => {
			window.wx.ready(function () {
				window.wx.chooseImage({
					count,
					sizeType,
					sourceType,
					success(res) {
						if (!res || !res.localIds || !res.localIds.length) {
							console.log('选择图片', res);
							reject('获取图片失败');
						} else {
							resolve(res.localIds[0]);
						}
					},
					fail(err) {
						reject('获取图片失败', err);
					},
				});
			})
		})
	},
	uploadWxImage: (localId, isShowProgressTips = 1) => {
		return new Promise((resolve, reject) => {
			window.wx.uploadImage({
				localId,
				isShowProgressTips,
				success(res) {
					resolve(res.serverId);
				},
				fail(err) {
					reject('图片上传失败');
				},
			});
		});
	},
	getWxImageLocalData: (localId, serverId) => {
		return new Promise((resolve, reject) => {
			window.wx.getLocalImgData({
				localId,
				success (res) {
					resolve(res.localData);
				},
				fail (err) {
					reject('获取图片发生错误');
				},
			});
		});
	},
	pay(config, extra = {}) {
		const _ua = getUA();
		if(_ua.isInWechat) {
			window.WeixinJSBridge.invoke('getBrandWCPayRequest', config, res => {
				if (res.err_msg === 'get_brand_wcpay_request:ok') {
					if (extra?.success) {
						extra.success();
					}
					return ;
				} else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
					// 取消支付
					console.log('取消支付');

				} else if (res.err_msg === 'get_brand_wcpay_request:fail') {
					// 支付失败
					_alert('支付失败，请稍后重试~');
				} else {
					// 极端情况
					_alert('支付失败，请稍后重试~');
				}

				if (extra?.error) {
					extra.error();
				}
			});
		}else{
			if(config.h5_url) {
				window.location.href = `${config.h5_url}&redirect_url=${window.location.origin}/order/list`;
			}
		}
	}
}

// export function base64toBlob(dataurl, filename) {
// 	var arr = dataurl.split(','),
// 		mime = arr[0].match(/:(.*?);/)[1],
// 		bstr = window.atob(arr[1]),
// 		n = bstr.length,
// 		u8arr = new Uint8Array(n);
// 	while (n--) {
// 		u8arr[n] = bstr.charCodeAt(n);
// 	}
// 	return new File([u8arr], filename, { type: mime });
// }

// export function base64toBlob(base64,type) {
// 	// 将base64转为Unicode规则编码
// 	let bstr = window.atob(base64, type),
// 	n = bstr.length,
// 	u8arr = new Uint8Array(n);
// 	while (n--) {
// 			u8arr[n] = bstr.charCodeAt(n) // 转换编码后才可以使用charCodeAt 找到Unicode编码
// 	}
// 	return new Blob([u8arr], {
// 			type,
// 	})
// }

export function base64toBlob(base64,type) {
	// 将base64转为Unicode规则编码
 let  bstr = window.atob(base64, type),
	 n = bstr.length,
	u8arr = new Uint8Array(n);
	while (n--) {
			u8arr[n] = bstr.charCodeAt(n) // 转换编码后才可以使用charCodeAt 找到Unicode编码
	}
	return new Blob([u8arr], {
			type,
	})
}

// 判断是否在幸福云里面并且是否有登录信息
export const checkXINGFUYUNAccessUser = async (callback) => {
	// if(callback && typeof callback === 'function') {
	// 	callback();
	// }
	// return;
	// if(getUA().isInWechat) return;
	if(window.sessionStorage.getItem('xfytoken')) {
		callback && typeof callback === 'function' && callback();
		return;
	}

	const key = getSearchByKey('key');
	const token = getSearchByKey('token');
	const _LOCAL_STORAGE_KEY = 'xingfuyun_login';
	const _YOUHUIGOU_KEY = 'youhuigou';
	const LOGIN_HREF = 'https://qpxfy.shqp.gov.cn/qpxfy/#/welcome';

	const _loginAction = async ($token) => {
		// 登录token记录localStorage,做重复请求拦截
		const loginToken = window.localStorage.getItem("_loginAction_token");
		if(loginToken && loginToken === $token){
			console.log("当前请求正在处理中，拦截token:",loginToken);
			// 相同token已经请求过登录了，并且登录接口尚未返回，这里直接进行拦截，避免发生重复注册的问题
			return;
		}
		// 记录当前请求token
		window.localStorage.setItem("_loginAction_token",$token);
		const result = await Request.post({
			url: '/user/xingfuyun/login',
			data: {
				token: $token
			}
		});
		// 登录接口响应后，移除localStorage
		window.localStorage.removeItem("_loginAction_token");
		if(!result) return;
		console.log('_loginAction--', JSON.stringify(result));
		window.sessionStorage.setItem('xfy_mobile', result.data.serialNumber);
		window.sessionStorage.setItem('token', result.data.token);
		delete result.data.token;
		const userInfo = result.data;
		_user.setInfo(userInfo);
		EventBus.emit('__login_userinfo_', userInfo)

		if(getUA().isInWechat) {
			user.wxLogin();
		}
	}

	const _checkToken = async ($token) => {
		const result = await Request.post({
			url: '/user/xingfuyun/check',
			hideError: true,
			data: {
				token: $token
			}
		});
		// if(result.ok == -1) {
		// 	window.location.href = LOGIN_HREF;
		// 	return;
		// }

		window.sessionStorage.setItem(_LOCAL_STORAGE_KEY, $token);

		_loginAction($token);
	};

	if(key === _YOUHUIGOU_KEY && !!token) {
		_checkToken(token);
	}else{
		// window.location.href = LOGIN_HREF;
		// const localToken = window.sessionStorage.getItem(_LOCAL_STORAGE_KEY);
		// _checkToken(localToken);
	}
};
