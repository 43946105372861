/**
 * Created by jimmy on 2022/9/25.
 */

import React, { useState, useEffect } from 'react';
import { GridItem } from 'react-vant';
import * as CONST from '../../common/const';
import './index.less';

const CompBlockModel = props => {
	const { className, style = {}, title = '', headerExtra, refProps, titleImgIcon } = props;
	if(!props.children) return null;

	return (
		<section ref={refProps} className={`comp-block-wrapper ${className}`} style={style} onClick={props.onClick}>
			{
				title && <div className="comp-block-header">
					{ titleImgIcon || null }
					<span className="title">{title}</span>
					{headerExtra}
				</div>
			}
			<div className="comp-block-body">{props.children}</div>
		</section>
	);
};

export default CompBlockModel;
