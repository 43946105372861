/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Page from '../../components/page';
import List from '../../components/list';
import './index.less';
import * as CONST from "../../common/const";
import ProductItem from "../../components/product/productItem";
import EmptyListContent from "../../components/emptyListContent";

const PageModel = props => {
	const history = useHistory();
	const groupId = Utils.getSearchByKey('gid');
	const [ groupList, setGroupList ] = useState([]);
	const [ group, setGroup ] = useState(null);
	const [queryParam, setQueryParam] = useState('');
	const getGroupList = async () => {
		const result = await Request.get({
			url: '/product/group/list'
		});
		if(!result) return;

		const list = result.data.list;
		setGroupList(list);
		if(list?.length > 0) {
			if(!!groupId) {
				const [ target = {} ] = list.filter(lst => lst.id == groupId);
				setGroup(target);
			}else{
				setGroup(list[0]);
			}
		}
	};
	useEffect(() => {
		getGroupList();
	}, [ 123 ]);

	/* start of 无限列表的 */
	const [ isInit, setIsInit ] = useState(true);
	const [ list, setList ] = useState([]);
	const [ page, setPage ] = useState(0);
	const [ hasMore, setHasMore ] = useState(true);
	const reset = () => {
		setList([]);
		setPage(0);
		setHasMore(true);
		setIsInit(true);
	};
	const getList = async () => {
		if(!group) return;
		console.log('group product list',group);
		let searchParam = group.id+'|'+page;
		if(queryParam && queryParam === searchParam){
			console.log('page already load',searchParam);
			return;
		}
		setQueryParam(group.id+'|'+page);
		const result = await Request.get({
			url: '/product/list',
			data: {
				groupId: group.id,
				page,
				pageSize: CONST.PAGE_SIZE_LIMIT
			},
		});
		if(!result) return;

		setList([...list, ...result.data.list]);
		setIsInit(false);
		if(page < Math.ceil(result.data.count/CONST.PAGE_SIZE_LIMIT)) {
			console.log('page change',page + 1)
			setPage(page + 1);
		}else{
			setHasMore(false);
		}
	};
	useEffect(() => {
		console.log('group change getlist', group);
		getList();
	}, [ group ]);
	/* end of 无限列表的 */

	return (
		<Page {...{
			...props,
			showFooter: true,
			page: 'category', title: '分类页'
		}}>
			<section className="category-container">
				<div className="search-container" onClick={() => {
					console.log('11');
					history.push('search');
				}}>
					<div className="search-wrapper">
						<em className="iconfont icon-search" />
						<div className="search-input"><span>搜索商品/品牌</span></div>
					</div>
				</div>
				<div className="category-wrapper">
					<div className="category-grouplist-wrapper">
						{
							groupList?.length > 0 && groupList.map((item, idx) => {
								return (
									<div className={`category-grouplist-item${group?.id === item.id ? ' activity' : ''}`} key={idx} onClick={() => {
										setGroup(null);
										reset();
										setTimeout(() => {
											setGroup(item);
										}, 20);
									}}>
										{
											item.cover ? <img src={`${CONST.MEDIA_HOST}${item.cover}`} className="category-img" /> : <div className='category-img'></div>
										}
										
										<p>{item.name}</p>
									</div>
								)
							})
						}
					</div>
					<div className="category-productlist-wrapper">
						{
							<InfiniteScroll
								loadMore={getList}
								hasMore={hasMore}
								useWindow={false}
								initialLoad={true}
								loader={list?.length > 0 && <div className="infinite-scroll-ending-tip" key={0}>加载中...</div>}
							>
								<List {...{
									list,
									numberInLine: 1,
									gap: 10,
									threshold: 250,
									renderItem: (data = {}) => {
										return (
											<ProductItem {...{
												data,
												mode: 'ltr'
											}} />
										)
									}
								}} />
							</InfiniteScroll>
						}
						{
							(!isInit && (!list || list?.length == 0) && <EmptyListContent />) ||
							!hasMore && list?.length > 0 && <div className="infinite-scroll-ending-tip" key={0}>我就是底线</div>
						}
					</div>
				</div>
			</section>
		</Page>
	);
};

export default PageModel;
