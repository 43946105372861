/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Page from '../../components/page';
import List from '../../components/list';
import './index.less';
import * as CONST from "../../common/const";
import EmptyListContent from "../../components/emptyListContent";
import Button from "../../components/button";
import Modal from "../../components/modal";

const PageModel = props => {
	const history = useHistory();
	const type = Utils.getSearchByKey('type');

	const [ list, setList ] = useState([]);
	const getList = async () => {
		if(!type) return;
		const result = await Request.get({
			url: '/message/list',
			data: {
				type
			}
		});
		if(!result) return;

		const list = result.data.list;
		setList(list);
	};
	useEffect(() => {
		getList();

		return () => false;
	}, [123]);

	const markMessageIsReady = async (id, idx) => {
		const result = await Request.post({
			url: '/message/read',
			data: { id }
		});
		if(!result) return;

		list[idx].isRead = true;
		setList([...list]);
	};

	return (
		<Page {...{
			...props,
			page: 'message', title: ({ [CONST.MESSAGE_TYPE_SYSTEM]: '系统消息', [CONST.MESSAGE_TYPE_LOGISTICS]: '物流信息' })[type]
		}}>
			<section className="message-list-container">
				{
					list?.length > 0 && list.map((item, idx) => {
						return (
							<div className={`message-list-item${item.isRead ? ' read' : ''}`} key={idx} onClick={async () => {
								if(!item.isRead) {
									await markMessageIsReady(item.id, idx);
								}
								if(item.type == 2) {
									console.log('物流详情看看');
								}
							}}>
								<span>{item.title}</span>
								<span>{item.content}</span>
								<span>{item.updatedAt && moment(item.updatedAt).format('YYYY-MM-DD HH:mm')}</span>
								<span className="read-status">{item.isRead ? '已读' : '未读'}</span>
							</div>
						)
					})
				}
			</section>
		</Page>
	);
};

export default PageModel;
