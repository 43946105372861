import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { InvoiceItem1ListData, InvoiceItem2ListData } from '../../../common/const';
import { submitInvoiceRequest } from '../../../common/requestList';
import { toast, _alert } from '../../../common/utils';
import City from '../../../components/city';
import Modal from "../../../components/modal";
import './index.less';
import Page from '../../../components/page';


const items = [InvoiceItem1ListData, InvoiceItem2ListData];


const AddInvoice = ({ title, desc}) => {
    const history = useHistory();
    const { id } = useParams();
    const [ cityFlag, setCityFlag ] = useState(false);
    const [ formData, setFormData ] = useState({
        type: 1,
        recipientName: '',
        recipientMobile: '',
        recipientAddress: '',
        recipientAddressDetail: '',
        targetTax: '',
        targetName: '',
        targetType: 1,
        category: 1,
        invoiceType: 1,
        region: ['--省--', '--市--', '--区--'],
        regionCode: ['', '', ''],
        email: ''
    });

    const bindRegionChange = (region, regionCode) => {
        setFormData({
            ...formData,
            region,
            regionCode
        })
    }

    const radioChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value
        })
    };
    const inputChange = (e, key) => {
        const value = e.currentTarget.value;
        setFormData({
            ...formData,
            [key]: value
        });
    }


    /**
     * 校验提交订单的参数的合法性
     */
    const validParam = () => {
        const itemList = [...items[0], ...items[1]].filter(item => !item.visible || formData[item.visible.key] != item.visible.value);
        let str = '';
        for(let i = 0; i < itemList.length; i++) {
        if (itemList[i].visible && formData[itemList[i].visible.key] != itemList[i].visible.value) {
            continue;
        }
        const key = itemList[i].key;
        let value = formData[key];
        if (itemList[i].type == 'picker') {
            value = `${formData[key + 'Code'][0]}`;
        }
        if (itemList[i].patterns && !!validItem(value, itemList[i].patterns)) {
            str = validItem(value, itemList[i].patterns);
            break;
        }
        }
        if (str) {
            _alert(str);
            return false
        }
        return true;
    }
    const validItem = (value, patterns) => {
        for(let i = 0; i < patterns.length; i++) {
        const item = patterns[i];
        if (item.required && value.replace(/s/g, '').length == 0 || item.reg && !new RegExp(item.reg, 'g').test(value)) {
            return item.text;
        }
        }
        return '';
    }
    /**
     * 格式化发票字段
     */
    const formatParam = () => {
        const orderId = id;
        let _param = { ...formData };
        _param.orderId = orderId
        _param.recipientAddress = _param.region.join('') + _param.recipientAddressDetail;
        delete _param.region;
        delete _param.regionCode;
        return _param;
    };
    /**
     * 提交发票
     */
    const submit = async () => {
        const orderId = id;
        if (!orderId) {
            _alert('订单不存在');
            return  ;
        }
        const isValid = validParam();
        if (!isValid) {
        return ;
        }
        const param = formatParam();
        const res = await submitInvoiceRequest(param);
        
        if (res?.ok == 0) {
            _alert('提交成功', () => {
                history.go(-1);
            });
        }
    }

    return (
    <Page {...{
        page: 'add-invoice', title: '申请发票'
    }}>
    <div className="add-invoice-container">
        {
            items.map((pItem, pIndex) => <div className="block" key={pItem.key}>
                {
                    pItem.map((item, index) => <>
                    {
                        (!item.visible || formData[item.visible.key] == item.visible.value) ? <div
                         className="form-item" key={`${item.key}${pItem.text}`}>
                            <span className="form-item-label">{item.label}</span>
                            <div className="form-item-content">
                                {
                                    item.type == 'radioBtn' && item.contents?.map((content) => <span
                                    key={`${item.key}${pItem.text}${content.text}`} 
                                    className={`radio-btn ${formData[item.key] == content.value ? 'selected' : ''}`} onClick={() => radioChange(item.key, content.value)}>{content.text}</span>)
                                }
                                {
                                    item.type == 'text' || item.type == 'number' ? <input className="form-item-input" type={item.type} data-key={item.key} onInput={e => inputChange(e, item.key)} placeholder={item.placeholder} /> : null
                                }
                                {
                                    item.type == 'picker' ? <div className="flex1 flex">
                                    {/* <picker mode="region" onChange={(e) => bindRegionChange(e)} value={formData[item.key]} className="address"> */}
                                    <div className="picker f-24 color-333 flex1" onClick={() => setCityFlag(true)}>
                                        {formData[item.key][0]} {formData[item.key][1]} {formData[item.key][2]}
                                    </div>
                                    {/* </picker> */}
                                    <div className="iconfont icon-arrow-right"></div>
                                </div> : null
                                }
                            </div>
                        </div> : null
                    }
                    </>)
                }
            
          </div>)
        }
        <Modal {...{
				title: '选择城市',
				visible: cityFlag,
				buttonOption: {
					text: '确认',
					// disabled: !(params.province && params.provinceCode && params.city && params.cityCode && params.distinct && params.distinctCode),
					onClick: () => {
						setCityFlag(false);
					}
				}
			}}>

                <City {...{
                    province: { name: formData.region[0], code: formData.regionCode[0] },
                    city: { name: formData.region[1], code: formData.regionCode[1] },
                    area: { name: formData.region[2], code: formData.regionCode[2] },

                    onChange: ({ province, city, area }) => {
                        bindRegionChange([province.name, city.name, area.name], [province.code, city.code, area.code])
                    }
                }} />
            </Modal>
    <div className="submit-btn" onClick={submit}>申请发票</div>
  </div></Page>)
}

export default AddInvoice;
