/**
 * Created by jimmy on 2019/9/29.
 */

import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter as Router, Route, Switch, useParams, useHistory } from "react-router-dom";
import * as CONST from '../../common/const';
import Page from '../../components/page';
import './index.less';
import * as Request from "../../common/request";
import Banners from "../../components/banners";
import Block from "../../components/block";
import ProductCommentItem from "../../components/product/commentItem";
import ProductItem from "../../components/product/productItem";
import Modal from "../../components/modal";
import * as Utils from "../../common/utils";
import List from "../../components/list";

const BrandPage = props => {
	const history = useHistory();
	const { id } = useParams();
	const title = Utils.getSearchByKey('title');

	const [list, setList ] = useState([]);
	const getList = async () => {
		const result = await Request.get({
			url: '/brand/product/list',
			data: {
				id,
				// page: pageIndex,
				// pageSize,
			}
		});
		if(!result) return;
		setList(result.data.list);
	};
	useEffect(() => {
		getList();
	}, [123]);

	const [ data, setData ] = useState(null);
	const getData = async () => {
		if(!title) {
			const result = await Request.get({
				url: '/brand/get',
				data: { id }
			});
			if(!result) return;

			setData(result.data);
		}
	};
	useEffect(() => {
		getData();
	}, [123]);

	return (
		<Page {...{
			...props,
			page: 'brand', title: title || data?.name
		}}>
			<section className="brand-container" style={{ minHeight: window.innerHeight }}>
				<List {...{
					list,
					numberInLine: 2,
					gap: 10,
					renderItem: productData => {
						return (
							<ProductItem {...{
								data: productData,
								// mode: 'ltr'
							}} />
						)
					}
				}} />
				{/*{
					list?.length > 0 && list.map((item, idx) => {
						return (
							<ProductItem key={idx} {...{
								data: item
							}} />
						)
					})
				}*/}
			</section>
		</Page>
	);
};

export default BrandPage;
