export const getStorageData = (key, defaultValue = null) => {
  let _data = window.localStorage.getItem(key);
  try {
    _data = JSON.parse(_data);
  } catch(e) {
    _data = defaultValue;
  }
  return _data;
}
/**
 * 把需要立即购买的东西放到Storage中
 */
 export const setBookingDataList = (list) => {
   window.localStorage.setItem('bookingDataList', JSON.stringify(list));
  // window.localStorage.setItem('bookingDataList', JSON.stringify(list));
}
export const getBookingDataList = () => {
  return getStorageData('bookingDataList', [])
};
export const removeBookingDataList = () => { window.localStorage.removeItem('bookingDataList') }
/**
 * 设置收货地址信息
 */
export const setEditShippingAddress = (data) => {
  window.localStorage.setItem('editShippingAddress', JSON.stringify(data));
}
export const getEditShippingAddress = () => window.localStorage.getItem('editShippingAddress');
/**
 * 设置当前选择的收货地址
 */
export const setChoosedAddress = (data) => {
  window.localStorage.setItem('choosedShippingAddress', JSON.stringify(data));
}
export const getChoosedAddress = () => {
  console.log('获取收货地址', localStorage.getItem('choosedShippingAddress'));
  return getStorageData('choosedShippingAddress');
};