/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import {useHistory, useParams} from 'react-router-dom';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Page from '../../components/page';
import List from '../../components/list';
import './index.less';
import * as CONST from "../../common/const";
import EmptyListContent from "../../components/emptyListContent";
import Button from "../../components/button";
import Modal from "../../components/modal";
import OrderItem from "../../components/order/orderItem";
import moment from "moment";
import OrderButton from "../../components/order/orderButton";

const OrderPage = props => {
	const history = useHistory();
	const { id } = useParams();

	const [ data, setData ] = useState(null);
	const getData = async () => {
		if(!id) return;
		const result = await Request.get({
			url: '/order/get',
			data: { id }
		});
		if(!result) return;

		setData(result.data);
	};
	useEffect(() => {
		getData();
	}, [ 123 ]);

	return (
		<Page {...{
			...props,
			page: 'order', title: '订单信息'
		}}>
			<section className="order-container">
				<div className="address-info-wrap">
					<em className="iconfont icon-address-list" />
					<div>
						<div className="address-user-info">
							<span className='receiver-name'>收货人：{data?.recipientName}</span>
							<span>联系方式：{data?.recipientMobile}</span>
						</div>
						<div className="address-info">{data?.recipientProvince}{data?.recipientCity}{data?.recipientDistinct}{data?.recipientAddress}</div>
					</div>
				</div>

				<div className="order-product-info-wrap">
					<div className="merchant-info">{data?.merchantInfo?.name}</div>
					<div className="order-product-info">
						{
							data?.productList?.length > 0 && data.productList.map((pitem, pidx) => {
								return <OrderItem key={pidx} {...{
									data: pitem,
									detailData: data
								}} />
							})
						}
					</div>
					<div className="order-price">
						<span>合计：</span>
						<span className="price-value">¥{data?.paidPrice}</span>
						{ data?.shipping && <span>（含运费¥{data?.shipping}）</span> }
					</div>
					<div className="order-info">
						<span>订单编号：{data?.serialNumber?.substr(0, 10)}</span>
						<span>下单时间：{data?.createdAt && moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
						<span>订单状态：<i className={`status${data?.status == CONST.ORDER_STATUS_TO_PAY ? ' status-topay' : ''}`}>{CONST.CONST_LABEL[data?.status]}</i></span>
					</div>
					<div className="order-ctrls">
						<div className="order-ctrl-service-item" onClick={() => window.location.href = CONST.customerServiceLink}><em className="iconfont icon-customer-service" /><span>联系客服</span></div>
						<OrderButton {...{
							from: 'detail',
							data
						}} />
					</div>
				</div>
			</section>
		</Page>
	);
};

export default OrderPage;
