import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MEDIA_HOST } from '../../common/const';
import { getTrackingOrder } from '../../common/requestList';
import Page from '../../components/page';
import './index.less';

const PageShippingInfo = (props) => {
  const { id } = useParams();
  const [packageList, setPackageList] = useState([])
  const [ currentPackageIndex, setCurrentPackageIndex ] = useState(0);

  const getShippingData = async () => {
    const res = await getTrackingOrder({ id });
    let _list = res?.data?.packageList || [];
    _list = _list.map(item => {
      // 头图
      try {
        item.coverImage = JSON.parse(item.productList[0].productInfo.images)[0]
      } catch(e) {
        item.coverImage = null;
      }
      return item;
    })
    setPackageList(_list || [])
  }
  useEffect(() => {
    getShippingData();
  }, [ 123 ])
  /**
   * 修改tab
   */
  const changePackage = (index) => {
    setCurrentPackageIndex(index);
  }


  return <Page {...{
    ...props,
    page: 'shipping-info', title: '物流信息'
  }}>
    <div className="shipping-info-container">
      <div className="shipping-product-scroll-view" >
        { /* 多个包裹 */ }
        {
          packageList.map((item, index) => <div className="package-item {currentPackageIndex == index ? 'selected' : ''}" onClick={() => changePackage(index)}>
          <div className="flex">
            <div className="tc">
              <div className="package-label">包裹{index + 1}</div>
              <div className="package-desc">共{item.productList.length}件</div>
            </div>
            <img src={`${MEDIA_HOST}${item.packageCover}`} className="product-img" />
          </div>
        </div>)
        }
        
      </div>

      { /* 物流信息 */ }
      {
        packageList?.length ? <div className="shipping-info-container">
        <div className="title f-28 color-333 lh-1">订单号: {packageList[0].productList[0].serialNumber}</div>
        <div className="title f-28 color-333 lh-1">物流单号: {packageList[currentPackageIndex].trackingNumber}</div>
        <div className="title f-28 color-333 lh-1">承运来源: {packageList[currentPackageIndex].trackingCompanyName}</div>
        <div className="title f-28 color-333 lh-1">物流状态</div>
        <div className="info-list f-24">
          {
            packageList[currentPackageIndex].trackingInfo.list.map((item, index) => <div className={`info-item ${index == 0 ? 'latest-item' : ''}`}>
            <text className="progress"></text>
            <div className="info-content">{item.context}</div>
            <div className="info-date">{item.time}</div>
          </div>)
          }
          
        </div>
      </div> : null
      }
      
    </div>

  </Page>
}

export default PageShippingInfo;
