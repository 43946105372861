/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import Page from '../../components/page';
import EmptyListContent from '../../components/emptyListContent';

import './index.less';

const PageModel = props => {
	return (
		<Page {...{
			...props,
			page: '404', title: '404'
		}}>
			<div className="m404-container" style={{ height: window.innerHeight }}>
				<EmptyListContent />
			</div>
		</Page>
	);
};

export default PageModel;
