/**
 * Created by jimmy on 2022/9/25.
 */

import React, { useState, useEffect } from 'react';
import * as CONST from '../../common/const';
import './index.less';

const CompBlockModel = props => {
	const { style } = props;

	const [ status, setStatus ] = useState(false);
	useEffect(() => {
		setStatus(props.status);
	}, [ props.status ]);

	useEffect(() => {
		if(props.onChange && typeof props.onChange == 'function'){
			props.onChange(status);
		}
	}, [ status ]);

	return (
		<section className={`comp-switch-wrapper${status && ' on' || ''}`} style={style} onClick={() => {
			setStatus(!status);
		}}>
			<div className="switch-ele" />
		</section>
	)
};

export default CompBlockModel;
