/**
 * Created by jimmy on 2022/9/25.
 */

import React, { useState, useEffect } from 'react';
import * as CONST from '../../common/const';
import './index.less';

const CompKingAreasModel = props => {
	const { style = {}, list = [], numberInLine = 4, gap = 15 } = props;

	if(!list || list.length <= 0) return null;

	return (
		<section className="comp-king-areas-wrapper" style={{ ...style, marginBottom: -gap }}>
			{
				list.map((item, idx) => {
					return (
						<div className="kaitem" style={{ width: `${100/numberInLine}%`, marginBottom: gap }} key={idx} onClick={() => {
							props.clickHandle(item, idx);
						}}>
							{ item.cover && <div className="ka-item-img" style={{ backgroundImage: `url(${ CONST.MEDIA_HOST + item.cover })` }} /> }
							{
								item.icon && <div className={`ka-item-iconfont iconfont ${item.icon}`} style={{ ...item.iconStyle, fontSize: 120/numberInLine }}>
									{ !!item.badge && <i className="kabadge">{item.badge}</i> }
								</div>
							}
							<span className="ka-item-label overflow-2-ellipsis">{item.label}</span>
						</div>
					)
				})
			}
		</section>
	);
};

export default CompKingAreasModel;
