import { HOME_ID } from "./const";
import { getPageConfig } from "./requestList";

const initData = async() => {
  const res = await getPageConfig({number: HOME_ID});
  if (res?.ok == 0) {
    window.MALL_DATA = res.data;
  }
}
export default initData;
