/**
 * 商家资质
 */

 import React, {useState, useEffect, useRef} from 'react';
import { useParams } from 'react-router-dom';
 import { EnterpriceQualificationList, MEDIA_HOST } from '../../common/const';
import { getMerchantInfo } from '../../common/requestList';
 import Page from '../../components/page';
 import './index.less';
 
 const list = [...EnterpriceQualificationList];
 
 const EnterpriceQualification = (props)  => {
   const id = useParams().id;
   const [data, setData] = useState(null);
   const [items, setItems] = useState([]);
   const getData = async() => {
    const res = await getMerchantInfo({
      id
    });
    let _data = res?.data || null;
    if (_data) {
      const _items = list.map(item => {
        if (_data[item.key]) {
          return {
            ...item,
            value: _data[item.key]
          }
        }
        return { ...item };
      })
      setItems(_items);
      // 资质图片
      try {
        _data.license = JSON.parse(_data.license);
        _data.businessCategory = JSON.parse(_data.businessCategory);
      } catch(e) {}
    }
    setData(_data);
   }
   useEffect(() => {
    getData();
   }, [123])
   return <Page {...{
    ...props,
    showFooter: false,
    page: 'enterpriceQualification', title: '商家资质'
  }}>
   <div className='enterprice-page-container'>
     {/* 资质信息 */}
     <div className='cart'>
       {
         items.map(item => <div className='info-item' key={`info${item.id}`}>
           <div className='label'>{item.label}</div>
           <div className='value'>{item.value || '暂无'}</div>
         </div>)
       }
       <div className='bottom-desc'>
         注：以上营业执照信息来源于供应商提供或市监部门系统数剧，具体以市监部门登记为准。<br />
        经营者需确保信息真实有效，平台也将定期核查，如与实际不符，为避免违规，请联系当地市监部门或平台客服更新。</div>
     </div>
     {/* 经营许可证图片 */}
     {
       data?.license?.length ? <div className='cart'>
        {
          data.license.map(item => <div className='img-wrapper'>
            <img className='license-img' src={`${MEDIA_HOST}${item}`} />
          </div>)
        }
      </div> : null
     }
     
   </div>

   </Page>
 }
 export default EnterpriceQualification;