/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { pca, pcaa } from 'area-data'; // v3 or higher
import 'react-area-linkage/dist/index.css'; // v2 or higher
import { AreaSelect, AreaCascader } from 'react-area-linkage';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Page from '../../components/page';
import List from '../../components/list';
import './index.less';
import * as CONST from "../../common/const";
import EmptyListContent from "../../components/emptyListContent";
import Button from "../../components/button";
import Modal from "../../components/modal";
import Switch from "../../components/switch";
import City from "../../components/city";

const PageModel = props => {
	const history = useHistory();
	const id = Utils.getSearchByKey('id');

	const [ data, setData ] = useState(null);
	const getData = async () => {
		if(!id) return;
		const result = await Request.get({
			url: '/address/get',
			data: { id }
		});
		if(!result) return;

		setData(result.data);
	};
	useEffect(() => {
		getData();
	}, [ id ]);

	const [ cityFlag, setCityFlag ] = useState(false);
	const [ params, setParams ] = useState({ name: '', mobile: '', isDefault: 2, province: '', provinceCode: '', city: '', cityCode: '', distinct: '', distinctCode: '', address: '' });
	useEffect(() => {
		console.log('data', data)
		if(data) {
			setParams({ ...params, ...data });
		}

		return () => false;
	}, data);
console.log('params123', params);

	const submit = async () => {
		if(!params.name) return Utils._alert('请填写有效的收货人姓名');
		if(!Utils.mobileVerify(params.mobile)) return Utils._alert('请填写有效手机号');
		if(!params.provinceCode) return Utils._alert('请选择收货地址');
		if(!params.address) return Utils._alert('请填写完整的收货信息');

		const result = await Request.post({
			url: params?.id ? '/address/update' : '/address/add',
			data: params
		});
		if(!result) return;

		Utils._alert('保存成功', () => {
			history.go(-1);
		});
	};

	return (
		<Page {...{
			...props,
			page: 'address-edit', title: '地址管理'
		}}>
			<section className="address-edit-container">
				<div className="address-form-item">
					<label>收件人</label>
					<input placeholder="姓名" defaultValue={params.name} onChange={e => {
						setParams({ ...params, name: e.target.value });
					}} />
				</div>
				<div className="address-form-item">
					<label>手机号</label>
					<input placeholder="联系电话" maxLength={11} defaultValue={params.mobile} onChange={e => {
						setParams({ ...params, mobile: e.target.value });
					}} />
				</div>
				<div className="address-form-item">
					<label>收货地址</label>
					<span onClick={() => {
						setCityFlag(true);
					}}>{ !(params.province&&params.city&&params.distinct) && <i>请选择</i> || [ params.province, params.city, params.distinct ].join(' ')}</span>
				</div>
				<div className="address-form-item address-form-textarea-item">
					<label>详细地址</label>
					<textarea placeholder="街道和门牌号" defaultValue={params.address} onChange={e => {
						setParams({ ...params, address: e.target.value });
					}} />
				</div>
				<div className="address-form-item">
					<label>设为默认</label>
					<Switch {...{
						status: params.isDefault == 1 ? true : false,
						style: {},
						onChange: status => {
							console.log('swtich', status);
							setParams({ ...params, isDefault: status == true ? 1 : 2 });
						}
					}} />
				</div>
			</section>

			<Button {...{
				className: 'address-edit-button',
				text: '保存地址',
				onClick: () => {
					console.log('params submit ', params);
					submit();
				}
			}} />

			<Modal {...{
				title: '选择城市',
				visible: cityFlag,
				buttonOption: {
					text: '确认',
					disabled: !(params.province && params.provinceCode && params.city && params.cityCode && params.distinct && params.distinctCode),
					onClick: () => {
						console.log('params', params);
						setCityFlag(false);
					}
				}
			}}>
				<City {...{
					province: { name: params.province, code: params.provinceCode },
					city: { name: params.city, code: params.cityCode },
					area: { name: params.distinct, code: params.distinctCode },

					onChange: ({ province, city, area }) => {
						setParams({
							...params,
							province: province.name, provinceCode: province.code,
							city: city.name, cityCode: city.code,
							distinct: area.name, distinctCode: area.code
						});
					}
				}} />
			</Modal>
		</Page>
	);
};

export default PageModel;
