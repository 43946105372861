/**
 * Created by jimmy on 2022/9/25.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as CONST from '../../../common/const';
import './index.less';

const CompProductItemModel = props => {
	const history = useHistory();
	const { style = {}, mode = 'double-column', showCartIcon = true } = props;
	const [ data, setData ] = useState(null);
	useEffect(() => {
		setData(props.data);
	}, [ props.data ]);

	const renderPrice = (price = '') => {
		if(!price) return null;
		if(!isNaN(price)){
			price = String(price);
		}
		const _priceArr = price.split('.');
		return <span className="value">{_priceArr[0]}<i>{_priceArr[1] ? `.${_priceArr[1]}` : ''}</i></span>
	}

	if(!data) return null;
	return (
		<section className={`comp-product-item-wrapper mode-${mode}`} style={style} onClick={() => {
			if(!showCartIcon) return;
			history.push(`/product/${data.id}`);
		}}>
			<div className="cover" style={{ backgroundImage: `url(${ CONST.MEDIA_HOST + data?.images[0] })` }}>
				{ !data.stock && !data.discountStock &&  <div style={{ backgroundImage: `url(${CONST.ICON_BASE_URL}sell-out.png)`}} className="sell-out-img"/> }
			</div>
			<div className="content">
				<div className="name overflow-2-ellipsis">{data.name}</div>
				<div className='desc overflow-ellipsis'>{data.intro}</div>
				<div className="price-wrappper">
					<div className="price">
						<span className="unit">&yen;</span>
						{
							renderPrice(data.productPrice || data.price || '')
						}
						{ mode === 'ltr' && data.originalPrice && <span className="origin-price">¥{data.originalPrice}</span> }
					</div>
					{ showCartIcon && <em className="shop-cart-icon iconfont icon-gouwuche" /> }
				</div>
			</div>
		</section>
	);
};

export default CompProductItemModel;
