/**
 * Created by jimmy on 2022/9/25.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as CONST from '../../../common/const';
import './index.less';

const CompProductPromotionModel = props => {
	const history = useHistory();
	const { style = {}, data } = props;

	if(!data) return null;

	return (
		<section className="comp-promotion-item-wrapper" style={style} onClick={() => {
			history.push(`product/${data.productInfo.id}`);
		}}>
			<div className="img-wrapper">
				<div className="good-item-img" style={{ backgroundImage: `url(${ CONST.MEDIA_HOST + data.productInfo?.images[0] })` }} />
				{
					!data.discountStock && <div className="sell-out-wrapper">
						<img src={`${CONST.ICON_BASE_URL}sell-out.png`} className="sell-out-img"/>
					</div>
				}
			</div>
			<div className="good-item-wrapper">
					<div className="good-item-name">{data.productInfo.name}</div>
					<div className="good-price-wrapper">
						<div className="price-wrapper">
							<span className="price">¥{data.productPrice || data.productInfo.price}</span>
							<span className="market-price">
								{
									data.productInfo.originalPrice && <span>¥{data.productInfo.originalPrice}</span>
								}
							</span>
						</div>
						<div className={`buy-btn${!data.discountStock ? ' disabled' : ''}`}>抢</div>
					</div>
				</div>
		</section>
	);
};

export default CompProductPromotionModel;
