/**
 * Created by jimmy on 2022/9/25.
 */

import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import * as CONST from '../../common/const';
import EmptyListContent from '../emptyListContent';
import List from '../list';
import './index.less';
import ProductPromotionItem from "../product/discountItem";

const CompListModel = props => {
	let { style = {}, tab = {}, tabs = [], list = [], checkMoreAction, tabContainerStyle = {} } = props;

	return (
		<section className="comp-tablist-wrapper" style={style}>
			<div className="tabs-container" style={tabContainerStyle}>
				{
					tabs?.length > 0 && tabs.map((item, idx) => {
						return (
							<div className="tab-item" key={idx} onClick={() => props.tabChange(item)}>
								<span>{item.name}</span>
								{ tab?.id == item.id && <i /> }
							</div>
						)
					})
				}
			</div>
			{
				list?.length > 0 && <div className="tab-list-container">
					<List {...{
						list: props.list,
						numberInLine: props.numberInLine,
						gap: props.gap,
						renderItem: props.renderItem
					}} />
					{
						checkMoreAction && typeof checkMoreAction == 'function' && <div className="list-more" onClick={() => {
							checkMoreAction(tab);
						}}>查看更多</div>
					}
				</div> || <EmptyListContent />
			}
		</section>
	);
};

export default CompListModel;
