/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';

import './index.less';
import IMG_404 from "../../pages/404/images/404.png";

const CompModel = props => {
	const { text = '没啥内容，看看其他的吧', style = {} } = props;
	return (
		<div className="comp-404-wrapper c404" style={style}>
			{/*<img src={IMG_404} />*/}
			<span>{text}</span>
		</div>
	);
};

export default CompModel;
