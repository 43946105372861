/**
 * 票券下单页面
 */
 import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { getBookingDataList, removeBookingDataList } from '../../common/cache';
import { ICON_BASE_URL, ORDER_PAY_METHOD_WX_H5, weekEnum } from '../../common/const';
import { createTicketOrder } from '../../common/requestList';
import { wx, _alert } from '../../common/utils';
import { Calendar } from 'react-vant';
import AddCount from '../../components/addCount';
import ProductDescModal from '../../components/productDescModal';

 import './index.less';
import { useHistory } from 'react-router-dom';
import * as CONST from "../../common/const";
import * as Utils from "../../common/utils";

 const itemListData = [
    { label: '姓名',
      type: 'text',
      key: 'name',
      placeholder: '必填，请输入证件上的姓名',
      patterns: [{ required: true, text: '请输入出行人的姓名'}, { reg: '\\S{2,}', text: '请输入正确的出行人姓名' }]
    },
    { label: '身份证',
      type: 'text',
      key: 'idCard',
      placeholder: '必填，请输入证件号',
      patterns: [{ required: true, text: '请输入出行人的证件号'}, { reg: '\^\\d\{17\}\[\\dXx\]\$', text: '请输入正确的身份证号' }]
    },
    { label: '联系电话',
      type: 'number',
      key: 'mobile',
      placeholder: '必填，请输入联系人手机号',
      patterns: [{ required: true, text: '请输入联系人手机号'}, { reg: '\^1\\d\{10\}\$', text: '请输入正确的联系人手机号' }]
    }
  ];
const initFomeData = {name: '', idCard: '', mobile: ''};

// 一个中转数据，为了格式化disable的日期
let _dateListContext = [];
 const TicketBooking = ({}) => {
    const history = useHistory();
    const noticeList = [{src: 'health-code.png', label: '健康码'}, {src: 'travel-code.png', label: '行程码'}, {src: 'epidemic-prevention-policy.png', label: '防疫政策'}];
    const formatter = (day) => {
        if (_dateListContext.filter(item => item.date == moment(day.date).format('YYYY-MM-DD'))?.[0]?.status == 2) {
          day.type = 'disabled'
        }
        return day;
      }
    const [itemList, setItemList] = useState([itemListData]);
    const [travellers, setTravellers] = useState([{...initFomeData}]);
    const [calendarVisible, setCalendarVisible] = useState(false);
    const [data, setData] = useState('');
    const [totalPrice, setTotalPrice] = useState(0);
    const [maxCount, setMaxCount] = useState(99);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedWeekText, setSelectedWeekText] = useState('');
    const [ticketData, setTicketData] = useState(null);
    const [count, setCount] = useState(1);
    const [isBooking, setIsBooking] = useState(false);
    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');
    const [noteModalVisible, setNoteModalVisible] = useState(false);
    const [showDescList, setShowDescList] = useState([]);
    const [dateReady, setDateReady] = useState(true);


    /**
     * 从storage中获取需要下的商品
     */
    function getProductData() {
        const list = getBookingDataList();
        const _data = list?.[0]?.productList?.[0];
        const _date = moment(_data.selectedDate);
        const _ticketData = list[0].productList[0];
        _dateListContext = _ticketData?.itemInfo?.dates || [];
        setTicketData(_ticketData);
        console.log('日期', _date.format('YYYY-MM-DD'), _ticketData);
        setSelectedDate(`${_date.format('YYYY-MM-DD')}`);
        setSelectedWeekText(`周${weekEnum[Number(_date.format('E')) - 1]}`);
        setCount(_ticketData.count);
        setMinDate(new Date(_data.minDate));
        setMaxDate(new Date(_data.maxDate));
        setDateReady(true);
        // updateTicketInfo();
    }

    /**
     * 计算商品的总价
     */
    function updateTicketInfo() {
        console.log('更新1', ticketData);
        let _totalPrice = 0;
        const _data = ticketData;
        const _currentDateInfo = _data?.itemInfo.dates?.filter(item => item.date == selectedDate)?.[0];
        // 活动价格
        const _discountInfo = ticketData?.productInfo?.discountInfo;
        console.log('pdateTicketInfos', selectedDate, _currentDateInfo,22, _data);
        // 正在活动中，并且有库存，则展示活动价格
        const _price = _discountInfo?.status == 2 && _discountInfo?.discountStock > 0 ? Number(_discountInfo.productPrice) : Number(_currentDateInfo?.price);
        _totalPrice = Math.round(_price * 100) * count / 100;
        // 最大库存量，如果有活动的话，在活动的库存量和当天的库存量中选择最低的
        let _maxCount = _currentDateInfo?.stock;
        if (_data?.productInfo?.discountInfo?.discountStock < _maxCount) {
            _maxCount = _data.productInfo.discountInfo.discountStock;
        }
        console.log('_totalPrice', _totalPrice, _price, ticketData);
        setMaxCount(_maxCount);
        setData(_data);
        setTotalPrice(_totalPrice.toFixed(2))
    }

    function addTravellers() {
        setItemList(itemList.concat([itemListData]));
        setTravellers(travellers.concat({...initFomeData}));
    }

    /**
     * 删除出行人
     */
    function deletePsg(index) {
        if (index && travellers[index] && itemList[index]) {
            travellers.splice(index, 1);
            itemList.splice(index, 1);
            setItemList([...itemList]);
            setTravellers([...travellers]);
        }
    }
    /**
     * 校验提交订单的参数的合法性
     */
    function validParam() {
        let str = '';
        for(let i = 0; i < itemList.length; i++) {
        for(let j = 0; j < itemList[i].length; j++) {
            const key = itemList[i][j].key;
            const value = travellers[i][key];
            if (!!validItem(value, itemList[i][j].patterns)) {
            str = validItem(value, itemList[i][j].patterns);
            break;
            }

        }
        }
        if (str) {
            _alert(str);
            return false
        }
        return true;
    }
    function validItem(value, patterns) {
        for(let i = 0; i < patterns.length; i++) {
            const item = patterns[i];
            if (item.required && value.replace(/s/g, '').length == 0 || (item.reg? !(new RegExp(item.reg).test(value)) : false)) {
                return item.text;
            }
        }
        return '';
    }
    /**
     * 组装下单的数据
     */
    function formateSubmitParam() {
      const ua = Utils.getUA();
        let param = {
            payment: ua.isInWechat ? CONST.ORDER_PAY_METHOD_WX_JS : CONST.ORDER_PAY_METHOD_WX_H5
        };
        const _currentDateInfo = data.itemInfo.dates?.filter(item => item.date == selectedDate)?.[0];
        let _productData = {
            id: data.productId,
            count,
            itemDetailId: data.itemId || 0,
            dateId: _currentDateInfo.id,
        };
        // 如果有活动，并且有库存
        if (data?.productInfo?.discountInfo?.discountStock >= count) {
            _productData.discountId = data.productInfo.discountInfo.discountId;
        }
        param.product = _productData;
        // 出行人
        param.travellers = travellers;
        return param;
    }
    /**
     * 创单
     */
    async function submit() {
        const isValid = validParam();
        if (!isValid) {
            return false;
        }
        const param = formateSubmitParam();
        const res = await createTicketOrder(param);
        if (res?.ok == 0) {
            // 创单成功，去掉storage中的数据
            removeBookingDataList();
            if(res?.data?.nonceStr || res?.data?.h5_url) {
                pay(res.data);
            } else {
                toOrderList();
            }
        } else {
            _alert(res?.msg);
        }
        setIsBooking(false);
    }
    function calendarConfirm(date) {
        const _date = moment(date);
        setCalendarVisible(false);
        setSelectedDate(`${_date.format('YYYY-MM-DD')}`);
        setSelectedWeekText(`周${weekEnum[Number(_date.format('E')) - 1]}`);
        // updateTicketInfo();
    }
    /**
     * 购买金额变更
     */
    function changeCount(_count) {
        let _itemList = [...itemList];
        let _travellers = [...travellers];
        _itemList = _itemList.slice(0, _count);
        _travellers = _travellers.slice(0, _count);
        setCount(_count);
        setItemList(_itemList);
        setTravellers(_travellers);
        // updateTicketInfo();
    }
    /**
     * input变更
     */
    function changeInput(e, index, itemindex) {
        const _val = e.currentTarget.value;
        const key = itemList[index][itemindex].key;
        travellers[index][key] = _val;
        setTravellers([...travellers]);
    }
    function afterGetDescData({ data = null}) {
        console.log(233);
        if (data?.descriptions && !(showDescList?.length)) {
            setShowDescList([2]);
        }
    }
    function closeBuyNoteModal() {
        setNoteModalVisible(false);
    }
    function showBuyNoteModal() {
        setNoteModalVisible(true);
    }
    /**
     * 支付成功或者取消，跳转订单详情页面
     */
    function toOrderList(id) {
        history.replace('/order/list');
    }
    function pay(config) {
        wx.pay(config, {
            success: () => {
                toOrderList();
            },
            error: () => {
                toOrderList();
            }
        });
    }

    useEffect(() => {
        getProductData();
    }, []);

    useEffect(() => {
        if (selectedDate && ticketData) {
            updateTicketInfo();
        }
    }, [ticketData, count, selectedDate]);

    return <>
    {
        ticketData ? <div className="ticket-booking-container">
        { /** 门票信息 */ }
        <div className="ticket-info-container">
            <div className="ticket-info-wrapper">
            <div className="title-label lh-1-5">{ticketData?.productInfo?.name}</div>
            { /** 购买说明和使用说明 */ }
            {
                showDescList[0] == 2 ? <div className="desc-entry-wrapper">
                    <span className="desc-entry-item" onClick={showBuyNoteModal}>购买说明</span>
                </div> : null
            }

            <div className="ticket-detail-wrapper">
                <div className="flex1"><span className="ticket-item-label">{ticketData?.itemInfo?.itemValues}</span><span>{selectedDate}</span></div>
                <div className="change-date" onClick={() => setCalendarVisible(true)}>更换日期</div>
            </div>
            { /** <div className="buy-note" onClick="showBuyNoteModal">购买须知</div> */ }
            </div>
            { /** 价格信息 */ }
            <div className="ticket-price-wrapper">
            <div className="ticket-num-wrapper flex">
                <div className="flex1">购买数量</div>
                <AddCount max={maxCount} value={count} change={changeCount} />
            </div>
            <div className="ticket-price flex">
                <div className="flex1 f-28 lh-1">合计</div>
                <div className="price">¥{totalPrice}</div>
            </div>
            </div>
        </div>
        { /** 出行人 */ }
        {
            itemList?.map((item, index) => <div className="psg-container block" key={`psg${index}`}>
                <div className="title-label flex"><span className="bold f-28">出行人</span>
                {
                    index == 0 ? <div className="desc color-333 f-24 flex1" >必须填 <span className="color-price">{count}</span> 位，用于入园身份验证</div> : <div className="iconfont icon-close" onClick={() => deletePsg(index)}></div>
                }

                </div>
                {
                    item.map((psg, psgIndex) => <div className={`input-item {psgIndex == item.length - 1 ? '' : 'bd-b'}`}>
                        <div className="input-label">{psg.label}</div>
                        <div className="input-wrapper"><input className="input-text f-28" onInput={e => changeInput(e, index, psgIndex)} value={travellers[index][psg.key]} placeholder-class="f-28 color-999" placeholder={psg.placeholder} /></div>
                    </div>)
                }

            </div>)
        }

        { /** 添加出行人 */ }
        {
            itemList.length < count ? <div className="add-psg-btn block f-28 tc bold" onClick={addTravellers} ><span className="add-icon"></span>添加出行人</div> : null
        }

        { /** 入园需要携带的证件 */ }
        <div className="notice-wrapper">
            <div className="f-28 color-333 mg-b-30">疫情期间，入园需佩戴口罩，出示健康码和行程码</div>
            <div className="notice-list">
                {
                    noticeList?.map((item, index) => <div className="notice-item">
                        <img className="notice-img" src={`${ICON_BASE_URL}${item.src}`} />
                        <span className="bold color-333 f-24">{item.label}</span>
                    </div>)
                }

            </div>
        </div>
        { /** 购买说明 */ }
        <ProductDescModal aftergetdata={afterGetDescData} close={closeBuyNoteModal} currentType={2} types={[2]} show={noteModalVisible} productId={ticketData.productId} />
        { /** 日期 */ }
        {
            dateReady ? <Calendar
                visible={calendarVisible }
                onClose={() => setCalendarVisible(false)}
                onConfirm={calendarConfirm}
                minDate={minDate}
                maxDate={maxDate}
                defaultValue={new Date(selectedDate)}
                formatter={formatter}
            /> : null
        }
        <div className="submit-btn" onClick={submit}>立即预订</div>
    </div> : null
    }

    </>
 }
 export default TicketBooking;
