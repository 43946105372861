/**
 * Created by jimmy on 2019/9/29.
 */

import React, {useState, useEffect, useRef} from 'react';
import Page from '../../components/page';
import * as Utils from '../../common/utils';
import * as CONST from '../../common/const';
import './index.less';
import ProductItem from "../../components/product/productItem";
import InfiniteScroll from "react-infinite-scroller";
import List from "../../components/list";
import * as Request from "../../common/request";
import EmptyListContent from "../../components/emptyListContent";

const SearchPage = props => {
	const [ inputKey, setInputKey ] = useState('');
	const [ searchKey, setSearchKey ] = useState('');

	const historyLocalStorage = Utils.localStorage(CONST._LOCALSTORAGE_KEY_SEARCH_HISTORY_);
	const [ searchHistory, setSearchHistory ] = useState([]);
	const getSearchHistory = () => {
		// let searchHistory = window.localStorage.getItem(_LOCALSTORAGE_KEY_SEARCH_HISTORY_);
		// if(searchHistory) {
		// 	searchHistory = JSON.parse(searchHistory);
		// }
		const searchHistory = historyLocalStorage.get();
		setSearchHistory(searchHistory || []);
	};
	useEffect(() => {
		getSearchHistory();
	}, [123]);
	const saveSearchHistory = key => {
		if(searchHistory && !searchHistory.includes(key)) {
			searchHistory.push(key);
			setSearchHistory(searchHistory);
			historyLocalStorage.set(searchHistory);
			// window.localStorage.setItem(_LOCALSTORAGE_KEY_SEARCH_HISTORY_, JSON.stringify(searchHistory));
		}
	};

	/* start of 无限列表的 */
	const [ isInit, setIsInit ] = useState(true);
	const [ list, setList ] = useState([]);
	const [ page, setPage ] = useState(0);
	const [ hasMore, setHasMore ] = useState(true);
	const reset = () => {
		setList([]);
		setPage(0);
		setHasMore(true);
		setIsInit(false);
	};
	const getList = async () => {
		if(!searchKey) return;
		const result = await Request.get({
			url: '/product/search',
			data: {
				key: searchKey,
				page,
				pageSize: CONST.PAGE_SIZE_LIMIT
			},
		});
		if(!result) return;

		setList([...list, ...result.data.list]);
		if(page < Math.ceil(result.data.count/CONST.PAGE_SIZE_LIMIT)) {
			setPage(page + 1);
		}else{
			setHasMore(false);
		}
	};
	useEffect(() => {
		getList();
	}, [ searchKey ]);
	/* end of 无限列表的 */

	return (
		<Page {...{
			...props,
			page: 'search', title: '搜索商品/品牌'
		}}>
			<section className="search-container" style={{ minHeight: window.innerHeight }}>
				<div className="search-input-wrapper-outer">
					<div className="search-input-wrapper">
						<em className="iconfont icon-search" />
						<input className="search-input" value={inputKey} placeholder="搜索商品/品牌" onChange={e => {
							setInputKey(e.target.value);
						}} />
					</div>
					<div className="search-button" onClick={() => {
						if(!inputKey) return;

						setSearchKey('');
						setTimeout(() => {
							setSearchKey(inputKey);
						}, 20);
						saveSearchHistory(inputKey);
						reset();
					}}>搜索</div>
				</div>

				{
					isInit && searchHistory?.length > 0 && <div className="search-history">
						<div className="title"><span>搜索历史</span><em className="iconfont icon-delete" onClick={() => {
							historyLocalStorage.clear();
							getSearchHistory();
						}} /></div>
						<div className="search-history-list">
							{
								searchHistory.map((item, idx) => {
									if(!item) return null;
									return (
										<div className="search-history-item" key={idx} onClick={() => {
											setSearchKey(item);
											setInputKey(item);
											reset();
										}}><span>{item}</span></div>
									)
								})
							}
						</div>
					</div>
				}

				<div className="search-result-list">
					{
						<InfiniteScroll
							loadMore={getList}
							hasMore={hasMore}
							useWindow={false}
							initialLoad={true}
							loader={list?.length > 0 && <div className="infinite-scroll-ending-tip" key={0}>加载中...</div>}
						>
							<List {...{
								list,
								numberInLine: 2,
								gap: 10,
								threshold: 250,
								renderItem: (data = {}) => {
									return (
										<ProductItem {...{
											data
										}} />
									)
								}
							}} />
						</InfiniteScroll>
					}
					{
						(!isInit && (!list || list?.length == 0) && <EmptyListContent />) ||
						!hasMore && list?.length > 0 && <div className="infinite-scroll-ending-tip" key={0}>我就是底线</div>
					}
				</div>
			</section>
		</Page>
	);
};

export default SearchPage;
