/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Page from '../../components/page';
import List from '../../components/list';
import './index.less';
import * as CONST from "../../common/const";
import ProductItem from "../../components/product/productItem";
import Button from "../../components/button";
import Modal from "../../components/modal";

const PageModel = props => {
	const [ list, setList ] = useState([]);
	const getList = async () => {
		const result = await Request.get({
			url: '/user/product/collect/list',
			data: {
				// page: pageIndex,
				// pageSize
			}
		});
		if(!result) return;

		setList(result.data.list);
	};
	useEffect(() => {
		getList();
	}, [ 123 ]);

	return (
		<Page {...{
			...props,
			page: 'product-collect-list', title: '商品收藏列表'
		}}>
			<section className="product-collect-list-container">
				{
					list?.length > 0 && list.map((item, idx) => {
						return (
							<div className="product-collect-item-wrapper" key={idx}>
								<ProductItem {...{
									data: item?.productInfo || null,
									mode: 'ltr'
								}} />
							</div>
						)
					})
				}
			</section>
		</Page>
	);
};

export default PageModel;
