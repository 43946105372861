import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getBookingDataList, getChoosedAddress, removeBookingDataList, setChoosedAddress } from '../../common/cache';
import * as CONST from '../../common/const';
import * as Utils from '../../common/utils';
import { getAddressList, getOrderShippingPrice, submitOrder, validShipableOrder } from '../../common/requestList';
import { computeTotalPrice, toast, wx, _alert } from '../../common/utils';
import './index.less';
import {ORDER_PAY_METHOD_WX_H5} from "../../common/const";

const BookingPage = ({ }) => {
    const history = useHistory();
    const [addressInfo, setAddressInfo] = useState(null); // 当前选择的地址信息
    const [list, setList] = useState([]);// 下单结算的商品
    const [totalShippingPrice, setTotalShippingPrice] = useState(0); // 总运费
    const [totalPrice, setTotalPrice] = useState(0);
    const [checkAll, setCheckAll] = useState(false);
    const [message, setMessage] = useState(''); // 备注信息
    const [isBooking, setIsBooking] = useState(false);// 防止多次点击，创单或者支付异常会重新设置为false
    const [showCalendar, setShowCalendar] = useState(false);
    const [orderIsInvalid, setOrderIsInvalid] = useState(false);
    const [errorTips, setErrorTips] = useState('');
    const [bookingTips, setBookingTips] = useState('');
    const [shippingMap, setShippingMap] = useState(null);// 当前下单的商品
    const [hasValidProduct, setHasValidProduct] = useState(true); // 是否有可下单的商品


    /**
    * 给不可售卖的商品增加提示和库存不足的标识
    * 如果有0元购商品和非零元购商品，在0元购商品上提示：0元购商品和其他商品不能一起下单
    * 0元购商品如果库存不足，提示超出最大限购数量
    */
    const formateList = (list) => {
        let _list = [...list];
        // 0元购商品
        // let _discountZeroProductCount = 0;
        let countMap = {};
        _list = _list.map(item1 => {
            item1.productList.forEach(item => {
                const productInfo = item.productInfo;
                if (!countMap[item.productId]) {
                    countMap[item.productId] = 0;
                }
                const _count = item.count;
                countMap[item.productId] += _count;
                // const _discountStock = productInfo?.discountInfo?.discountStock;
                // const bol1 = _discountStock > 0 && _discountStock < _count;
                // const bol1 = false;
                const bol2 = productInfo.stock < _count || (item.itemInfo?.stock < _count);
                if (bol2) {
                    item.disabled = true;
                }
            });
            // console.log('countMap', countMap);
            item1.productList.forEach(item => {
                const productInfo = item.productInfo;
                const _discountStock = productInfo?.discountInfo?.discountStock;
                // console.log('_discountStock', _discountStock, item);
                if (!item.disabled && _discountStock > 0 && _discountStock < countMap[item.productId]) {
                    item.disabled = true;
                }
            })
        return {...item1}
        });
        // 判断库存有没有超
        return _list;
    };
    /**
     * 计算商品的总价
     */
    const setListData = (list) => {
        let _totalPrice = 0;
        list.forEach(item => {
            let shippingPrice = 0;
            shippingPrice = Number(shippingMap?.[item.merchantInfo.id] || 0);
            // console.log(1111, shippingMap);
            item.totalShippingPrice = Math.round(shippingPrice * 100) / 100;
            item.totalPrice = Math.round(computeTotalPrice(item.productList) * 100 + Math.round(shippingPrice * 100)) / 100;
            _totalPrice = (item.totalPrice * 100 + _totalPrice * 100) / 100;
        });
        console.log('list', list);
        setList(list);
        setTotalPrice(_totalPrice.toFixed(2));
        setCheckAll(list.length === list?.length);
    };
    /**
     * 从storage中获取需要下的商品
     */
    const getProductData = () => {
        const list = getBookingDataList();
        formateList(list)
        setListData(list);
    };
    useEffect(() => {
      getProductData();
      initAddressInfo();
    }, [ ]);

    /**
     * 获取默认地址
     */
    async function getDefaultAddress() {
        const res = await getAddressList({
            page: 0,
            pageSize: 10,
            isDefault: 1
        });
        if (res?.ok == 0 && res?.data?.list?.length) {
            setChoosedAddress(res.data.list[0]);
            initAddressInfo();
        }
    }
    /**
    * 初始化地址信息
    */
    async function initAddressInfo() {
        const info = getChoosedAddress();
        if (info) {
            setAddressInfo(info);
        } else {
            await getDefaultAddress();
        }
        await validAddressIsValid();
        // 根据商品去获取运费
        getProductData();
        // console.log('获取运费', list);
        await getShippingPriceInfo();
        // // 获取快递费之后，重新去计算价格
        getProductData();
    }

    useEffect(() => {
        getShippingPriceInfo();
    }, [ addressInfo ]);
    useEffect(() => {
      getProductData();
    }, [ shippingMap ])



    /**
     * 校验配送地址是否是可配送范围
     */
    async function validAddressIsValid() {
        let _productList = [];
        list.forEach(item => {
            let _ids = item.productList.forEach(pItem => {
                if (pItem.productId) {
                _productList.push({
                    id: pItem.productId,
                })
                }
            })
        })
        if (addressInfo && list) {
            const res = await validShipableOrder({
                products: _productList,
                addressId: addressInfo?.id
            });
            const _state = {
                orderIsInvalid: false,
                errorTips: ''
            };
            if (res?.ok != 0) {
                _state.orderIsInvalid = true;
                _state.errorTips = res?.msg || '该地址不在该商品的可配送范围';
            }
            setOrderIsInvalid(_state.orderIsInvalid);
            setErrorTips(_state.errorTips)
        }
    };
    /**
     * 获取订单的运费
     */
    async function getShippingPriceInfo() {
        const param = formateCreateOrderParam();
        console.log('获取快递费', param);
        if (!param.addressId || !param.products?.length) {
            setHasValidProduct(false);
            return false;
        }
        const res = await getOrderShippingPrice({
            products: param.products,
            addressId: param.addressId,
        });
        let _shippingMap = {};
        const _list = res?.data?.list || [];
        _list.forEach(item => {
            _shippingMap[item.merchantId] = Number(item.shippingPrice);
        });
        setShippingMap(_shippingMap);
        setHasValidProduct(true);
    }
    function chooseAddress() {
        history.push('/address');
    }
    /**
     * 校验参数
     */
    function verifyParam() {
        if (!addressInfo) {
            _alert('请先选择收货地址');
            return false;
        }
        return true;
    }
    function formateCreateOrderParam() {
        const ua = Utils.getUA();
        console.log('list', list);
        let _products = [];
        list.forEach(item1 => {
          // 购物车的ID
          item1.productList.forEach(item => {
            if (item.disabled) {
              return ;
            }
            let obj = item.id ? { cartId: item.id} : {};
            const _discountInfo = item.productInfo?.discountInfo;
            if (_discountInfo?.discountStock > 0 && item.productInfo?.discountInfo?.discountId) {
              obj.discountId = item.productInfo.discountInfo.discountId;
            }
            _products.push({
              id: item.productInfo.id,
              itemDetailId: item.itemInfo?.id,
              count: item.count,
              ...obj
            });
          })
        });
        let _param = {
          products: _products,
          addressId: addressInfo?.id || 0,
          message,
          payment: ua.isInWechat ? CONST.ORDER_PAY_METHOD_WX_JS : CONST.ORDER_PAY_METHOD_WX_H5,
        };
        return _param;
    }
    function toOrderList(id) {
        const str = id ? '?id='+id : '';
        history.replace('/order/list?type=' + id);
    }
    /**
     * 创单
     */
    async function createOrder() {
        if (isBooking || orderIsInvalid || !hasValidProduct) return ;
        setIsBooking(true);
        const isValid = verifyParam();
        if (isValid) {
            const param = formateCreateOrderParam();
            const res = await submitOrder(param);
            if (res?.ok == 0) {
                // 创单成功，去掉storage中的数据
                removeBookingDataList();
                if(res?.data?.nonceStr || res?.data?.h5_url) {
                    pay(res.data);
                } else {
                    toOrderList(3);
                }
            } else {
                setIsBooking(false);
            }
            return false;
        }
        setIsBooking(false);
    };
    async function pay(config) {
        console.log('支付');
        wx.pay(config, {
            success: () => {
                toOrderList(3);
            },
            error: () => {
                toOrderList(1);
            }
        });
    }
    function setNote(e) {
        setMessage(e.currentTarget.value || '');
    }

    return (<div className='booking-container'>
        {/* 如果不在可配送范围 */}
        {
            orderIsInvalid ? <div className="tips-info">{errorTips}</div> : null
        }

        {/* 地址信息 */}
        <div className="address-container flex">
            <div className="iconfont icon-31dingwei address-icon"></div>
            <div className="address-content-wrapper flex1 color-999 f-24" onClick={chooseAddress}>
            {
                !addressInfo ? <div className="f-24 color-666">请选择收货地址</div> : <>
                    <div className="address-user-info flex flex-v-c">
                    <span className="receiver-name overflow-ellipsis">{addressInfo.name}</span>
                    <span className="mobile">{addressInfo.mobile}</span>
                    </div>
                    <div className="lh-1-4">{addressInfo.province}{addressInfo.city}{addressInfo.distinct}{addressInfo.address}</div>
                </>
            }
            </div>
            <div className="iconfont icon-arrow-right"></div>
        </div>
        {/* 如果商品中有0元购的商品和非0元购的商品，则不能下单 */}
        {/* 商品list */}
        {
            list?.map((item, index) => <div className="shop-product-list" key={item.merchantInfo.name + index}>
            <div className="shop-title">{item.merchantInfo.name}</div>
            {
                item.tips ? <div className="tips-desc">{item.tips}</div> : null
            }
            {
                item.productList?.map((pItem, pIndex) => <div className={`product-item-wrapper ${pItem.disabled ? 'disabled' : ''}`} key={pItem.productInfo.id + pIndex + ''}>
                    <div className="product-img-block">
                        <img src={CONST.MEDIA_HOST + pItem.productInfo.images[0]} className="product-img" />
                        {
                            pItem.disabled ? <div className="disable-wrapper">不可购买</div> : null
                        }

                    </div>
                    <div className="flex1">
                        <div className="flex">
                        <div className="product-content flex1">
                            <div className="product-title">{pItem.productInfo.name}</div>
                            <div className="product-desc">{pItem.itemInfo?.itemValues}</div>
                        </div>
                        <div className="price-wrapper">
                            <div className="price-num">¥{(pItem.productInfo.discountInfo && pItem.productInfo.discountInfo.discountStock && pItem.productInfo.discountInfo.productPrice) || pItem.itemInfo && pItem.itemInfo.price || pItem.productInfo.price}</div>
                            <div className="product-num">x{pItem.count}</div>
                        </div>
                        </div>
                        {
                            pItem.disabled ? <div className="tips-desc">购买数量超过了限购数，可能是库存不足，也可能是人为控制</div> : null
                        }

                    </div>
                </div>)
            }

            {/* <div className="tips-desc">该店铺中部分活动商品库存不足，将以原价购买</div> */}

            <div className="shop-total-info-wrapper">
            <div className="flex price-item">
                <div className="price-label flex1">快递费</div>
                <div className="price-num">¥{shippingMap && shippingMap[item.merchantInfo.id] || '0.00'}</div>
            </div>
            <div className="flex price-item">
                <div className="price-label flex1">合计：</div>
                <div className="price-num total-price">¥{item.totalPrice}</div>
            </div>
            </div>
        </div>)
        }

        {/* 备注信息 */}
        <div className="note-wrapper">
            <div className="note-label">备注信息</div>
            <input className="note-input flex1" placeholder="填写备注信息" onInput={setNote} />
        </div>
        {/* 客服 */}
        <customer-service>
            <div className="customer-service-wrapper">
            {/* <image className="customer-service-icon" src="{{ICON_BASE_URL}customer_service_blue_icon.png" /> */}
            <div className="iconfont icon-customer-service"></div>
            <span>联系客服</span>
            </div>
        </customer-service>
        <div className="booking-bottom-block"></div>
        {/* 确定下单操作区域 */}
        <div className="bottom-handler-wrapper">
            <div className="flex1">实付款<span className="booking-price-num">¥{totalPrice}</span></div>
            <div className={`booking-btn ${orderIsInvalid || !hasValidProduct ? 'disabled' : ''}`} onClick={createOrder}>确定下单</div>
        </div>
    </div>)
}

export default BookingPage;
