/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Page from '../../components/page';
import List from '../../components/list';
import './index.less';
import * as CONST from "../../common/const";
import EmptyListContent from "../../components/emptyListContent";
import Button from "../../components/button";
import Modal from "../../components/modal";
import OrderItem from "../../components/order/orderItem";
import OrderButton from "../../components/order/orderButton";
import TabList from "../../components/tablist";
import InfiniteScroll from 'react-infinite-scroller';
import './list.less';

const ORDER_TABS = [
	{ name: '全部', id: 1, status: 0 },
	{ name: '待付款', id: 2, status: 1, type: CONST.ORDER_STATUS_TO_PAY },
	{ name: '待发货', id: 3, status: 3, type: CONST.MESSAGE_STATUS_TO_SEND },
	{ name: '待收货', id: 4, status: 4, type: CONST.ORDER_STATUS_TO_RECEIVE },
	{ name: '待评价', id: 5, status: 5, type: CONST.ORDER_STATUS_TO_COMMENT },
];

const OrderListPage = props => {
	const history = useHistory();
	const type = Utils.getSearchByKey('type');

	const [ currentTab, setCurrentTab ] = useState(null);
	const [ pageIndex, setPageIndex] = useState(0);
	const [ pageSize, setPageSize] = useState(10);
	const [ total, setTotal] = useState(0);
	const [ isLoading, setIsLoading] = useState(false);


	const initCurrentTab = () => {
		const _type = type || ORDER_TABS[0].id;
		// if (_type) {
		const _data = ORDER_TABS.filter(item => item.id == _type);
		setCurrentTab(_data ? _data[0] : ORDER_TABS[0]);
		// }
	}
	const changeTab = item => {
		if (item.id == currentTab.id) {return };
		setPageIndex(0);
		setCurrentTab(item);
	}
	useEffect(() => {
		initCurrentTab();
	}, [123])


	const [ list, setList ] = useState([]);
	const getList = async () => {
		setIsLoading(true);
		const result = await Request.get({
			url: '/order/list',
			data: {
				status: currentTab?.status,
				page: pageIndex,
				pageSize,
				// type
			}
		});
		setIsLoading(false);
		if(!result) return;

		const _list = result?.data?.list;
		setList(pageIndex == 0 ? _list : [...list, ..._list]);
		setTotal(result?.data?.count || 0)

	};
	/**
	 * 加载下一页
	 */
	const loadNextPage = () => {
			if ((pageIndex + 1) * pageSize >= total) {
					return ;
			}
			if (isLoading) {
				return false;
			}
			console.log('加载下一页', isLoading);
			setPageIndex(pageIndex + 1);
	}
	useEffect(() => {
		if (currentTab != null) {
			getList();
		}

		return () => false;
	}, [currentTab, pageIndex]);

	const toOrderDetail = (e, item) => {
		e.stopPropagation();
		if(item.type == CONST.ORDER_TYPE_PHYSICAL) {
			history.push(`/order/${item.id}`);
		}else if(item.type == CONST.ORDER_TYPE_TICKET) {
			history.push(`/order/ticket/${item.id}`);
		}
	}

	return (
		<Page {...{
			...props,
			page: 'order-list', title: '订单列表'
		}}>
			<section className="order-list-container">
				<ul className='tab-list-container'>
					{
						currentTab && ORDER_TABS?.map((item, index) => <li onClick={() => changeTab(item)} className={`tab-item-wrapper${currentTab?.id == item.id ? ' active' : ''}`} key={item.name}>{item.name}</li>)
					}
				</ul>
				<div className='scroll-wrapper'>
					<InfiniteScroll
						loadMore={loadNextPage}
						hasMore={total > pageSize * (pageIndex + 1)}
						useWindow={false}
						initialLoad={true}
						loader={list?.length > 0 && <div className="infinite-scroll-ending-tip" key={0}>加载中...</div>}
					>
						<List {...{
								list: list,
								numberInLine: 1,
								// gap: props.gap,
								renderItem: item => {
									return (
										<div className="order-list-item">
											<div className="order-info" onClick={e => toOrderDetail(e, item)}>
												<div className="number">订单号：{item.serialNumber?.substr(0, 10)}</div>
												<div className={`status${item.status == CONST.ORDER_STATUS_TO_PAY ? ' status-topay' : ''}`}>{CONST.CONST_LABEL[item.status]}</div>
											</div>
											<div className="order-product-info" onClick={e => toOrderDetail(e, item)}>
												{
													item.productList?.length > 0 && item.productList.map((pitem, pidx) => {
														return <OrderItem key={pidx} {...{
															data: pitem,
															detailData: item
														}} />
													})
												}
											</div>
											<div className="order-price" onClick={e => toOrderDetail(e, item)}>
												<span>合计：</span>
												<span className="price-value">¥{item.paidPrice}</span>
												{ item.shipping && <span>（含运费¥{item.shipping}）</span> }
											</div>
											<div className="order-ctrls">
												<div className="order-ctrl-service-item" onClick={() => window.location.href = CONST.customerServiceLink}><em className="iconfont icon-customer-service" /><span>联系客服</span></div>
												<OrderButton {...{
													data: item,
													refresh: () => {
														setPageIndex(0);
														getList();
													}
												}} />
											</div>
										</div>
									)
								}
							}} />
						{
							total <= pageSize * (pageIndex + 1) ? <div className='infinite-scroll-ending-tip'>{total ? '我就是底线' : '暂无数据'}</div> : null
						}
					</InfiniteScroll>
				</div>
			</section>
		</Page>
	);
};

export default OrderListPage;
