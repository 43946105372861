import API from './api';
import * as HTTP from './request';
const GET = async (url, param) => {
  return HTTP.get({
    url,
    data: param,
  })
}
const POST = async (url, param) => {
  return HTTP.post({
    url,
    data: param,
  })
}
const UPLOAD = async (url, param) => {
  return HTTP.upload({
    url,
    data: param,
  })
}
// 商品详情
export const getProductDetailData = (param) => {
  return GET(API.productDetail, param);
}
// 商品规格列表
export const getProductSpecificationList = (param) => {
  return GET(API.productSpecificationList, param);
}
// 获取商品评价列表
export const getCommentList = param => {
  return GET(API.productCommentList, param);
}
/**
 * 根据code拿用户信息
 */
export const getUserData = (code, success) => {
  return GET(API.userGet);
};
/**
 * 更新用户信息
 */
export const updateUserInfo = (data, success) => {
  POST(API.userSet, data, {
    success
  });
}
/**
 * 用户头像上传
 */
export const uploadAvatar = (data) => {
  return POST(API.uploadAvatar, data);
}
/**
 * 用户头像上传
 */
 export const userLogin = (code) => {
  return POST(API.userLogin, {
    code
  });
}
/**
 * 购物车
 */
export const getCardListData = param => {
  console.log('请求', API.cartList);
  return GET(API.cartList, param);
}
/**
 * 加入购物车
 */
export const addToCart = (data) => {
  return POST(API.setCart, data);
}
/**
 * 删除购物车商品
 */
export const deleteCartItem = data => {
  return POST(API.deleteCart, data);
}
/**
 * 获取品牌列表
 */
export const getBrandList = data => {
  return GET(API.brandList, data);
}
/**
 * 商品搜索
 */
export const getBrandProductList = data => {
  return GET(API.brandProductList, data);
}
/**
 * 商品搜索
 */
export const searchProduct = data => {
  return GET(API.searchProduct, data);
}
/**
 * 获取收货地址
 */
export const getAddressList = data => {
  return GET(API.addressList, data);
}
/**
 * 添加收货地址
 */
export const addAddress = data => {
  return POST(API.addAddress, data);
}
/**
 * 更新收货地址
 */
export const updateAddress = data => {
  return POST(API.updateAddress, data);
}
/**
 * 删除收货地址
 */
 export const deleteAddress = data => {
  return POST(API.deleteAddress, data);
}
/**
 * 创单
 */
export const submitOrder = data => {
  return POST(API.submitOrder, data);
}
/**
 * 获取微信支付参数
 */
export const getPayParam = data => {
  return POST(API.orderPay, data);
}
/**
 * 获取订单详情
 */
export const getOrderDetail = data => {
  return GET(API.orderDetail, data);
}
/**
 * 取消订单
 */
export const cancelOrder = data => {
  return POST(API.cancelOrder, data);
}
/**
 * 订单列表
 */
export const getOrderList = data => {
  return GET(API.orderList, data);
}
/**
 * 确认收货
 */
export const confirmReceipt = data => {
  return POST(API.confirmReceiveOrder, data);
}
/**
 * 查看物流信息
 */
export const getTrackingOrder = data => {
  return GET(API.trackingOrder, data);
}
/**
* 获取退款理由
*/
export const getRefundReasons = data => {
  return GET(API.refundReasons, data);
}
/**
* 退款申请
*/
export const applyRefund = data => {
  return POST(API.applyRefund, data);
}
/**
* 退款详情
*/
export const getRefundDetail = data => {
  return GET(API.refundDetail, data);
}
/**
* 上传评论图片
*/
export const upload = imgUrl => {
  return HTTP.upload(imgUrl);
}
/**
 * 获取消息列表
 */
export const getMessageList = data => {
  return GET(API.messageList, data);
}
/**
 * 获取消息列表
 */
export const getUnReadMessageCount = data => {
  return GET(API.messageUnReadCount, data);
}
/**
 * 获取首页配置
 */
export const getConfigData = () => {

}
/**
 * 获取商品分组列表
 */
export const getProductGroupList = () => {
  return GET(API.productGroupList);
}
/**
 * 获取商品列表
 */
export const getProductList = data => {
  return GET(API.productList, data);
}
/**
 * 评价
 */
export const addComment = data => {
  return POST(API.productCommentAdd, data);
}
/**
 * 收藏
 */
export const collect = (data) => {
  return POST(API.toggleProductCollect, data);
}
/**
 * 获取进行中的订单的数量
 */
export const getOrderCount = (data) => {
  return GET(API.orderCount, data);
}
/**
 * 更新用户信息
 */
export const setUserInfo = data => {
  return POST(API.userSet, data);
}
/**
 * 获取首页配置信息
 */
export const getPageConfig = (data) => {
  return GET(API.pageConfig, data);
}
/**
 * 获取banner
 */
export const getBannerList = (data) => {
  return GET(API.bannerList, data);
}
/**
 * 获取折扣商品列表
 */
export const getDiscountProductList = data => {
  return GET(API.discountProductList, data);
}
/**
 * 商品收藏列表
 */
export const getCollectList = data => {
  return GET(API.collectList, data);
}
/**
 * 是否是在配送范围
 */
export const validShipableOrder = data => {
  return POST(API.shipableOrder, data);
}
/**
 * 获取配送运费
 */
export const getOrderShippingPrice = data => {
  return POST(API.orderShippingPrice, data);
}
/**
 * 判断是否幸福云用户
 */
export const checkXingfuyunAccessUser = data => {
  return POST(API.checkAccessUser, data);
}
// 是否打开幸福云登录入口
export const xfuLoginFlag = data => {
  return POST(API.xfuLoginFlag, data);
}
/**
 * 标记消息为已读
 */
export const markMessageIsReady = data => {
  return POST(API.messageMarkRead, data);
}
/**
 * 票券商品创单接口
 */
export const createTicketOrder = data => {
  return POST(API.submitTicketOrder, data);
}
/**
 * 开具发票
 */
export const submitInvoiceRequest = data => {
  return POST(API.submitInvoice, data);
}
/**
 * 获取发票详情
 */
export const getInvoiceDetail = data => {
  return GET(API.invoiceGet, data);
}
/**
 * 获取发票物流
 */
export const getInvoiceTracking = data => {
  return GET(API.invoiceTracking, data);
}
/**
 * 获取商品描述信息{productId: xx, type: Enum(说明类型：1 - 参数说明；2 - 购买说明；3 - 使用说明)}
 */
export const getProductDescriptions = data => {
  return GET(API.productDescriptions, data);
}
/**
 * 取消退款申请
 */
export const cancelRefundOrder = data => {
  return POST(API.cancelRefundOrder, data);
}
/**
 * 上传评论图片
 */
export const uploadCommentImg = data => {
  return UPLOAD(API.uploadCommentImg, data);
}
/**
 * 上传退款图片
 */
 export const uploadRefundImg = data => {
  return UPLOAD(API.uploadRefundImg, data);
}
/**
 * 获取商家信息
 */
export const getMerchantInfo = data => {
  return GET(API.getMerchant, data);
}

