/**
 * Created by jimmy on 2019/9/29.
 */

import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter as Router, Route, Switch, useParams, useHistory } from "react-router-dom";
import * as CONST from '../../common/const';
import Page from '../../components/page';
import './index.less';
import * as Request from "../../common/request";
import Banners from "../../components/banners";
import Block from "../../components/block";
import ProductCommentItem from "../../components/product/commentItem";
import ProductItem from "../../components/product/productItem";
import Modal from "../../components/modal";
import * as Utils from "../../common/utils";
import List from "../../components/list";
import KingAreas from "../../components/kingAreas";

const BrandListPage = props => {
	const history = useHistory();

	const [list, setList ] = useState([]);
	const getList = async () => {
		const result = await Request.get({
			url: '/brand/list'
		});
		if(!result) return;
		setList(result.data.list);
	};
	useEffect(() => {
		getList();
	}, [123]);

	return (
		<Page {...{
			...props,
			page: 'brand-list', title: '品牌专区'
		}}>
			<section className="brand-list-container" style={{ minHeight: window.innerHeight }}>
				<KingAreas {...{
					list: list?.length > 0 && list.map(d => ({ ...d, label: d.name })) || [],
					clickHandle: (item) => {
						if(item.targetType == CONST.BRAND_TARGET_TYPE_WEBSITE && item.target.startsWith('http')) {
							window.location.href = item.target;
						}else if(item.targetType == CONST.BRAND_TARGET_TYPE_DEFAULT) {
							history.push(`/brand/${item.id}?title=${item.name}`);
						}else{
							// console.log('程序员忘记写了，你提醒下叻');
						}
					}
				}} />
			</section>
		</Page>
	);
};

export default BrandListPage;
