/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as CONST from '../../common/const';
import Page from '../../components/page';
import Banners from '../../components/banners';
import Block from '../../components/block';
import KingAreas from '../../components/kingAreas';
import List from '../../components/list';
import TabList from '../../components/tablist';
import ProductPromotionItem from '../../components/product/discountItem';
import ProductItem from '../../components/product/productItem';
import './index.less';
import {getPageConfig} from "../../common/requestList";
import {BRAND_TARGET_TYPE_WX_MINI_PROGRAM, HOME_ID} from "../../common/const";

const PageModel = props => {

	const history = useHistory();
	const [ data, setData ] = useState({});
	const getData = async () => {
		const res = await getPageConfig({number: HOME_ID});
		if (res?.ok == 0) {
			window.MALL_DATA = res.data;
			setData(res.data || {});
		}
	};
	useEffect(() => {
		getData();
		return () => false;
	}, [ 123 ]);

	const [ categoryList, setCategoryList ] = useState([]);
	const [ currentCategory, setCurrentCategory ] = useState(null);
	const getCategoryList = async () => {
		const result = await Request.get({
			url: '/product/group/list'
		});
		if(!result) return;

		if(result.data.list?.length > 0) {
			setCategoryList(result.data.list);
			setCurrentCategory(result.data.list[0]);
		}
	};
	useEffect(() => {
		getCategoryList();

		return () => false;
	}, [ 123 ]);

	const [ productList, setProductList ] = useState([]);
	const getProductList = async () => {
		const result = await Request.get({
			url: '/product/list',
			data: {
				page: 0,
				pageSize: CONST.PAGE_SIZE_LIMIT,
				groupId: currentCategory.id
			}
		});
		if(!result) return;

		setProductList(result.data.list);
	};
	useEffect(() => {
		if(currentCategory?.id) {
			getProductList();
		}

		return () => false;
	},  [ currentCategory ]);

	const [ countDownInfo, setCountDownInfo ] = useState(null);
	useEffect(() => {
		if(data?.timeLimitDiscountInfo) {
			let endTime = null;
			let formatLabel = null;
			if(data.timeLimitDiscountInfo.status == CONST.DISCOUNT_STATUS_TO_START) {
				formatLabel = `距离开始还有<span>DD天HH时mm分钟ss秒</span>`;
				endTime = data.timeLimitDiscountInfo.effectiveTime;
			}
			if(data.timeLimitDiscountInfo.status == CONST.DISCOUNT_STATUS_PROCESSING) {
				formatLabel = `距离结束还有<span>DD天HH时mm分钟ss秒</span>`;
				endTime = data.timeLimitDiscountInfo.expiredTime;
			}
			if(!endTime) return;

			Utils.countDown(endTime, result => {
				if(result.ok == 0) {
					setCountDownInfo(result.data);
				}else{
					setCountDownInfo(result.msg || null);
				}
			}, formatLabel);
		}
	}, [ data ]);
console.log('data---', data);
	return (
		<Page {...{
			...props,
			showFooter: true,
			page: 'home', title: data?.title || '首页',
			showBg: true
		}}>
			<section className="home-container" style={{
				minHeight: window.innerHeight,
				backgroundColor: data?.backgroundColor,
				backgroundImage: `url(${CONST.MEDIA_HOST + data?.backgroundImage1})`,
			}}>
				{ data.backgroundImage2 && <div className="bg" style={{ backgroundImage: `url(${CONST.MEDIA_HOST + data.backgroundImage2})` }} /> }

				<Banners {...{
					list: data?.topBanners?.length > 0 && data?.topBanners || [],
					style: { margin: 15, borderRadius: 20 }
				}} />

				<Block {...{
					title: '品牌专区',
					titleImgIcon: <img className='brand-icon' src={`${CONST.ICON_BASE_URL}home_brand_title_icon.png`} />,
					moreConfig: {},
					style: { margin: 15, paddingBottom: 10, borderRadius: 6, fontWight: 'bold' },
					className: "brands-container",
					headerExtra: <div className="brands-more" onClick={() => {
						history.push('brand/list');
					}}>查看更多</div>
				}}>
					<KingAreas {...{
						list: data?.brands?.length > 0 && data?.brands.map(d => ({ ...d, label: d.name })) || [],
						clickHandle: (item) => {
							if(item.targetType == CONST.BRAND_TARGET_TYPE_WEBSITE && item.target.startsWith('http')) {
								window.location.href = item.target;
							}else if(item.targetType == CONST.BRAND_TARGET_TYPE_DEFAULT) {
								history.push(`/brand/${item.id}?title=${item.name}`);
							}else if(item.targetType == CONST.BRAND_TARGET_TYPE_WX_MINI_PROGRAM && item.target) {
								window.location.href = item.target;
							}else{
								// console.log('程序员忘记写了，你提醒下叻');
							}
						}
					}} />
				</Block>

				{
					data?.timeLimitDiscountInfo && <Block {...{
						title: data?.timeLimitDiscountInfo?.name || '限时特惠',
						moreConfig: {},
						style: { margin: 15, paddingBottom: 10, borderRadius: 6 },
						className: "activity-container",
						headerExtra: <div className="activity-more" onClick={() => {
							history.push(`product/discount/list?id=${data?.timeLimitDiscountInfo?.id}`);
						}}>
							<div className="activity-countdown" dangerouslySetInnerHTML={{ __html: countDownInfo }} />
							<span>更多</span>
						</div>
					}}>
						<List {...{
							list: (() => {
								let list = data?.timeLimitDiscountInfo?.products || [];
								if(list.length > 3) {
									list = list.slice(0, 3);
								}
								return list;
							})(),
							numberInLine: 3,
							gap: 10,
							renderItem: (data = {}) => {
								return (
									<ProductPromotionItem {...{
										data
									}} />
								)
							}
						}} />
					</Block>
				}

				<Block className="home-page-category">
					<TabList {...{
						tabs: categoryList,
						tab: currentCategory,
						tabChange: tab => {
							setCurrentCategory(tab);
						},
						list: productList,
						numberInLine: 2,
						gap: 10,
						tabContainerStyle:{ backgroundImage: `url(${CONST.MEDIA_HOST + data?.backgroundImage1})` },
						renderItem: productData => {
							return (
								<ProductItem {...{
									data: productData,
									// mode: 'ltr'
								}} />
							)
						},
						checkMoreAction: current => {
							console.log('current', current);
							history.push('category');
						}
					}} />
					<Banners {...{
						list: data?.bottomBanners?.length > 0 && data?.bottomBanners || [],
						style: { borderRadius: 6, height: 78 },
						height: 78
					}} />
				</Block>
			</section>
		</Page>
	);
};

export default PageModel;

