/**
 * Created by jimmy on 2022/9/25.
 */

import React, { useState, useEffect } from 'react';
import * as CITY from './data/index';
import * as CONST from '../../common/const';
import './index.less';

const CompCityModel = props => {
	const [ plist, setpList ] = useState([]);
	const [ ptarget, setpTarget ] = useState(null);
	const [ clist, setcList ] = useState([]);
	const [ ctarget, setcTarget ] = useState(null);
	const [ alist, setaList ] = useState([]);
	const [ atarget, setaTarget ] = useState(null);

	useEffect(() => {
		if(props.province) {
			const { code: provinceCode } = props.province;
			const province = CITY.get(provinceCode);
			setpTarget(province);
		}
	}, [ props.province ]);
	useEffect(() => {
		if(props.city) {
			const { code: cityCode } = props.city;
			const city = CITY.get(cityCode);
			setcTarget(city);
		}
	}, [ props.city ]);
	useEffect(() => {
		if(props.area) {
			const { code: areaCode } = props.area;
			const area = CITY.get(areaCode);
			setaTarget(area);
		}
	}, [ props.area ]);

	const getProvinceList = () => {
		const plist = CITY.getProvinceList();
		setpList(plist);
	};
	useEffect(() => {
		getProvinceList();

		return () => false;
	}, [123]);
	const getCityList = () => {
		const clist = CITY.getCityListByPcode(ptarget);
		setcList(clist);
	};
	useEffect(() => {
		getCityList();

		return () => false;
	}, [ ptarget ]);
	const getAreaList = () => {
		const alist = CITY.getAreaListByPcodeCcode(ptarget, ctarget);
		setaList(alist);
	};
	useEffect(() => {
		getAreaList();

		return () => false;
	}, [ ctarget ]);

	const [ finish, setFinish ] = useState(false);
	useEffect(() => {
		if(finish && ptarget && ctarget && atarget && props.onChange && typeof props.onChange == 'function') {
			props.onChange({ province: ptarget, city: ctarget, area: atarget });
			setFinish(false);
		}
	}, [ ptarget, ctarget, atarget, finish ]);

	return (
		<section className="comp-city-wrapper">
			<div className="comp-city-display">
				<div className="comp-city-display-item" onClick={() => {
					setpTarget(null);
					setcTarget(null);
					setaTarget(null);
				}}>{ptarget?.name || '请选择'}<em className="iconfont" /></div>
				{
					ptarget && <div className="comp-city-display-item" onClick={() => {
						setcTarget(null);
						setaTarget(null);
					}}>{ctarget?.name || '请选择'}</div>
				}
				{
					ctarget && <div className="comp-city-display-item" onClick={() => {
						setaTarget(null);
					}}>{atarget?.name || '请选择'}</div>
				}
			</div>
			<div className="comp-city-list">
				{
					(() => {
						if(!ptarget && !ctarget) return plist;
						if(!ctarget) return clist;
						return alist;
					})().map((item, idx) => {
						return (
							<div className="comp-city-item" key={idx} onClick={() => {
								if(!ptarget && !ctarget) {
									setpTarget(item);
									setcTarget(null);
									setaTarget(null);
									setFinish(false);
									return;
								}
								if(!ctarget) {
									setcTarget(item);
									setaTarget(null);
									setFinish(false);
									return;
								}
								setaTarget(item);
								setFinish(true);
							}}>{item.name}</div>
						)
					})
				}
			</div>
		</section>
	);
};

export default CompCityModel;
